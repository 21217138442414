import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { 
	getQualifyingGroup,
	getQualifyingGroupSettings,
	saveQualifyingGroupSettings,
	saveQualifyingGroupResults,
	getQualifyingGroupResults,
	resetQualifyingGroupResults
} from '../../../api/qualifying';

const QualifierGroups = (props) => {
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState(null);
	const [groupsettings, setGroupsettings] = useState(null);
	const [results, setResults] = useState({});
	const [event, setEvent] = useState(0);

	const getEvents = useSelector((state) => state.events);
	
	// get first event where has_qualifiers is 1
	useEffect(() => {
		const foundEvent = getEvents.events.find(event => event.has_qualifiers === 1);
		if (foundEvent) {
			setEvent(foundEvent.id);
		}
	}, [getEvents]);

	const getInitialData = async () => {
		try {
			const getQualifyingGroupSettingsRes = await getQualifyingGroupSettings(event);

			if (getQualifyingGroupSettingsRes.data.status === "success") {
				setGroupsettings(getQualifyingGroupSettingsRes.data.group_nums);
				// setLoading(false);
			}

			const getQualifyingGroupsRes = await getQualifyingGroup(event);

			if (getQualifyingGroupsRes.data.status === "success") {
				setGroups(getQualifyingGroupsRes.data.events);
				// setLoading(false);
			}

			const getQualifyingGroupsResultsRes = await getQualifyingGroupResults();

			if (getQualifyingGroupsResultsRes.data.status === "success") {
				console.log('getQualifyingGroupsResultsRes', getQualifyingGroupsResultsRes.data)
				setResults(getQualifyingGroupsResultsRes.data.results);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [event]);

	if (loading) {
		return <Loading />;
	}

	const submitTimetable = async (values, { setSubmitting }) => {
		setSubmitting(true);
		const toastId = toast("Saving Result...", { autoClose: false });

		try {
			const saveResultRes = await saveQualifyingGroupResults(values);
			if (saveResultRes.data.status === "success") {
				getInitialData();
				setSubmitting(false);
				toast.update(toastId, { render: "Start Times Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving Start Times.", type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	const formatTime = (value) => {
		if (!value) return '';

		// Remove all non-digits and limit string to 6 characters (HHMMS)
		const digits = value.replace(/\D/g, '').substring(0, 5);

		// Split the string into HH, MM, and SS
		const parts = [];
		for (let i = 0; i < digits.length; i += 2) {
			parts.push(digits.substring(i, i + 2));
		}

		return parts.join(':');
	};

	const handleChange = (e, setFieldValue, crewId, values) => {
		const key = e.target.name.split('[')[1].split(']')[0];
		const formattedTime = formatTime(e.target.value);

		// Update the start time for the crew that initiated the change
		setFieldValue(`crews[${key}].start_time`, formattedTime);

		// get this crew's group number
		const groupNum = values.crews[key].group_num;
		console.log('THISCOOLGN', values.crews[key]);


		// loop all values and update the start time for all crews in the same group except the current crewId
		Object.keys(values.crews).forEach(index => {
			console.log('THISGN', values.crews[index].group_num);
			if (values.crews[index].crew_id !== crewId && values.crews[index].group_num === groupNum) { // Skip the crew that initiated the change
				setFieldValue(`crews[${index}].start_time`, formattedTime);
			}
		});
	};

	const submitGroupSetter = async (values, { setSubmitting }) => {
		setSubmitting(true);
		const toastId = toast("Saving Group Settings...", { autoClose: false });

		try {
			const saveResultRes = await saveQualifyingGroupSettings(values);
			if (saveResultRes.data.status === "success") {
				getInitialData();
				setSubmitting(false);
				toast.update(toastId, { render: "Group Settings Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving Group Settings.", type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	const GetGroupSetterForm = () => {
		return (
			<Formik
				initialValues={{
					event: event,
					group_num: groupsettings,
				}}
				onSubmit={submitGroupSetter}
			>
				{({ values, errors, touched, setFieldValue, isValid }) => (
					<Form>
						<div className="row align-items-end">
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 1 Max</label>
									<Field
										name={`group_num[0]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 2 Max</label>
									<Field
										name={`group_num[1]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 3 Max</label>
									<Field
										name={`group_num[2]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 4 Max</label>
									<Field
										name={`group_num[3]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 5 Max</label>
									<Field
										name={`group_num[4]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-2">
									<label htmlFor="group">Group 6 Max</label>
									<Field
										name={`group_num[5]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-0">
									<label htmlFor="group">Group 7 Max</label>
									<Field
										name={`group_num[6]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-0">
									<label htmlFor="group">Group 8 Max</label>
									<Field
										name={`group_num[7]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<div className="form-group mb-md-0">
									<label htmlFor="group">Group 9 Max</label>
									<Field
										name={`group_num[8]`}
										className="form-control"
										type="number"
									/>
								</div>
							</div>
							<div className="col-12 col-md-2">
								<Field name="event" type="hidden" value={event} />
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		)
	}

	const QualifyingGroupForm = () => {
		let rowId = 1;

		const initialValues = {
			crews: groups.reduce((crewAcc, group) => {
				group.forEach((crew, group_num) => {
					crewAcc[rowId] = {
						crew_id: (crew.crew?.crew?.id) ? crew.crew?.crew?.id : 0, // This is actually the entry ID
						start_time: (results[crew.crew?.crew?.id]?.start_time) ? results[crew.crew?.crew?.id].start_time : '00:00:0',
						group_num: crew.group_number,
					};

					rowId++;
				});

				return crewAcc;
			}, {})
		};

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={submitTimetable}
			>
				{({ values, errors, touched, setFieldValue, isValid }) => {
					rowId = 0;
					return (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Event</th>
										<th>Crew #</th>
										<th>Crew</th>
										<th>Group #</th>
										<th>ST</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{groups.map((group, index) => (
										<React.Fragment key={index}>
											{group.map((crew, i) => {
												if (crew.break === true || crew.break === false) {
												} else {
													rowId++;
													return (
														<tr key={`tr${i}`}>
															<td>{crew.crew.event.name}</td>
															<td>{(crew.crew?.crew?.crew_number) ? crew.crew?.crew?.crew_number : 'N/A'}</td>
															<td>{crew.crew?.crew?.crew_name}</td>
															<td>{crew.group_number}</td>
															<td width="110px">
																<Field
																	name={`crews[${rowId}].start_time`}
																	className="form-control"
																	onClick={(e) => e.target.select()}
																	onChange={e => handleChange(e, setFieldValue, crew.crew?.crew?.id, values, rowId)}
																/>
															</td>
															<td>
																<Field type="hidden" name={`crews[${rowId}].group_num`} value={crew.group_number} />
																<Field type="hidden" name={`crews[${rowId}].crew_id`} value={crew.crew?.crew?.id} />
																<Field type="hidden" name={`crews[${rowId}].type`} value="RACE" />
															</td>
														</tr>
													)
												}
											})}
										</React.Fragment>
									))}
								</tbody>
							</table>

							<div className="floating-buttons">
								<button type="submit" className="btn btn-primary btn-lg">Save</button>
							</div>
						</Form>
					)
				}}
			</Formik>
		)
	};

	const DoEventSelect = () => {
		return (
			<select className="form-control" onChange={(e) => setEvent(e.target.value)}>
				{
					getEvents.events.map((events, index) => {
						if (events.has_qualifiers === 1) {
							return (
								<option key={index} value={events.id} selected={(events.id === parseInt(event)) ? true : false}>{events.name}</option>
							)
						}
					})
				}
			</select>
		)
	}

	return (
		<div className="container-fluid">
			<div className="row align-items-center">
				<div className="col-12 col-md-6">
					<h1>Qualifying Groups</h1>
				</div>
				<div className="col-12 col-md-6">
					<DoEventSelect />
				</div>

				<div className="col-12 mt-3 form-group">
					<div className="mb-4"><GetGroupSetterForm /></div>
					{(groups && groups.length > 0) ? <QualifyingGroupForm /> : <div className="alert alert-danger">No Races Found</div>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(QualifierGroups);
