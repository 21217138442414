import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import { getCommentatorRaceList } from '../../../api/commentators';
import Pagination from '../../../components/Pagination';
import { toast } from 'react-toastify';
import { getCommentatorPrintDoc, getCommentatorPrintDocAll } from '../../../api/commentators';

const CommentatorList = (props) => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');
	const [day, setDay] = useState('tues');

	const changeDay = (e) => {
		const { value } = e.target;
		setLoading(true);
		setDay(value);
	};

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		const response = await getCommentatorRaceList(page, day, sortDirection, sortedColumn);
		console.log(response);
		if (response.data.status === "success") {
			setEntries(response.data.raceCrews);
			setLoading(false);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [page, sortDirection, sortedColumn, day]);

	if (loading) {
		return <div className="screen-loading">Loading...</div>;
	}

	const getCommentatorPrintPDF = async (race, race_num, day) => {
		const toastId = toast("Generating report...", { autoClose: false, type: 'info' });
		try {
			const getCommentatorPrint = await getCommentatorPrintDoc(race);

			if (getCommentatorPrint.data.status === 'success') {
				const blob = new Blob(
					[Uint8Array.from(atob(getCommentatorPrint.data.report), c => c.charCodeAt(0))],
					{ type: 'application/pdf' }
				);
	
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'race-'+day+'-'+race_num+'.pdf';
				link.click();

				toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { render: "Unable to download report", type: 'error', autoClose: 5000 });
		}
	}

	const getCommentatorPrintAllPDF = async () => {
		const toastId = toast("Generating report...", { autoClose: false, type: 'info' });
		try {
			const getCommentatorPrint = await getCommentatorPrintDocAll(day);

			if (getCommentatorPrint.data.status === 'success') {
				const blob = new Blob(
					[Uint8Array.from(atob(getCommentatorPrint.data.report), c => c.charCodeAt(0))],
					{ type: 'application/pdf' }
				);
	
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'race-'+day+'-all.pdf';
				link.click();

				toast.update(toastId, { render: "Downloading Report", type: 'success', autoClose: 5000 });
			}
		} catch (error) {
			toast.update(toastId, { render: "Unable to download report", type: 'error', autoClose: 5000 });
		}
	}

	const columns = () => {
		return Object.entries(entries).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				race_number: value.race,
				day: value.day,
				berks: <>
					<Link to={`/admin/commentators/${value.berks?.id}?race_id=${value.id}&station=berks`}>{value.berks?.crew_name}</Link>
					<span className="d-block"><strong>Crew No:</strong> {value.berks?.crew_number}</span>
				</>,
				bucks: <>
					<Link to={`/admin/commentators/${value.bucks?.id}?race_id=${value.id}&station=bucks`}>{value.bucks?.crew_name}</Link>
					<span className="d-block"><strong>Crew No:</strong> {value.bucks?.crew_number}</span>
				</>,
				print: <button className="btn btn-secondary" onClick={() => getCommentatorPrintPDF(value.id, value.race, value.day)}>Print</button>
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Races</h1>
				</div>
				<div className="col-12 col-md-6">
					<select className="form-control" onChange={(e) => changeDay(e)} defaultValue={day}>
						<option value="tues">Tuesday</option>
						<option value="weds">Wednesday</option>
						<option value="thurs">Thursday</option>
						<option value="fri">Friday</option>
						<option value="sat">Saturday</option>
						<option value="sun">Sunday</option>
					</select>
				</div>

				<div className="col-12 form-group">
					<div>
						{(entries && Object.entries(entries).length > 0) ?
							<>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										{
											name: 'day',
											sort: 'day'
										},
										{
											name: 'race_number',
											sort: 'race_number'
										},
										{
											name: 'berks',
											sort: 'berks'
										},
										{
											name: 'bucks',
											sort: 'bucks'
										},
										{
											name: 'print',
											sort: 'print'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									columnMappings={{
										'id': 'id',
										'race_number': 'race'
									}}
									linkLocation="/admin/commentators"
									editable={false}
								/>
								<Pagination data={entries.entries} setPage={setPage} />
								<button className="btn btn-secondary" onClick={() => getCommentatorPrintAllPDF()}>Print All</button>
							</> : <div className="alert alert-danger">No races found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(CommentatorList);
