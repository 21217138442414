import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { getClubs } from '../api/clubs';

const CommentatorFilter = ({ handleFilters, filters }) => {
	const [event, setEvent] = useState(null);
	const [events, setEvents] = useState(null);

	const eventsList = useSelector((state) => state.events);

	useEffect(() => {
		mapEventsToSelect();
	}, [eventsList.events]);

	const mapEventsToSelect = () => {
		if (!eventsList.events) return;

		let eventOptions = [{ value: '', label: 'All' }];
		eventsList.events.forEach(value => {
			eventOptions.push({ value: value.id, label: value.name });
		});

		setEvents(eventOptions);
	};

	const handleEventChange = selectedOption => {
		setEvent(selectedOption || null);
		handleFilters({ event: selectedOption || null });
	};

	return (
		<div className="filter-container row">
			<div className="filter-item col-12 col-md-6">
				<label>Event</label>
				<Select
					value={event}
					onChange={handleEventChange}
					options={events}
				/>
			</div>
		</div>
	);
};

export default CommentatorFilter;
