const generateSecureRandomPassword = (length) => {
	const lowerCharset = "abcdefghijklmnopqrstuvwxyz";
	const upperCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	const numberCharset = "0123456789";
	const symbolCharset = "!@#$%^&*()-_=+<>?/";

	const allCharsets = [lowerCharset, upperCharset, numberCharset, symbolCharset];
	let password = "";

	// Ensure at least one character from each charset is included
	allCharsets.forEach(charset => {
		const randomIndex = Math.floor(Math.random() * charset.length);
		password += charset[randomIndex];
	});

	// Fill the remaining characters randomly from the combined charset
	const combinedCharset = lowerCharset + upperCharset + numberCharset + symbolCharset;
	for (let i = password.length; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * combinedCharset.length);
		password += combinedCharset[randomIndex];
	}

	// Randomize the order of characters in the password
	password = password.split('').sort(() => 0.5 - Math.random()).join('');

	return password;
}

export default generateSecureRandomPassword;
