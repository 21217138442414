import instance from './config';

// Public routes
const checkCoupon = (code) => {
	return instance.get(`/coupons/check/${code}`);
};

const getCoupon = (id) => {
	return instance.get(`/admin/coupons/${id}`);
};

const getCoupons = (page = 1, sortDir = 'asc', sortCol = 'created_at') => {
	return instance.get(`/admin/coupons?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const saveCoupon = (data) => {
	return instance.get(`/admin/coupons`, data);
};

const deleteCoupon = (id) => {
	return instance.delete(`/admin/coupons/${id}`);
}

export {
	checkCoupon,
	getCoupon,
	getCoupons,
	saveCoupon,
	deleteCoupon
}
