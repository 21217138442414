import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../components/Pagination';
import { getOrders } from '../../../api/orders';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';


// TODO: Wire up searching

const OrdersList = (props) => {
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState({});
	const [page, setPage] = useState(1);
	const [isSearching, setIsSearching] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const response = await getOrders(page, sortDirection, sortedColumn);

			if (response.data.status === 'success') {
				setOrders(response.data.orders);
				setLoading(false);
			}
		} catch (error) {
			
		}
	}

	// useEffect(() => {
	// 	setLoading(true);
	// 	getInitialData();
	// }, []);

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page, sortDirection, sortedColumn]);

	// useEffect(() => {
	// 	if (!searchTerm) return;

	// 	setLoading(true);
	// 	fetch(`${API_URL}/admin/events/search`, {
	// 		method: 'POST',
	// 		headers: {
	// 			// auth url
	// 			'Content-Type': 'application/json',
	// 			'Authorization': `Bearer ${token}`,
	// 		},
	// 		body: JSON.stringify({ searchTerm }),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			console.log('data', data);
	// 			setEvents(data);
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// }, [searchTerm]);

	const handleSearch = (data, term = '') => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setOrders(data);
	};

	// const handleDeleteItem = async (id) => {
	// 	if (window.confirm("Are you sure you want to delete this item?")) {
	// 		try {
	// 			// Make your fetch/axios call to delete the item from backend
	// 			fetch(`${API_URL}/admin/event/${id}`, {
	// 				method: 'DELETE',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					'Authorization': `Bearer ${token}`,
	// 				},
	// 			})
	// 				.then((response) => response.json())
	// 				.then((data) => {
	// 					if (data.error) {
	// 						alert(data.error);
	// 						return;
	// 					}

	// 					setEvents(prevData => prevData.filter(item => item.id !== id));
	// 				})
	// 				.catch((error) => {
	// 					console.error('Error:', error);
	// 				});
	// 		} catch (error) {
	// 			// Handle your error
	// 			console.error('Error:', error);
	// 		}
	// 	}
	// }

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(orders.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				order_id: value.orderId,
				status: value.statusText,
				customer: value.user.first_name + ' ' + value.user.last_name,
				order_amount: (value.amount) ? '£' + value.amount.toFixed(2):'£0.00',
				order_date: value.created_at_formatted,
				// initials: value.initials,
				// last_name: value.last_name,
				// club: value.club?.name,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Orders</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					{/* <Search handleSearch={handleSearch} endpoint="admin/orders/search" placeholder="Search Orders by ID" setLoading={setLoading} /> */}
					<Search handleSearch={handleSearch} endpoint="admin/orders/search" page={page} setIsSearching={setIsSearching} placeholder="Search Orders by ID" setLoading={setLoading} />

				</div>
				<div className="col-12">
				</div>
				<div className="col-12 form-group">
					<div>
						{(orders.data) ?
							<>
								<Table
									data={columns()}
									linkColumn="order_id"
									linkLocation="/admin/orders"
									visibleColumns={[
										{
											name: 'order_id',
											sort: 'id'
										},
										{
											name: 'customer',
											// sort: 'customer'
										},
										{
											name: 'order_amount',
											sort: 'amount'
										},
										{
											name: 'status',
											// sort: 'customer'
										},
										{
											name: 'order_date',
											sort: 'created_at'
										}
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
								/>
								<Pagination data={orders} setPage={setPage} />
							</>: <div className="alert alert-danger">No orders found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(OrdersList);
