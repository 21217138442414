import { useState } from 'react';
import { withRouter } from '../common/with-router';
import { Link } from 'react-router-dom';
import { getDashboard } from '../api/settings';
import Loading from '../components/global/loading';
import useJWT from '../jwt/useJWT';

const Dashboard = () => {
	const [page, setPage] = useState(null);
	const [loading, setLoading] = useState(true);
	const { isAuthenticated } = useJWT();

	const getPageContent = async () => {
		try {
			const response = await getDashboard();
			if (response.data.status === 'success') {
				setPage(response.data);
				setLoading(false);
			} else {
				console.error('Error retrieving page content:', response.data);
			}
		} catch (error) {
			console.log('Error retrieving page content:', error);
		}
	};

	useState(() => {
		getPageContent();
	}, []);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div dangerouslySetInnerHTML={{ __html: page.page }}></div>
					{(page.slug === 'entries_open_page') && <Link to="/add-entry" className="btn btn-primary">Make an Entry</Link>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(Dashboard);
