import { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, yupToFormErrors } from "formik";
import * as Yup from "yup";
import SelectField from './select';
import Loading from '../global/loading';
import { toast } from 'react-toastify';
import { addNewClub, getClubs } from '../../api/clubs';
import { getCountries } from '../../api/misc';

const NewClub = (props) => {
	const [countries, setCountries] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetchCountries = async () => {
		try {
			const getCountryData = await getCountries();
			const modifiedData = getCountryData.data.countries.map(item => ({
				// ...item,
				label: item.country,
				value: item.code
			}));

			setCountries(modifiedData);

		} catch (error) {
			console.log('ERR', error);
		}
	};

	useEffect(() => {
		fetchCountries();
		if (countries) setLoading(false);
	}, []);

	const createClub = async (values) => {
		const toastId = toast("Saving Club...", { autoClose: false });
		try {
			const addTheClub = await addNewClub(values.newclub);
			if (addTheClub.data.status === "success") {
				toast.update(toastId, { render: "Club Saved!", type: "success", autoClose: 2000 });
				props.updateClubs(addTheClub.data.club.id);
				props.close();
			} else {
				toast.update(toastId, { render: "Error Saving Club. Please contact support", type: "error", autoClose: 2000 });
			}
		} catch (error) {
			toast.update(toastId, { render: "Error Saving Club. Please contact support", type: "error", autoClose: 2000 });
		}
	}

	if (loading) return (<Loading />);

	const NewClubForm = () => {
		return (
			<Formik
				initialValues={{
					newclub: {
						name: "",
						country_code: "",
						contact: "",
						email: "",
						town: "",
						state: "",
						contact: "",
						tel_day: "",
						tel_eve: "",
						colours: "",
					}
				}}
				validationSchema={Yup.object({
					newclub: Yup.object({
						name: Yup.string().required("Required"),
						country_code: Yup.string().required("Required"),
						contact: Yup.string().required("Required"),
						email: Yup.string().email("Must be a valid email address").required("Required"),
						town: Yup.string().required("Required"),
						state: Yup.string().required("Required"),
						contact: Yup.string().required("Required"),
						tel_day: Yup.string().required("Required"),
						tel_eve: Yup.string().required("Required"),
						colours: Yup.string().required("Required"),
						// contact_address: Yup.string().required("Required"),
						// contact_postcode: Yup.string().required("Required"),
					})
				})}
				onSubmit={createClub}
			>
				{({ values, setFieldValue, isValid, errors, touched, isSubmitting, ...formikBag }) => (
					<Form>
						<div className="row">
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[name]">Club Name</label>
									<Field type="text" name="newclub[name]" className="form-control" />
									<ErrorMessage name="newclub[name]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[country_code]">Club Country</label>
									<Field
										component={SelectField}
										name='newclub[country_code]'
										className="form-control"
										options={countries}
									/>
									<ErrorMessage name="newclub[country_code]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[town]">Club Town</label>
									<Field type="text" name="newclub[town]" className="form-control" />
									<ErrorMessage name="newclub[town]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[state]">Club State</label>
									<Field type="text" name="newclub[state]" className="form-control" />
									<ErrorMessage name="newclub[state]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[contact]">Club Contact Name</label>
									<Field type="text" name="newclub[contact]" className="form-control" />
									<ErrorMessage name="newclub[contact]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[email]">Club Contact Email</label>
									<Field type="text" name="newclub[email]" className="form-control" />
									<ErrorMessage name="newclub[email]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[tel_day]">Daytime Telephone Number</label>
									<Field type="text" name="newclub[tel_day]" className="form-control" />
									<ErrorMessage name="newclub[tel_day]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[tel_eve]">Evening Telephone Number</label>
									<Field type="text" name="newclub[tel_eve]" className="form-control" />
									<ErrorMessage name="newclub[tel_eve]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="newclub[colours]">Club Colours</label>
									<Field type="text" name="newclub[colours]" className="form-control" />
									<ErrorMessage name="newclub[colours]" component="div" className="field-error" />
								</div>
							</div>
							<div className="col-12 text-end">
								<button type="submit" className="btn btn-primary" disabled={isSubmitting}>Add Club</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		)
	}

	return <NewClubForm />;
}

export default NewClub;
