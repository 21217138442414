import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import Table from '../components/Table';
import Loading from '../components/global/loading';
import { getMyEntries } from '../api/entries';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { downloadMyEntries } from '../api/downloads';
import { withdrawEntry } from '../api/entries';
import Pagination from '../components/Pagination';

const CompletedEntries = (props) => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [withdrawalModal, setWithdrawalModal] = useState(false);
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const initialData = async () => {
		try {
			const myEntries = await getMyEntries([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], page, sortDirection, sortedColumn);
			setEntries(myEntries.data.entries);
			setLoading(false);
		} catch (error) {
			
		}
	};

	useEffect(() => {
		if (!withdrawalModal) {
			initialData();
		}
	}, [withdrawalModal, page, sortDirection, sortedColumn]);

	if (loading) {
		return <Loading />;
	}

	const downloadPdf = async (id) => {
		const toastId = toast("Processing PDF download", { autoClose: false, type: 'info' });
		// TODO: Entry withdrawal modal for reason before submitting
		try {
			const pdf = await downloadMyEntries([4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
			// download pdf blob
			const blob = new Blob(
				[Uint8Array.from(atob(pdf.data.data), c => c.charCodeAt(0))],
				{ type: 'application/pdf' }
			);

			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'my_entries.pdf';
			link.click();

			toast.update(toastId, { autoClose: 5000, type: 'success', render: 'PDF downloaded' });
		} catch (error) {
			toast.update(toastId, { autoClose: 5000, type: 'error', render: 'Error processing PDF' });
		}
	};

	const Withdrawal = (id) => {
		// if entry is an object
		const initialValues = {
			id: id.id,
			reason: '',
		};

		const validationSchema = Yup.object().shape({
			reason: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);

			const confirmWithdraw = window.confirm("Are you sure you want to withdraw this entry?");

			if (!confirmWithdraw) {
				setSubmitting(false);
				return;
			}

			const toastId = toast("Withdrawing Entry", { autoClose: false });

			const data = {
				...values,
			};

			(async () => {
				const fetchWithdrawnEntry = await withdrawEntry(id.id, data);
				if (fetchWithdrawnEntry.data.status === 'success') {
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry withdrawn", autoClose: 5000 });
					setWithdrawalModal(false);
				} else {
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error withdrawing entry. Please contact support.", autoClose: 5000 });
					console.error('Error:', fetchWithdrawnEntry);
				}
			})();
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid }) => {
							return (
								<Form>
									<div className="form-group-flex">
										<label htmlFor="username">Withdrawal Reason:</label>
										<Field type="hidden" name="id" />
										<Field component="textarea" className="form-control" name="reason" />
									</div>

									<div className="form-group form-buttons">
										<button
											className="btn btn-primary btn-block"
											type="submit"
											disabled={(loading)}
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Withdraw Entry</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				id: value.id,
				crew_name: (value.status === 3) ? <><strong>Withdrawn</strong> - <s>{value.crew.crew_name}</s></> : value.crew.crew_name,
				event: value.event.name,
				athletes: <ul>
					{value.crew?.crew_members?.map((athlete, key) => {
						return <li key={key}>{athlete.athletes?.first_name[0] + '.' + athlete.athletes?.initials + ' ' + athlete.athletes?.last_name}</li>;
					})}
				</ul>,
				reference: value.crew.identifier,
				date_added: value.created_at_formatted,
				withdraw: (value.status !== 3) ? <button type="button" onClick={() => setWithdrawalModal(value.id)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button> :'',
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>My Entries</h1>
					<div>
						{(entries.data && Object.entries(entries.data).length > 0) ? 
							<>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'crew_name',
											sort: 'crew_name'
										},
										{
											name: 'event',
											sort: 'event_name'
										},
										{
											name: 'athletes',
											sort: 'athletes'
										},
										{
											name: 'reference',
											sort: 'reference'
										},
										{
											name: 'date_added',
											sort: 'created_at'
										},
										{
											name: 'withdraw'
										}
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									// hideTitles={['withdraw']}
									linkColumn="crew_name"
									linkLocation="/entries/edit"
									// editable={true}
								/>
								<div className="text-end mb-4"><button type="button" onClick={() => downloadPdf()} className="btn btn-warning"><FontAwesomeIcon icon={faFilePdf} /> Download Entries (PDF)</button></div>
								<Pagination data={entries} setPage={setPage} />
							</>:
							// <div className="alert alert-info">No completed entries found. <Link to="/add-entry">Click here to add a new entry</Link></div>
							<div className="alert alert-info">No completed entries found.</div>
						}
					</div>
				</div>
			</div>

			{(withdrawalModal) &&
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Withdraw Entry</h5>
								<button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setWithdrawalModal(false)}>{<FontAwesomeIcon icon={faTimes} />}</button>
							</div>
							<div className="modal-body">
								{<Withdrawal id={withdrawalModal} />}
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default withRouter(CompletedEntries);
