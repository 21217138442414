import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../common/with-router';
import Table from '../components/Table';
import Loading from '../components/global/loading';
import { getMyEntries, deleteMyEntry } from '../api/entries';
import Pagination from '../components/Pagination';

const SavedEntries = (props) => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};
	// const [message, setMessage] = useState("");

	const initialData = async () => {
		try {
			const myEntries = await getMyEntries(0, page, sortDirection, sortedColumn);

			if (myEntries.data.status === 'success') {
				setEntries(myEntries.data.entries);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// deploybot
	// get copy of processes

	useEffect(() => {
		initialData();
	}, [page, sortDirection, sortedColumn]);

	if (loading) {
		return <Loading />;
	}

	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this entry? This cannot be undone.")) {
			try {
				const deleteEntryResponse = await deleteMyEntry(id);
				console.log('deleteEntryResponse', deleteEntryResponse);
				if (deleteEntryResponse.data.status === 'success') {
					setEntries({
						...entries,
						data: entries.data.filter((entry) => entry.id !== id),
					});
				}
			} catch (error) {
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				id: value.id,
				crew_name: value.crew.crew_name,
				event: value.event.name,
				date_added: value.created_at_formatted,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Saved Entries</h1>
					<div>
						{(entries.data && Object.entries(entries.data).length > 0) ?
							<>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'crew_name',
											sort: 'crew_name'
										},
										{
											name: 'event',
											sort: 'event_name'
										},
										{
											name: 'date_added',
											sort: 'created_at'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="crew_name"
									linkLocation="/entries/edit"
									editable={true}
									handleDelete={handleDeleteItem}
								/>
								<Pagination data={entries} setPage={setPage} />
							</>:
							// <div className="alert alert-info">No saved entries found. <Link to="/add-entry">Click here to add a new entry</Link></div>
							<div className="alert alert-info">No saved entries found.</div>
						}
						
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(SavedEntries);
