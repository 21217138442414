import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select'
import { toast } from 'react-toastify';
import { getEventType } from '../../../api/events';

const EventTypesSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');
	
	const [loading, setLoading] = useState(false);
	const [eventtype, setEventType] = useState({});
	// const [message, setMessage] = useState(null);

	// const eventTypeList = (eventTypes && eventTypes.length > 0) ? eventTypes.map((item) => {
	// 	return {
	// 		value: item.id,
	// 		label: item.name,
	// 	}
	// }) : [];
	const getInitialData = async () => {
		try {
			const getEventsTypesRes = await getEventType(props.router?.params?.id);
			if (getEventsTypesRes.data.status === 'success') {
				setEventType(getEventsTypesRes.data.type);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			setLoading(true);
			getInitialData();
		}
	}, []);

	const eventForm = () => {
		// if entry is an object
		const initialValues = {
			name: (eventtype && eventtype.name) ? eventtype.name : '',
			cox: (eventtype && eventtype.cox) ? true : false,
			steer: (eventtype && eventtype.steer) ? true : false,
			gender: (eventtype && eventtype.gender) ? eventtype.gender : '',
			athlete_qty: (eventtype && eventtype.athlete_qty) ? eventtype.athlete_qty : '',
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required('Required'),
			steer: Yup.boolean(),
			cox: Yup.boolean(),
			gender: Yup.string().matches(/^[FM]$/, 'Must be F or M').required('Required'),
			athlete_qty: Yup.number().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/event-type/${data.id}`:`${API_URL}/admin/event-type`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT':'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					console.log('Success:', res);
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Event saved successfully!", autoClose: 5000});

					if (res.id && !data.id) {
						window.location.href = `/admin/event-types/edit/${res.id}`;
					}
				})
				.catch((error) => {
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
					console.error('Error:', error);
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid }) => {
							return (
								<Form>
									<div className="form-group-flex">
										<label htmlFor="username">Name:</label>
										<Field type="text" className="form-control" name="name" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Gender</label>
										<Field type="text" name="gender" className="form-control" onBlur={(val) => {
											// if not F or M, set to null and alert user
											if (val.target.value !== 'F' && val.target.value !== 'M') {
												setFieldValue('gender', null);
											}
										}}/>
										<ErrorMessage name="gender" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Athlete Quantity</label>
										<Field type="number" name="athlete_qty" className="form-control" />
										<ErrorMessage name="athlete_qty" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Has Cox</label>
										<Field type="checkbox" name="cox" className="form-check-input" />
										<ErrorMessage name="cox" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Has Steer</label>
										<Field type="checkbox" name="steer" className="form-check-input" />
										<ErrorMessage name="steer" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="price">Price</label>
										<Field type="number" name="price" className="form-control" />
										<ErrorMessage name="cox" component="div" className="invalid-feedback" />
									</div>
	
									<div className="form-group form-buttons">
										<button
											className="btn btn-primary btn-block"
											type="submit"
											disabled={(loading)}
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					{(eventtype) ? <h1>Edit Event Type</h1>:<h1>Add Event Type</h1>}
				</div>

				<div className="col-12 form-group">
					{eventForm()}
				</div>
			</div>
		</div>
	);
}

export default withRouter(EventTypesSingle);
