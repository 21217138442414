import instance from './config';

const getAthletes = (page = 1, sortDir = 'asc', sortCol = 'id', filters = {}) => {
	let filterQuery = "";

	console.log('filters', filters);

	Object.entries(filters).map((filter, key) => {
		if (filter[1] && filter[1].value) {
			if (Array.isArray(filter[1])) {
				filter[1].forEach((filterValue) => {
					filterQuery += `${filter[0]}[]=${filterValue.value}&`;
				});
			} else {
				filterQuery += `${filter[0]}=${filter[1].value}&`;
			}
		}
	});

	// remove the last &
	filterQuery = filterQuery.slice(0, -1);

	return instance.get(`/admin/athletes?${filterQuery}&page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getAthlete = (id) => {
	return instance.get(`/admin/athletes/${id}`);
};

const deleteAthlete = (id) => {
	return instance.delete(`/admin/athletes/${id}`);
};

const saveAthlete = (data) => {
	if (data.id) {
		return instance.put(`/admin/athletes/${data.id}`, data);
	}

	return instance.post(`/admin/athletes`, data);
};

const checkInAthlete = (data) => {
	return instance.post('/admin/checkin', data);
};

const saveCoxWeight = (id, data) => {
	return instance.put(`/admin/athletes/${id}`, data);
};

const saveAthleteHistory = (data) => {
	return instance.post(`/admin/history`, data);
};

const deleteAthleteHistory = (id) => {
	return instance.delete(`/admin/history/${id}`);
};

const getDuplicateAthletes = () => {
	return instance.get('/admin/duplicate-athletes');
}

const mergeAthleteData = (data) => {
	return instance.post('/admin/duplicate-athletes/merge', data);
}

export {
	getAthletes,
	getAthlete,
	deleteAthlete,
	saveAthlete,
	checkInAthlete,
	saveCoxWeight,
	saveAthleteHistory,
	deleteAthleteHistory,
	getDuplicateAthletes,
	mergeAthleteData
}
