import { NavLink } from "react-router-dom";

const Footer = () => {
	return (
		<footer>
			<div className="main-footer">
				<div className="container">
					<ul>
						<li>
							<NavLink to="https://www.hrr.co.uk/privacy-cookie-policy/" target="_blank">Privacy and Cookie Policy</NavLink>
						</li>
					</ul>
				</div>
			</div>
			<div className="copyright">
				<div className="container">

					<small style={{ color: '#fff', display: 'block' }}>HERMES v{process.env.REACT_APP_VERSION}</small>
					&copy; {new Date().getFullYear()} - All Rights Reserved
				</div>
			</div>
		</footer>
	);
};

export default Footer;
