import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { adminGetUsers } from '../../../api/user';

const UserList = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState({});
	const [isSearching, setIsSearching] = useState(false);
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getAllUsers = await adminGetUsers(page, sortDirection, sortedColumn);

			if (getAllUsers.data.status === 'success') {
				setUsers(getAllUsers.data.users);
				setLoading(false);
			}
		} catch (error) {
			console.log('ERROR', error);
		}
	}

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setPage(1);
		setUsers(data);
	};

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(users.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				first_name: value.first_name,
				last_name: value.last_name,
				email: value.email,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Users <Link to="/admin/users/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/users/search" page={page} setIsSearching={setIsSearching} placeholder="Search Name or Email" setLoading={setLoading} />
				</div>
				<div className="col-12 form-group">
					<div>
						{(users.data) ?
							<div>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										{
											name: 'first_name',
											sort: 'first_name'
										},
										{
											name: 'last_name',
											sort: 'last_name'
										},
										{
											name: 'email',
											sort: 'email'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="email"
									linkLocation="/admin/users/edit"
									editable={true}
								/>
								<Pagination data={users} setPage={setPage} />
							</div> : <div className="alert alert-danger">No users found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(UserList);
