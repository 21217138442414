import { useContext } from 'react';
import JWTContext from './jwtContext';

const useJWT = () => {
	const context = useContext(JWTContext);

	if (!context) {
		throw new Error('useJWT must be used within a JWTProviderComponent');
	}

	return context;
};

export default useJWT;
