export const CART = 'CART';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';

const cartAction = (cart) => {
	return {
		type: CART,
		payload: cart,
	}
};

const deleteCartItemById = (itemId) => {
	return {
		type: DELETE_CART_ITEM,
		payload: itemId,
	};
};

const clearCart = () => {
	return {
		type: CLEAR_CART,
		payload: {},
	};
};

export { cartAction, deleteCartItemById, clearCart };
