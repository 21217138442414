import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const SearchSchema = Yup.object().shape({
	search: Yup.string().required('Required'),
});

const Search = ({ placeholder, endpoint, handleSearch, isLoading, page, setIsSearching }) => {
	const [term, setTerm] = useState('');

	const clearSearch = () => {
		handleSearch(null);
		setIsSearching(false);
	}

	useEffect(() => {
		if (term) {
			doSearch(term);
		}
	}, [page]);


	const doSearch = async (term) => {
		const API_URL = process.env.REACT_APP_API_URL;
		const token = localStorage.getItem('_rrjt');
		
		setTerm(term);
		

		await fetch(`${API_URL}/${endpoint}?page=${page}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify({ term }),
		})
			.then((response) => response.json())
			.then((data) => {
				handleSearch(data);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	return (
		<Formik
			initialValues={{ search: '' }}
			validationSchema={SearchSchema}
			onSubmit={(values, { setSubmitting }) => {
				doSearch(values.search).finally(() => setSubmitting(false));
			}}
		>
			{({ isSubmitting, resetForm }) => (
				<Form>
					<div className="d-flex">
						<Field type="text" name="search" className="form-control" placeholder={(placeholder) ? placeholder:'Search...'} />
						<button type="submit" className="btn btn-primary" disabled={isSubmitting}>
							{isSubmitting ? 'Searching...' : 'Search'}
						</button>
						<button type="button" className="btn btn-secondary" onClick={() => { resetForm(); clearSearch(); }}>
							Reset
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default Search;
