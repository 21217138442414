import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
	baseURL: API_URL,
});

instance.interceptors.request.use((config) => {
	const token = localStorage.getItem('_rrjt');
	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}
	return config;
});

instance.interceptors.response.use(
	(response) => {
		// Do something with response data
		return response;
	},
	async (error) => {
		const originalRequest = error.config;

		// If the error response indicates that the token has expired and we haven't already retried the request
		if (error.response && error.response.data.status === 'blocked' && !originalRequest._retry) {
			// remove access token
			localStorage.removeItem('_rrjt');
			window.location.href = '/login';
		}

		if (error.response && error.response.data.status === 'token_expired' && !originalRequest._retry) {
			originalRequest._retry = true;

			// Attempt to refresh the token using the refreshToken stored in localStorage
			const refreshToken = localStorage.getItem('_rrjt');
			const { data } = await axios.post(`${API_URL}/refresh`, { token: refreshToken });
			if (data.access_token) {
				// Save the new JWT token and update the Authorization header
				localStorage.setItem('_rrjt', data.access_token);
				instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;

				// Retry the original request
				return instance(originalRequest);
			} else {
				// If refresh fails, redirect to login
				console.log('It failed', data);
				window.location.href = '/login';
			}
		}

		return Promise.reject(error);
	}
);

export default instance;
