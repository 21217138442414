import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';
import { getLogs } from '../../../api/logs';

const Logs = () => {
	const [loading, setLoading] = useState(true);
	const [logs, setLogs] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');
	const [isSearching, setIsSearching] = useState(false);

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setLogs(data.logs);
	};

	const getInitialData = async () => {
		try {
			const fetchLogs = await getLogs(page, sortDirection, sortedColumn);
			if (fetchLogs.data.status === 'success') {
				setLogs(fetchLogs.data.logs);
				setLoading(false);
			}
		} catch (error) {
			console.log('Error', error);
		}
	}

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
		getInitialData();
	}, [page, sortDirection, sortedColumn]);

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(logs.data).map(([key, value]) => {
			let endpoint = '';

			switch (value.table_name) {
				case 'entries':
					endpoint = '/admin/entries/edit';
					break;
				default:
					break;
			}

			return {
				view: 'admin',
				id: value.id,
				action: value.action,
				type: value.table_name,
				text: <div dangerouslySetInnerHTML={{__html: value.text_change}}></div>,
				resource: <Link to={`${endpoint}/${value.related_resource?.id}`}>{value.related_resource?.id}</Link>,
				user: (value.user?.first_name) ? `${value.user.first_name} ${value.user.last_name}` : 'N/A',
				date: value.created_at_formatted,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Logs</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/logs/search" page={page} setIsSearching={setIsSearching} placeholder="Search Entries by ENC Number" setLoading={setLoading} />
				</div>

				<div className="col-12 form-group">
					<div>
						{(logs.data.length) ?
							<div>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'user',
											sort: 'user_id'
										},
										{
											name: 'action',
											sort: 'action'
										},
										{
											name: 'type',
											sort: 'table_name'
										},
										{
											name: 'text',
											sort: 'text_change'
										},
										{
											name: 'resource',
											sort: 'resource_id'
										},
										{
											name: 'date',
											sort: 'created_at'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="resource"
									linkLocation="/admin/logs/view"
								/>
								<Pagination data={logs} setPage={setPage} />
							</div> : <div className="alert alert-danger">No logs found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Logs);
