import { useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from '../../common/with-router';
import { login } from '../../api/auth';
import useJWT from '../../jwt/useJWT';
import * as Yup from "yup";

const Login = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const validateemail = searchParams.get('validateemail');

	const [message, setMessage] = useState((validateemail) ? 'Please validate your email before logging in.' : null);
	const { updateJWT, isAuthenticated } = useJWT();

	// if validateemail param exists in url
	const formsubmit = async (values) => {
		try {
			const loginData = await login(values);

			if (loginData.data.access_token) {
				updateJWT(loginData.data.access_token);
				dispatch({ type: 'USER', payload: loginData.data.user });
				navigate('/', { replace: false });
			} else {
				window.grecaptcha.reset();
			}
		} catch (error) {
			if (error.response.data.message) {
				// reset the recaptcha
				window.grecaptcha.reset();
				setMessage(error.response.data.message);
			}
		}
	}

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Required'),
		password: Yup.string().required('Required'),
		recaptcha: Yup.string().required('Please verify you are not a robot'),
	});

	if (isAuthenticated) {
		return (
			<div className="row justify-content-center">
				<div className="col-12">
					<h1>Sign In</h1>
					<p>You are already logged in.</p>
				</div>
			</div>
		);
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-md-8">
					{(message) ? (
						<div className="alert alert-info" role="alert">{message}</div>
						):null
					}

					{(!validateemail) && 
						<>
							<h1>Sign In</h1>
							<p>Welcome! Please enter your login details</p>
							{/* <p className="alert alert-info"><strong>NOTE:</strong> User IDs will not be carried over from last year and you will need to <Link to="/register">re-register</Link>.</p> */}
							<Formik initialValues={{
								email: '',
								password: '',
								recaptcha: '',
							}}
								validationSchema={validationSchema}
								onSubmit={formsubmit}>
								{({ handleSubmit, isSubmitting, setFieldValue }) => (
									<Form>
										<div className="mb-2">
											<label htmlFor="email">Email</label>
											<Field type="email" className="form-control" id="email" name="email" placeholder="Enter email" />
											<ErrorMessage name="email" component="div" className="field-error" />
										</div>
										<div className="mb-2">
											<label htmlFor="password">Password</label>
											<Field type="password" className="form-control" id="password" name="password" placeholder="Password" />
											<ErrorMessage name="password" component="div" className="field-error" />
										</div>
										<div className="mb-2">
											<ReCAPTCHA
												sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
												onChange={(value) => {
													// value is the reCAPTCHA response, store it in the form values
													setFieldValue('recaptcha', value);
												}}
											/>
											<ErrorMessage name="recaptcha" component="div" className="field-error" />
										</div>
										<button type="submit" className="btn btn-primary" disabled={(isSubmitting) && true}>Login</button> <Link to="/register">Register</Link>

										<div className="d-block mt-2">
											<Link to="/reset-password">Forgot Password?</Link>
										</div>
									</Form>
								)}
							</Formik>
						</>
					}
				</div>
			</div>
		</div>
	);
};

export default withRouter(Login);
