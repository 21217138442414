import instance from './config';

const getCart = () => {
	return instance.get(`/cart`);
};

const addToCart = (data) => {
	return instance.post(`/cart`, data);
};

const removeFromCart = (id) => {
	return instance.delete(`/cart/${id}`);
};

export {
	getCart,
	addToCart,
	removeFromCart
}
