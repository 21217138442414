import { Link } from 'react-router-dom';
import { withRouter } from '../../common/with-router';

const NotFound = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 text-center">
					<h1>404 Not Found</h1>
					<p>The link you requested was not found. <Link to={'/'}>Click here to return to the home page.</Link></p>
				</div>
			</div>
		</div>
	);
}

export default withRouter(NotFound);
