import instance from './config';

// Public routes
const saveRaceTimetable = (data, day) => {
	return instance.post(`/admin/races/${day}`, data);
};

const getRaceTimetable = (day = 'tues') => {
	return instance.get(`/admin/races/${day}`);
};

export {
	saveRaceTimetable,
	getRaceTimetable
}
