import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../components/Pagination';
import { deleteClub, adminGetClubs } from '../../../api/clubs';
import { toast } from 'react-toastify';

const ClubsList = (props) => {
	const [loading, setLoading] = useState(true);
	const [clubs, setClubs] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('asc');
	const [sortedColumn, setSortedColumn] = useState('id');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getClubRes = await adminGetClubs(page, sortDirection, sortedColumn);
			if (getClubRes.data.status === "success") {
				setClubs(getClubRes.data.clubs);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [page, sortDirection, sortedColumn]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setClubs({data: data});
	};

	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item? Please note this action should NOT be done during a LIVE Regatta registration. This application may crash or have issues with entry relationships as a result.")) {
			const toastId = toast("Deleting Club...", { autoClose: false, type: toast.TYPE.INFO });

			try {
				const deleteRes = await deleteClub(id);

				if (deleteRes.data.status === 'success') {
					toast.update(toastId, { render: 'Club Deleted!', type: toast.TYPE.SUCCESS, autoClose: 5000 });
					setClubs({
						...clubs,
						data: clubs.data.filter((club) => club.id !== id),
					});
				}
			} catch (error) {
				toast.update(toastId, { render: 'Deleting club failed. Please contact support.', type: toast.TYPE.ERROR, autoClose: 5000 });
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(clubs.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				alpha_code: value.alpha_code,
				name: value.name,
				country_code: value.country_code,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Clubs <Link to="/admin/clubs/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/clubs/search" placeholder="Search Clubs by Name or Code" setLoading={setLoading} />
				</div>

				<div className="col-12 form-group">
					<div>
						{(clubs.data && clubs.data.length) ?
							<div>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'alpha_code',
											sort: 'alpha_code'
										},
										{
											name: 'name',
											sort: 'name'
										},
										{
											name: 'country_code',
											sort: 'country_code'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="name"
									linkLocation="/admin/clubs/edit"
									editable={true}
									handleDelete={handleDeleteItem}
								/>
								<Pagination data={clubs} setPage={setPage} />
							</div> : <div className="alert alert-danger">No clubs found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(ClubsList);
