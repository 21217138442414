import instance from './config';

const getCountries = () => {
	return instance.get('/admin/countries');
};

const addCountry = (data) => {
	return instance.post('/admin/countries', data);
};

const deleteCountry = (id) => {
	return instance.delete(`/admin/countries/${id}`);
}

export {
	getCountries,
	addCountry,
	deleteCountry,
}
