import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { profileUpdate } from '../api/user';

const Profile = () => {
	// get currentUser from dispatch
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.user);

	const [loading, setLoading] = useState(true);

	const getInitialData = () => {
		setLoading(false);
	}

	useEffect(() => {
		setLoading(true);
		getInitialData();
	}, []);

	const ClubForm = () => {
		console.log('currentUser', currentUser);
		const initialValues = {
			first_name: (currentUser && currentUser.user?.first_name) ? currentUser.user.first_name : '',
			last_name: (currentUser && currentUser.user?.last_name) ? currentUser.user.last_name : '',
			email: (currentUser && currentUser.user?.email) ? currentUser.user.email : '',
			password: '',
			password_confirm: '',
			news_event_updates: (currentUser && currentUser.user?.news_event_updates) ? currentUser.user.news_event_updates : false,
			shop_info: (currentUser && currentUser.user?.shop_info) ? currentUser.user.shop_info : false,
			partner_marketing: (currentUser && currentUser.user?.partner_marketing) ? currentUser.user.partner_marketing : false,
		};

		const validationSchema = Yup.object().shape({
			first_name: Yup.string().required('Required'),
			last_name: Yup.string().required('Required'),
			password: Yup.string().min(8, 'Password must be at least 8 characters').matches(/^(?=.*[0-9])(?=.*[!@#$%^_&*-])[a-zA-Z0-9!@#$%^&*_-]+$/, 'Password must contain at least one number and one symbol'),
			password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			// if (props.router?.params?.id) {
			// 	data.id = props.router?.params?.id;
			// }

			try {
				const doProfileUpdate = await profileUpdate(currentUser.user.id, data);
				if (doProfileUpdate.data) {
					try {
						dispatch({ type: 'USER', payload: doProfileUpdate.data.user });
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Profile saved!", autoClose: 5000 });
					} catch (error) {
						toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving profile.", autoClose: 5000 });
						console.log(error);
					}
				}
			} catch (error) {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving profile.", autoClose: 5000 });
				console.log(error);
			}
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							return (
								<Form>
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="currentUsername">First Name</label>
												<Field type="text" className="form-control" name="first_name" />
												<ErrorMessage name="first_name" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Last Name</label>
												<Field type="text" name="last_name" className="form-control" />
												<ErrorMessage name="last_name" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-12">
											<div className="form-group-flex">
												<label htmlFor="event_code">Email</label>
												<Field type="email" name="email" className="form-control" />
												<ErrorMessage name="email" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Change Password</label>
												<Field type="password" name="password" className="form-control" />
												<ErrorMessage name="password" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Password Confirm</label>
												<Field type="password" name="password_confirm" className="form-control" />
												<ErrorMessage name="password_confirm" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-12">
											<div className="form-field form-check mb-2">
												<Field type="checkbox" className="form-check-input" name="news_event_updates" />
												<label htmlFor="terms" className="form-check-label">
													HRR may send me company news and event updates, including information about ticket sales and offers (including offers exclusive to Members and Competitors)
												</label>
												<ErrorMessage name="news_event_updates" component="div" className="field-error" />
											</div>
											<div className="form-field form-check mb-2">
												<Field type="checkbox" className="form-check-input" name="shop_info" />
												<label htmlFor="terms" className="form-check-label">
													The HRR Official Shop may send me information on its products and special offers (including offers exclusive to Members and Competitors)
												</label>
												<ErrorMessage name="shop_info" component="div" className="field-error" />
											</div>
											<div className="form-field form-check">
												<Field type="checkbox" className="form-check-input" name="partner_marketing" />
												<label htmlFor="terms" className="form-check-label">
													HRR's Official Partners may send me information relating to their products and services (including offers exclusive to Members and Competitors)
												</label>
												<ErrorMessage name="partner_marketing" component="div" className="field-error" />
											</div>
										</div>
									</div>

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					{(currentUser) && <h1>Edit Bio</h1>}
				</div>

				<div className="col-12 form-group">
					{<ClubForm />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(Profile);
