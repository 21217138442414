import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../components/global/loading';
import { createEntry } from '../api/entries';

const CreateEntry = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const getEvents = useSelector((state) => state.events);

	const [loading, setLoading] = useState(false);
	const [event, setEvent] = useState(false);
	const [entry, setEntry] = useState(false);

	useEffect(() => {
		// This is a hack to flush the state when we navigate back to this page
		if (location.state?.flush) {
			setEntry(false);
			setEvent(false);

			location.state.flush = false;
		}
	}, [location.state]);


	useEffect(() => {
		if (event && !entry) {
			setLoading(true);
			(async () => {
				const createAnEntry = await createEntry(event.id);
				if (createAnEntry.data.status === 'success') {
					setEntry(createAnEntry.data.entry_id);
					navigate('/entries/edit/' + createAnEntry.data.entry_id, { replace: false });
					setLoading(false);
				}
			})();
		}
	}, [event]);

	const displayEvents = () => {
		if (getEvents.events && getEvents.events.length > 0) {
			return (
				<table className="table table-striped w-100">
					<thead>
						<tr>
							<th>Event</th>
							<th>Event Type</th>
						</tr>
					</thead>
					<tbody>
						{getEvents.events.map(event => {
							return (
								<tr key={event.id}>
									<td><button className="btn btn-link" onClick={() => setEvent(event)}>{event.name}</button></td>
									<td>{event.event_type_name}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			)
		} else {
			return <p>Error: No events found</p>
		}
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Create Entry</h1>
					{(getEvents) && 
						<div>
							<p>Select an event to start your entry.</p>
							{displayEvents()}
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default withRouter(CreateEntry);
