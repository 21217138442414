import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../common/with-router';
import { useSelector, useDispatch } from 'react-redux';
import useJWT from '../jwt/useJWT';
import { Formik, Form, Field, ErrorMessage, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { dateFormatterChange, dateFormatterBlur } from '../utils/dateFormatter';
import Loading from '../components/global/loading';
import SelectField from '../components/forms/select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getClubs } from '../api/clubs';
import { addToCart } from '../api/cart';
import { getEntry, saveEntry } from '../api/entries';
import NewClub from '../components/forms/newClub';
import { getCrewMemberOrder, fuzzySearch } from '../utils/Helpers';
import moment from 'moment';
import { getCurrentUser } from '../api/user';

const AddEntry = (props) => {
	const dispatch = useDispatch();

	const { refreshJWT } = useJWT();

	const getEvents = useSelector((state) => state.events);
	const getUser = useSelector((state) => state.user);

	const [loading, setLoading] = useState(true);
	const [event, setEvent] = useState(false);
	const [clubs, setClubs] = useState(false);
	const [entry, setEntry] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selectKey, setSelectKey] = useState(0);

	const formikRef = useRef(null);

	const fetchClubs = async () => {
		try {
			const getClubData = await getClubs();
			if (getClubData.data.status === 'success') {
				const modifiedData = getClubData.data.clubs.map(item => ({
					...item,
					label: item.name,
					value: item.id
				}));

				console.log(modifiedData);

				setClubs(modifiedData);
			}
		} catch (error) {
			console.log('ERR', error);
		}
	};

	const fetchEntry = async () => {
		const id = props.router?.params?.id;
		if (id) {
			try {
				const getEntryData = await getEntry(id);

				if (getEntryData.data.status === 'success') {
					const crewMembers = getCrewMemberOrder(getEntryData.data.entry?.crew.crew_members);
					getEntryData.data.entry.crew.crew_members = crewMembers;

					const newSelectedClubs = {};
					getEntryData.data.crew?.crew_members.forEach((member, index) => {
						newSelectedClubs[index] = member.club;
					});

					// setSelectedClubs(prevState => ({ ...prevState, ...newSelectedClubs }));
					setEntry(getEntryData.data.entry);
					const foundEvent = getEvents.events.find((ev) => ev.id === getEntryData.data.entry.event_id);
					setEvent(foundEvent);
				} else {
					toast("Error fetching entry. Please contact support.", { autoClose: true, type: toast.TYPE.ERROR });
					console.error('Error:', getEntryData);
				}
			} catch (error) {
				console.log('ERR', error);
			}
		}
	};

	useEffect(() => {
		if (!clubs) fetchClubs();
		if (!entry) fetchEntry();
		if (clubs && entry && event) setLoading(false);
	}, [clubs, entry, event]);

	const countUniqueClubs = (arr) => {
		const clubSet = new Set();

		arr.forEach(athlete => {
			if (athlete.club) {
				clubSet.add(athlete.club);
			}
		});

		return clubSet.size;
	}

	const getClubData = (currentAthleteValues, unique = false) => {
		let clubData = currentAthleteValues.map(athlete => {
			if (athlete.club) {
				return clubs.find((club) => club.id === athlete.club);
			}
		}).filter(club => club !== undefined);

		if (unique) {
			clubData = [...new Set(clubData)];
		}

		return clubData;
	}

	const checkClubValue = async (selectedOption, index, currentValues, setValue) => {
		const athleteList = formikRef.values.athletes;

		await setValue(`athletes[${index}].club`, selectedOption);

		if (index === 0) {
			// loop currentValues and if it's not defined, set this value as its current value
			currentValues.athletes.forEach((athlete, i) => {
				if (event.composite === 0) {
					setValue(`athletes[${i}].club`, selectedOption);
				}

				if (!athlete.club) {
					setValue(`athletes[${i}].club`, selectedOption);
				}
			});
		}

		if (event.composite > 0) {
			const clubCount = countUniqueClubs(athleteList);
			if (clubCount > event.composite) {
				toast("Only " + event.composite + " clubs are allowed to be entered for this event. You have reached the maximum for this event in your entry.", { autoClose: true, type: toast.TYPE.ERROR });
				await setValue(`athletes[${index}].club`, null, () => {
					setSelectKey(prevKey => prevKey + 1);
				});

				return;
			}
		}
	};

	const handleDateChange = (value, name, setFieldValue, period = "past") => {
		const formattedValue = dateFormatterChange(value.target.value, period);
		setFieldValue(name, formattedValue);
	};
	
	const handleDateBlur = (value, name, setFieldValue, period = "past") => {
		const formattedValue = dateFormatterBlur(value.target.value, period);
		setFieldValue(name, formattedValue);
	};

	const handleSave = async (values) => {
		// setSubmitting(true);
		// setSubmitAttempted(true);
		// setSubmitAction(type);
		const toastId = toast("Saving...", { autoClose: false, type: toast.TYPE.INFO });

		const data = {
			...values,
			entry_id: entry.id,
			event_id: event.id,
			crew_id: entry.crew.id,
			basket: true
		};

		// fetch put
		// (async () => {
		const submitSaveEntry = await saveEntry(entry.id, data);
		if (submitSaveEntry.data.status === 'success') {
			// const payload = { [data.entry_id]: submitSaveEntry.data.entry };
			// setSubmitting(false);
			toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry saved successfully!", autoClose: 5000 });
			// dispatch(cartAction(payload));

			// Get new user data
			await refreshJWT();

			const getCurrentUserRes = await getCurrentUser();
			if (getCurrentUserRes.data.status === 'success') {
				dispatch({ type: 'USER', payload: getCurrentUserRes.data.user });
			}

			const addToCartRes = await addToCart({entry_id: entry.id});
			if (addToCartRes.data.status === 'success') {
				window.location.href = '/basket';
			}
		} else {
			if (submitSaveEntry.data.doubling) {
				// loop doubling and concat the values
				const doubling = submitSaveEntry.data.doubling.map(d => d.position).join(', ');
				toast.update(toastId, { type: toast.TYPE.ERROR, render: submitSaveEntry.data.message + ' Problem positions: ' + doubling, autoClose: 5000 });
			}
		}
	};

	const handleSaveAct = async (values) => {
		const toastId = toast("Saving...", { type: toast.TYPE.INFO, autoClose: false });

		const data = {
			...values,
			entry_id: entry.id,
			event_id: event.id,
			crew_id: entry.crew.id,
			basket: false 
		};

		const submitSaveEntry = await saveEntry(entry.id, data);
		if (submitSaveEntry.data.status === 'success') {
			setEntry(submitSaveEntry.data.entry);
			toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Entry saved successfully!", autoClose: 5000 });
		}

		const getCurrentUserRes = await getCurrentUser();
		if (getCurrentUserRes.data.status === 'success') {
			dispatch({ type: 'USER', payload: getCurrentUserRes.data.user });
		}
	};

	// let hasCurrentUserAthlete = false;
	const EventForm = () => {
		const steerIndex = (entry && entry.crew.crew_members?.findIndex(member => member.steer === 1)) ? entry.crew.crew_members?.findIndex(member => member.steer === 1) : '0';
		const initialValues = {
			athletes: Array.from({ length: event.event_types.athlete_qty }, (_, index) => {
				// hasCurrentUserAthlete = (entry && entry.crew.crew_members?.[index]?.athletes?.user_id === getUser.user.id) ? true : false;
				return {
					position: index === 0 ? 'Bow' :
						index === (event.event_types?.athlete_qty - 1) && event.event_types?.cox === 1 ? 'Cox' :
						index === (event.event_types?.athlete_qty - 2) && event.event_types?.cox === 1 ? 'Stroke' :
						index === (event.event_types?.athlete_qty - 1) && event.event_types?.cox === 0 ? 'Stroke' :
						index + 1,
					club: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.club_id) ? entry.crew.crew_members?.[index]?.athletes?.club_id : '',
					first_name: (entry && entry.crew.crew_members?.[index]?.athletes && entry.crew.crew_members?.[index]?.athletes?.first_name) ? entry.crew.crew_members?.[index]?.athletes?.first_name : '',
					last_name: (entry && entry.crew.crew_members?.[index]?.athletes && entry.crew.crew_members?.[index]?.athletes?.last_name) ? entry.crew.crew_members?.[index]?.athletes?.last_name : '',
					dob: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.dob) ? entry.crew.crew_members?.[index]?.athletes?.dob : '',
					initials: {
						0: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[0] : '',
						1: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[1] : '',
						2: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2]) ? entry.crew.crew_members?.[index]?.athletes?.initial_parts?.[2] : '',
					},
					previously_rowed: (entry && entry.crew.crew_members?.[index] && entry.crew.crew_members?.[index]?.athletes?.previously_rowed) ? entry.crew.crew_members?.[index]?.athletes?.previously_rowed : 0,
				}
			}),
			// find where steer === 1 from crew_members
			steer: (entry && steerIndex && steerIndex !== -1) ? steerIndex.toString() : '0',
			crew: {
				emergency_contact_name: (entry && entry.crew.emergency_contact_name) ? entry.crew.emergency_contact_name : '',
				emergency_contact: (entry && entry.crew.emergency_contact) ? entry.crew.emergency_contact : '',
				crew_ranking_index: (entry && entry.crew.crew_ranking_index) ? entry.crew.crew_ranking_index : '',
				crew_rank: (entry && entry.crew.crew_rank) ? entry.crew.crew_rank : '',
				club_id_1: (entry && entry.crew.club_id_1) ? entry.crew.club_id_1 : '',
				club_id_2: (entry && entry.crew.club_id_2) ? entry.crew.club_id_2 : '',
			},
			coach: {
				is_coach: (entry && entry.crew?.coach?.user_id) ? "Yes" : 'No',
				first_name: (entry && entry.crew.coach && entry.crew.coach.first_name) ? entry.crew.coach.first_name : '',
				last_name: (entry && entry.crew.coach && entry.crew.coach.last_name) ? entry.crew.coach.last_name : '',
				email_address: (entry && entry.crew.coach && entry.crew.coach.email) ? entry.crew.coach.email : '',
				mobile_number: (entry && entry.crew.coach && entry.crew.coach.mobile) ? entry.crew.coach.mobile : '',
				initials: {
					0: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[0]) ? entry.crew.coach.initial_parts?.[0] : '',
					1: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[1]) ? entry.crew.coach.initial_parts?.[1] : '',
					2: (entry && entry.crew.coach && entry.crew.coach.initial_parts?.[2]) ? entry.crew.coach.initial_parts?.[2] : '',
				},
			},
			// is_athlete: (hasCurrentUserAthlete) ? "Yes" : 'No',
			supplementary_info: (entry && entry.supplementary_info) ? entry.supplementary_info : '',
			arrival_date: (entry && entry.arrival_date) ? entry.arrival_date : '',
			is_athlete: (entry && entry.is_athlete_id) ? "Yes" : 'No',
			is_athlete_id: (entry && entry.is_athlete_id) ? entry.is_athlete_id : '',
			multiple_crews: (entry && entry.crew_rank) ? 'Yes':'No',
		};

		const saveValidationSchema = Yup.object().shape({
			athletes: Yup.array()
				.of(
					Yup.object().shape({
						position: Yup.string().required('Required'),
						initials: Yup.object().shape({
							0: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
							1: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
							2: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
						}),
						club: Yup.string().required('Required'),
						first_name: Yup.string().required('Required'),
						last_name: Yup.string().required('Required'),
						dob: Yup.string()
							.required('Required')
							.test(
								'is-date',
								'Date format should be dd/mm/yyyy and a valid date',
								value => {
									return moment(value, 'DD/MM/YYYY', true).isValid();
								}
							)
							.test(
								'age-validation',
								'Athlete does not meet the age requirement for this event.',
								value => {
									const dob = moment(value, 'DD/MM/YYYY');
									const currentYear = moment().year();

									const ageDate = (event.age_on_date) ? event.age_on_date.replace(/(.{2})/g, '$1/').slice(0, -1) : null;
									const min_age = (event.min_age) ? parseInt(event.min_age) : null;
									const max_age = (event.max_age) ? parseInt(event.max_age) : null;

									const ageOnDate = moment(`${ageDate}/${currentYear}`, 'DD/MM/YYYY'); // Adjust for your event.age_on_date
									const age = ageOnDate.diff(dob, 'years');

									// Validate against min_age and max_age
									if (min_age !== null && age < min_age) {
										console.log('TOO YOUNG');
										return false;
									}
									if (max_age !== null && age > max_age) {
										console.log('TOO OLD');
										return false;
									}

									console.log('ALL GOOD!');
									return true;
								}
							),
						previously_rowed: Yup.string().required('Required'),
					})
				)
				.required('Must have athletes'),
			steer: Yup.string().when('$eventSteer', {
				is: 1,
				then: Yup.string().required('Required'),
				otherwise: Yup.string().notRequired(),
			}),
		});

		const cartValidationSchema = Yup.object().shape({
			athletes: Yup.array()
				.of(
					Yup.object().shape({
						position: Yup.string().required('Required'),
						club: Yup.string().required('Required'),
						first_name: Yup.string().required('Required'),
						last_name: Yup.string().required('Required'),
						initials: Yup.object().shape({
							0: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
							1: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
							2: Yup.string()
								.transform((value) => (value === '' ? undefined : value))
								.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
								.nullable(),
						}),
						dob: Yup.string()
							.required('Required')
							.test(
								'is-date',
								'Date format should be dd/mm/yyyy and a valid date',
								value => {
									return moment(value, 'DD/MM/YYYY', true).isValid();
								}
							)
							.test(
								'age-validation',
								'Athlete does not meet the age requirement',
								value => {
									const dob = moment(value, 'DD/MM/YYYY');
									const currentYear = moment().year();

									const ageDate = (event.age_on_date) ? event.age_on_date.replace(/(.{2})/g, '$1/').slice(0, -1):null;
									const min_age = (event.min_age) ? parseInt(event.min_age) : null;
									const max_age = (event.max_age) ? parseInt(event.max_age) : null;

									const ageOnDate = moment(`${ageDate}/${currentYear}`, 'DD/MM/YYYY'); // Adjust for your event.age_on_date
									const age = ageOnDate.diff(dob, 'years');

									// Validate against min_age and max_age
									if (min_age !== null && age < min_age) {
										return false;
									}
									if (max_age !== null && age > max_age) {
										return false;
									}
									
									return true;
								}
							),
						previously_rowed: Yup.string().required('Required'),
					})
				)
				.required('Must have athletes'),
			steer: Yup.string().when('$eventSteer', {
				is: true,
				then: () => Yup.string().required('Required'),
				otherwise: () => Yup.string().notRequired(),
			}),
			crew: Yup.object().shape({
				crew_ranking_index: Yup.string()
					.when('$shouldValidateCrewRanking', {
						is: true,
						// minimum of 2, max of 4 characters
						then: () => Yup.string()
							.required('Required')
							.matches(/^\d{2,4}$/, 'CRI Should be between 2 and 4 digits'),
						otherwise: () => Yup.string().notRequired(),
					}),
				crew_rank: Yup.string().when('multiple_crews', {
					is: 'Yes',
					then: () => Yup.string().required('Required'),
					otherwise: () => Yup.string().notRequired(),
				}),
				emergency_contact_name: Yup.string().required('Required'),
				emergency_contact: Yup.string().required('Required').matches(
					/^\+\d{10,15}$/,
					'Phone number must start with a "+" followed by the country code and number'
				),
				club_id_1: Yup.string()
					.when('$shouldValidateClubId1', {
						is: true,
						then: () => Yup.string().required('Required'),
						otherwise: () => Yup.string().notRequired(),
					}),
				club_id_2: Yup.string()
					.when('$shouldValidateClubId2', {
						is: true,
						then: () => Yup.string().required('Required'),
						otherwise: () => Yup.string().notRequired(),
					}),
			}),
			coach: Yup.object().shape({
				is_coach: Yup.string().required('Required'),
				first_name: Yup.string().when('is_coach', {
					is: 'No',
					then: () => Yup.string().required('Required'),
					otherwise: () => Yup.string().notRequired(),
				}),
				last_name: Yup.string().when('is_coach', {
					is: 'No',
					then: () => Yup.string().required('Required'),
					otherwise: () => Yup.string().notRequired(),
				}),
				initials: Yup.object().shape({
					0: Yup.string()
						.transform((value) => (value === '' ? undefined : value))
						.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
						.nullable(),
					1: Yup.string()
						.transform((value) => (value === '' ? undefined : value))
						.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
						.nullable(),
					2: Yup.string()
						.transform((value) => (value === '' ? undefined : value))
						.matches(/^[A-Z]-[A-Z]$|^[A-Z]$/i, 'format must be a single letter or two letters separated by a hyphen')
						.nullable(),
				}),
				email_address: Yup.string().when('is_coach', {
					is: 'No',
					then: () => Yup.string().required('Required'),
					otherwise: () => Yup.string().notRequired(),
				}),
				mobile_number: Yup.string().when('is_coach', {
					is: 'No',
					then: () => Yup.string().required('Required').matches(
						/^\+\d{10,15}$/,
						'Phone number must start with a "+" followed by the country code and number'
					),
					otherwise: () => Yup.string().notRequired(),
				}),
			}),
			arrival_date: Yup.string()
				.when('$shouldValidateArrivalDate', {
					is: true,
					then: () => Yup.string().required('Required').test('len', 'Date format should be dd/mm/yyyy', val => val && val.toString().length === 10),
					otherwise: () => Yup.string().notRequired(),
				}),
			multiple_crews: Yup.string().notRequired(),
			supplementary_info: Yup.string().required('Required'),
		});

		const validateForm = (values, action) => {
			const schema = (action === 'cart') ? cartValidationSchema : saveValidationSchema;
			const context = {
				shouldValidateArrivalDate: (action === 'cart' && getClubData(values.athletes).some(club => club.country_code !== 'GBR')) ? true : false,
				shouldValidateCrewRanking: (action === 'cart' && getClubData(values.athletes).filter(club => club.country_code === 'GBR').length === event.event_types.athlete_qty) ? true : false,
				shouldValidateClubId1: (action === 'cart' && getClubData(values.athletes, true).length > 2) ? true : false,
				shouldValidateClubId2: (action === 'cart' && getClubData(values.athletes, true).length > 2) ? true : false,
				eventSteer: (event.event_types.steer === 1) ? true:false
			};

			try {
				schema.validateSync(values, { abortEarly: false, context: context });
			} catch (error) {
				return yupToFormErrors(error);
			}

			// Return an empty object if there are no errors
			return {};
		};

		const customFilter = (option, searchText) => {
			// Use the label for matching, but you can adjust to match with other attributes
			const [matched] = fuzzySearch(searchText, option.label);
			return matched;
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={cartValidationSchema}
						onSubmit={handleSave}
						>
						{({ values, setFieldValue, isValid, errors, setErrors, touched, setTouched, validateField, isSubmitting, ...formikBag }) => {
							formikRef.current = formikBag;
							formikRef.values = values;
							console.log(values);
							// unique getClubData(values.athletes)
							const selectedClubIdData = getClubData(values.athletes, true)
								.map(club => {
									return {
										value: club.id,
										label: club.name,
										isDisabled: (values.crew.club_id_1 === club.id || values.crew.club_id_2 === club.id) ? true : false
									};
								}
							);

							const uneditableStatuses = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
							const isEditable = (uneditableStatuses.includes(entry.status)) ? true : false;

							return (
								<Form>
									{(isEditable) && (
										<div className="alert alert-warning">This entry is no longer editable</div>
									)}
									<fieldset disabled={isEditable}>
										<div>
											<table className="table table-striped">
												<thead>
													<tr>
														<th width="90">Position</th>
														{(event.event_types.steer === 1) && (
															<th>
																Steer
															</th>
														)}
														<th width="300">Club</th>
														<th>First Name</th>
														<th>Middle Initials (1 per field)</th>
														<th>Last Name</th>
														<th>Date of Birth (dd/mm/yyyy)</th>
														<th>Has this athlete competed at HRR before, excluding Qualifiers?</th>
													</tr>
												</thead>
												<tbody>
													{Array.from({ length: event.event_types.athlete_qty }, (_, index) =>
														<tr key={index}>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].position`} disabled />
																<ErrorMessage name={`athletes[${index}].position`} component="div" className="field-error" />
															</td>
															{(event.event_types.steer === 1) && (
																<td>
																	<Field type="radio" name="steer" value={index} checked={values.steer === index.toString()} 
																		// if this is an error field
																		className={(errors.steer && touched.steer) ? 'is-invalid' : ''}
																	/>
																	{(touched.steer && touched.steer !== true) && (
																		<div className="field-error">{touched.steer}</div>
																	)}
																	{/* <ErrorMessage name="steer" component="div" className="field-error" /> */}
																</td>
															)}
															<td>
																<Field
																	key={selectKey}
																	isDisabled={(event.composite === 0 && index !== 0 || isEditable) ? true:false}
																	component={SelectField}
																	name={`athletes[${index}].club`}
																	className="form-control"
																	options={clubs}
																	filterOption={customFilter}
																	onChange={(value) => checkClubValue(value, index, values, setFieldValue)}
																	noOptionsMessage={({ inputValue }) => {
																		if (inputValue) {
																			return (
																				<div
																					style={{ cursor: "pointer" }}
																					onClick={() => setShowModal(true)}
																				>
																					Create new club "{inputValue}"
																				</div>
																			);
																		} else {
																			return "No options";
																		}
																}} />
																<ErrorMessage name={`athletes[${index}].club`} component="div" className="field-error" />
															</td>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].first_name`}  />
																<ErrorMessage name={`athletes[${index}].first_name`} component="div" className="field-error" />
															</td>
															<td>
																<div className="d-flex">
																	<div className="px-1">
																		<Field type="text" className="form-control" name={`athletes[${index}].initials[0]`} />
																		<ErrorMessage name={`athletes[${index}].initials[0]`} component="div" className="field-error" />
																	</div>
																	{(values.athletes?.[index].initials?.[0]?.length > 0) &&
																		<div className="px-1">
																			<Field type="text" className="form-control" name={`athletes[${index}].initials[1]`} />
																			<ErrorMessage name={`athletes[${index}].initials[1]`} component="div" className="field-error" />
																		</div>
																	}
																	{(values.athletes?.[index]?.initials?.[1]?.length > 0) &&
																		<div className="px-1">
																			<Field type="text" className="form-control" name={`athletes[${index}].initials[2]`} />
																			<ErrorMessage name={`athletes[${index}].initials[2]`} component="div" className="field-error" />
																		</div>
																	}
																</div>
															</td>
															<td>
																<Field type="text" className="form-control" name={`athletes[${index}].last_name`} />
																<ErrorMessage name={`athletes[${index}].last_name`} component="div" className="field-error" />
															</td>
															<td>
																<Field type="text" className="form-control datepicker-dob" name={`athletes[${index}].dob`} onChange={input => handleDateChange(input, `athletes[${index}].dob`, setFieldValue)} onBlur={input => handleDateBlur(input, `athletes[${index}].dob`, setFieldValue)} />
																<ErrorMessage name={`athletes[${index}].dob`} component="div" className="field-error" />
															</td>
															<td>
																<Field component={SelectField} name={`athletes[${index}].previously_rowed`} className="form-control" options={[{ value: 1, label: "Yes" }, { value: 0, label: "No"}]} isDisabled={isEditable} />
																<ErrorMessage name={`athletes[${index}].previously_rowed`} component="div" className="field-error" />
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>

										{(getClubData(values.athletes, true).length > 2) && (
											<div className="form-group">
												<h3>Names in Programme</h3>
												<p>Only two clubs shall be displayed in the Daily Racing Programme, any others will be used by the Commentators.</p>
												<div className="row">
													<div className="col-12 col-md-6 form-field">
														<label htmlFor="username">Club 1</label>
														<Field
															isDisabled={isEditable}
															component={SelectField}
															name="crew[club_id_1]"
															className="form-control"
															options={selectedClubIdData}
															// onChange={value => setSelectedClub1(value)}
															// value={selectedClub1}
														/>
														{(touched.crew?.club_id_1 && touched.crew?.club_id_1 !== true) && (
															<div className="field-error">{touched.crew?.club_id_1}</div>
														)}
														<ErrorMessage name="crew[club_id_1]" component="div" className="field-error" />
													</div>

													<div className="col-12 col-md-6 form-field">
														<label htmlFor="password">Club 2</label>
														<Field
															isDisabled={isEditable}
															component={SelectField}
															name="crew[club_id_2]"
															className="form-control"
															options={selectedClubIdData}
															// onChange={value => setSelectedClub2(value)}
															// value={selectedClub2}
														/>
														{(touched.crew?.club_id_2 && touched.crew?.club_id_2 !== true) && (
															<div className="field-error">{touched.crew?.club_id_2}</div>
														)}
														<ErrorMessage name="crew[club_id_2]" component="div" className="field-error" />
													</div>
												</div>
											</div>
										)}

										{(event.event_types.athlete_qty > 2) && (
											<div className="form-group">
												<h3>Will you be entering multiple crews from one club for the same event?</h3>
												<label htmlFor="multiple_crews"><Field type="radio" name="multiple_crews" value="Yes" checked={values.multiple_crews === 'Yes'}/> Yes</label>
												<label htmlFor="multiple_crews"><Field type="radio" name="multiple_crews" value="No" checked={values.multiple_crews === 'No' || !values.multiple_crews } /> No</label>
											</div>
										)}

										{(values.multiple_crews === 'Yes') &&
											<div className="form-group">
												<p>Select this crew's rank</p>
												<Field component={SelectField} name="crew[crew_rank]" className="form-control" options={[
													{label: "A", value: "A"},
													{label: "B", value: "B"},
													{label: "C", value: "C"},
													{label: "D", value: "D"},
													{label: "E", value: "E"},
													{label: "F", value: "F"}
												]} />
												{(touched.crew?.crew_rank && touched.crew?.crew_rank !== true) && (
													<div className="field-error">{touched.crew?.crew_rank}</div>
												)}
												<ErrorMessage name="crew[crew_rank]" component="div" className="field-error" />
											</div>
										}

										{(values.athletes.some(athlete => athlete.last_name.toLowerCase() === getUser.user.last_name.toLowerCase())) &&
											<div className="form-group">
												<h3>Are you one of the athletes in this crew?</h3>
												<label htmlFor="is_athlete"><Field type="radio" name="is_athlete" value="Yes" checked={values.is_athlete === 'Yes'}/> Yes</label>
												<label htmlFor="is_athlete"><Field type="radio" name="is_athlete" value="No" checked={values.is_athlete === 'No' || !values.is_athlete} /> No</label>
											</div>
										}

										{(values.athletes.some(athlete => athlete.last_name.toLowerCase() === getUser.user.last_name.toLowerCase()) && values.is_athlete === 'Yes') &&
											<div className="form-group">
												<label htmlFor="username">Which athlete are you?</label>
												<Field
													isDisabled={isEditable}
													component={SelectField}
													name="is_athlete_id"
													className="form-control"
													options={
														values.athletes
															.map((athlete, index) => {
																if (athlete.last_name.toLowerCase() === getUser.user.last_name.toLowerCase()) {
																	return {
																		value: index,
																		label: `${athlete.first_name} ${athlete.last_name}`
																	};
																}
																return null; // Return null for non-matching athletes
															})
															.filter(Boolean) // This will remove the null entries
													}
												/>
												<ErrorMessage name="is_athlete_id" component="div" className="field-error" />
											</div>
										}

										<div className="form-group">
											<h3>Are you the coach of this crew?</h3>
											<label htmlFor="is_coach"><Field type="radio" name="coach[is_coach]" value="Yes" checked={values.coach.is_coach === 'Yes'} /> Yes</label>
											<label htmlFor="is_coach"><Field type="radio" name="coach[is_coach]" value="No" checked={values.coach.is_coach === 'No' || !values.coach.is_coach} /> No</label>
										</div>

										{(values.coach.is_coach === 'No') &&
											<div className="form-group">
												<div className="row">
													<div className="col-12 form-field">
														<h4>Coach Details</h4>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-12 col-md-4 form-field">
														<label htmlFor="coach_first_name">First Name<span className="required">*</span></label>
														<Field type="text" name="coach[first_name]" className="form-control" />
														<ErrorMessage name="coach[first_name]" component="div" className="field-error" />
													</div>

													<div className="col-12 col-md-4 form-field">
														<div className="row">
															<div className="col-12">
																<label htmlFor="coach_middle_initials">Middle Initials</label>
															</div>
															<div className="col px-2">
																<Field type="text" className="form-control" name="coach[initials][0]" max="1"/>
															</div>
															<div className="col px-2">
																<Field type="text" className="form-control" name="coach[initials][1]" max="1"/>
															</div>
															<div className="col px-2">
																<Field type="text" className="form-control" name="coach[initials][2]" max="1"/>
															</div>
														</div>
													</div>

													<div className="col-12 col-md-4 form-field">
														<label htmlFor="coach_last_name">Last Name<span className="required">*</span></label>
														<Field type="text" name="coach[last_name]" className="form-control" />
														<ErrorMessage name="coach[last_name]" component="div" className="field-error" />
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-12 col-md-6 form-field">
														<label htmlFor="coach_email_address">Email<span className="required">*</span></label>
														<Field type="email" name="coach[email_address]" className="form-control" />
														<ErrorMessage name="coach[email_address]" component="div" className="field-error" />
													</div>

													<div className="col-12 col-md-6 form-field">
														<label htmlFor="coach_mobile_number">Mobile Number (including country code e.g +44)<span className="required">*</span></label>
														<Field type="text" name="coach[mobile_number]" className="form-control" />
														<ErrorMessage name="coach[mobile_number]" component="div" className="field-error" />
													</div>
												</div>
											</div>
										}

										<div className="row">
											{(getClubData(values.athletes).filter(club => club.country_code === 'GBR').length === event.event_types.athlete_qty) &&
												<div className="col-12 col-md-6 form-group">
													<h3>British Rowing Ranking Index</h3>
													<label htmlFor="crew_ranking_index">For a crew from a UK club, or that has raced at BR-affiliated events, please provide the current Crew Ranking Index (CRI) for that crew.</label>
													<Field type="number" name="crew[crew_ranking_index]" className="form-control" />
													{(touched.crew?.crew_ranking_index && touched.crew?.crew_ranking_index !== true) && (
														<div className="field-error">{touched.crew?.crew_ranking_index}</div>
													)}
													{/* <ErrorMessage name="crew[crew_ranking_index]" component="div" className="field-error" /> */}
												</div>
											}

											<div className="col-12 col-md-6 form-group">
												<h3>Supplementary information</h3>
												<p>Please use this box to provide supplementary information, as required by Clause 5 of the Entry Notes.</p>
												<p>Please provide at least the five best results from the last 12 months.<span className="required">*</span></p>
												<Field component="textarea" className="form-control" name="supplementary_info"/>
												<ErrorMessage name="supplementary_info" component="div" className="field-error" />
											</div>

											{
												(getClubData(values.athletes).some(club => club.country_code !== 'GBR')) &&
													<div className="col-12 col-md-6 form-group arrival-date">
														<h3>Anticipated arrival date</h3>
														<div className="form-field">
															<label htmlFor="arrival_date">Anticipated arrival date (dd/mm/yyyy)<span className="required">*</span></label>
															<Field type="text" className="form-control datepicker" name="arrival_date" onChange={input => handleDateChange(input, "arrival_date", setFieldValue, "future")} onBlur={input => handleDateBlur(input, `arrival_date`, setFieldValue, "future")} />
															{(touched.arrival_date && touched.arrival_date !== true) && (
																<div className="field-error">{touched.arrival_date}</div>
															)}
															{/* <ErrorMessage name="arrival_date" component="div" className="field-error" /> */}
														</div>
													</div>
											}

											<div className="col-12 col-md-6 form-group">
												<h3>Crew Emergency Contact</h3>
												<p>Please provide details of someone we can contact in an emergency both before and during the Regatta.</p>

												<div className="form-field">
													<label htmlFor="emergency_contact">Emergency Contact Name<span className="required">*</span></label>
													<Field type="text" className="form-control" name="crew[emergency_contact_name]" />
													<ErrorMessage name="crew[emergency_contact_name]" component="div" className="field-error" />
												</div>
												<div className="form-field">
													<label htmlFor="emergency_contact">Emergency Contact Number for Crew (including country code e.g +44)<span className="required">*</span></label>
													<Field type="text" className="form-control" name="crew[emergency_contact]" />
													<ErrorMessage name="crew[emergency_contact]" component="div" className="field-error" />
												</div>
											</div>
										</div>

										{(!isEditable) && (
											<div className="form-group form-buttons">
												<button
													className="btn btn-primary btn-block"
													type="button"
													onClick={async () => {
														const validationAttempt = await validateForm(values, 'save');
														setTouched(validationAttempt);
														console.log('VLAD', validationAttempt);
														// if validationAttempt is empty, then there are no errors
														if (Object.keys(validationAttempt).length === 0) {
															handleSaveAct(values);
														} else {
															window.scrollTo(0, 0);
															toast.error('Please fix the form errors before saving');
														}
													}}
													disabled={(isSubmitting)}
												>
													{isSubmitting && (
														<FontAwesomeIcon icon={faSpinner} spin />
													)}
													<span>Save</span>
												</button>

												<button
													className="btn btn-secondary btn-block ms-2"
													type="button"
													onClick={async () => {
														const validationAttempt = await validateForm(values, 'cart');
														setTouched(validationAttempt);
														// setErrors(validationAttempt);
														// setTouched("crew[crew_ranking_index]")

														console.log('VLAD', validationAttempt);

														if (Object.keys(validationAttempt).length === 0) {
															handleSave(values);
														} else {
															window.scrollTo(0, 0);
															toast.error('Please fix the form errors before saving');
														}
													}}
													disabled={(isSubmitting)}
												>
													{isSubmitting && (
														<FontAwesomeIcon icon={faSpinner} spin />
													)}
													<span>Save and Add to Cart</span>
												</button>
											</div>
										)}
									</fieldset>
								</Form>
							)}
						}
					</Formik>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Make an Entry</h1>
					{(entry) &&
						<div>
							<p>Event: {event.name}</p>
							{<EventForm />}
						</div>
					}

					{showModal && (
						<div className="modal show" tabIndex="-1" style={{display: 'block'}}>
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Create New Club</h5>
										<button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>{<FontAwesomeIcon icon={faTimes} />}</button>
									</div>
									<div className="modal-body">
										{<NewClub updateClubs={fetchClubs} close={setShowModal}/>}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default withRouter(AddEntry);
