import React, { useState, createRef } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from '../../common/with-router';
import { resetUserPassword } from '../../api/user';
import * as Yup from "yup";

const ResetPassword = () => {
	const [message, setMessage] = useState(null);
	// get the token from the url
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');


	const formsubmit = async (values) => {
		try {
			const loginData = await resetUserPassword(token, values);

			if (loginData.data.status === 'success') {
				setMessage('Your password was successfully changed. You will be redirected to login in 5 seconds...');
				// redirect after 5 seconds
				setTimeout(() => {
					window.location.href = '/login';
				}, 5000);
				window.grecaptcha.reset();
			} else {
				setMessage('An error occured. Please try again.');
				window.grecaptcha.reset();
			}
		} catch (error) {
			if (error.response.data.message) {
				// reset the recaptcha
				window.grecaptcha.reset();
				setMessage(error.response.data.message);
			}
		}
	}

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-{}\[\]\\:;"'<>,.?~])[a-zA-Z0-9!@#$%^&*()_+\-{}\[\]\\:;"'<>,.?~]+$/,
				'Password must contain at least one number and one symbol'
			),
		password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-md-8">
					{(message) ? (
							<div className="alert alert-info" role="alert">{message}</div>
						):null
					}
					<h1>Reset Password</h1>
					<Formik
					initialValues={{
						password: '',
					}}
					validationSchema={validationSchema}
					onSubmit={formsubmit}>
						{({ handleSubmit, isSubmitting, setFieldValue }) => (
							<Form>
								<div className="mb-2">
									<label htmlFor="password">Password</label>
									<Field type="password" className="form-control" id="password" name="password" />
									<ErrorMessage name="password" component="div" className="field-error" />
								</div>
								<div className="mb-2">
									<label htmlFor="password_confirm">Password Confirm</label>
									<Field type="password" className="form-control" id="password_confirm" name="password_confirm" />
									<ErrorMessage name="password_confirm" component="div" className="field-error" />
								</div>
								<button type="submit" className="btn btn-primary" disabled={(isSubmitting) && true}>Reset Password</button>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ResetPassword);
