import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { saveCoxWeight } from '../../../api/athletes';

const BoatTentWeighingSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');
	// get the entry id from the url
	const entryId = props.router.params.id;

	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState({});
	const [message, setMessage] = useState("");

	useEffect(() => {
		fetch(`${API_URL}/admin/weighinglist/${entryId}`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
			.then((response) => response.json())
			.then((data) => {
				setEntry(data);
				// dispatch({ type: 'EVENTS', payload: data });
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}, []);

	const EventForm = () => {
		// if entry is an object
		const initialValues = {
			id: entry.data?.athletes?.id,
			weight: (entry.data?.athletes?.weight) ? entry.data?.athletes?.weight:0,
		};

		const validationSchema = Yup.object().shape({
			weight: Yup.number().required('Weight is required'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			// setLoading(true);
			const toastId = toast("Saving...", { autoClose: false });

			setSubmitting(true);

			try {
				const saveWeight = await saveCoxWeight(values.id, values);
				if (saveWeight.data.status === 'success') {
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Athlete weight saved", autoClose: 5000 });
					return;
				}
			} catch (error) {
				console.log(error);
			}
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<div>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{({ values }) => (
								<Form>
									<table className="table table-striped align-middle">
										<tbody>
											<tr>
												<td width="150px">First Name:</td>
												<td>
													{entry.data?.athletes?.first_name}
												</td>
											</tr>
											<tr>
												<td>
													Initials:
												</td>
												<td>
													<div className="d-flex">
														{entry.data?.athletes?.initials}
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Last Name:
												</td>
												<td>
													{entry.data?.athletes?.last_name}
												</td>
											</tr>
											<tr>
												<td>Weight (KG):</td>
												<td>
													<Field type="text" className="form-control" name={`weight`} />
												</td>
											</tr>
										</tbody>
									</table>

									<h3>Deadweights</h3>
									<table className="table table-striped align-middle">
										<thead>
											<tr>
												<th>Event</th>
												<th>Deadweight (KG)</th>
											</tr>
										</thead>
										<tbody>
											{Object.values(entry.events).map((itm, ind) => {
												const calcDeadweight = (itm.min_cox_weight > 0) ? (itm.min_cox_weight - values.weight):0;
												return (
													<tr key={ind}>
														<td>
															{itm.name}
														</td>
														<td>
															{(calcDeadweight < 0) ? 0 : calcDeadweight.toFixed(2)}
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>

									<div className="form-group form-buttons">
										<button
											className="btn btn-primary btn-block"
											type="submit"
											disabled={(loading)}
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Save</span>
										</button>
									</div>

									{message && (
										<div className="form-group">
											<div className="alert alert-danger" role="alert">
												{message}
											</div>
										</div>
									)}
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Cox Weigh-in</h1>
					<div>
						{(entry) && <EventForm />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(BoatTentWeighingSingle);
