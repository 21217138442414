import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getCountries } from '../../../api/misc';

const NgbSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [ngb, setNgb] = useState(null);
	const [countries, setCountries] = useState(null);

	const getCountriesDropdown = async () => {
		try {
			const getCountyData = await getCountries();
			console.log('COIU', getCountyData);
			if (getCountyData.data.status === "success") {
				

				setCountries(getCountyData.data.countries);
				// setCountries(getCountyData.data.countries);
			}
		} catch (error) {
			
		}
		// return fetch(`${API_URL}/countries`, {
		// 	headers: {
		// 		// auth url
		// 		'Content-Type': 'application/json',
		// 		'Authorization': `Bearer ${token}`,
		// 	}
		// })
		// 	.then((response) => response.json())
		// 	.then((data) => {
		// 		// loop and set to label and value

		// 		data.forEach((item) => {
		// 			item.label = item.country;
		// 			item.value = item.code;
		// 		});

		// 		setCountries(data);
		// 	})
		// 	.catch((error) => {
		// 		console.error('Error:', error);
		// 	});
	}

	const getInitialData = (id) => {
		getCountriesDropdown()
			.finally(() => {
				fetch(`${API_URL}/admin/ngbs/${id}`, {
					headers: {
						// auth url
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					}
				})
					.then((response) => response.json())
					.then((data) => {
						setNgb(data);
						setLoading(false);
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			});
	}

	useEffect(() => {
		// getEventTypes().finally(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		} else {
			getCountriesDropdown().finally(() => {
				setLoading(false);
			});
		}
		// });
	}, []);

	const ngbForm = () => {
		// get object from eventTypeList that matches the id of event.event_type

		// if entry is an object
		const initialValues = {
			name: (ngb && ngb.name) ? ngb.name : '',
			country_code: (ngb && ngb.country_code) ? ngb.country_code : '',
			email: (ngb && ngb.email) ? ngb.email : ''
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required('Required'),
			email: Yup.string().required('Required'),
			country_code: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/ngbs/${data.id}` : `${API_URL}/admin/ngbs`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT' : 'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						setLoading(false);
						setSubmitting(false);
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "NGB saved successfully!", autoClose: 5000 });
	
						if (res.id && !data.id) {
							window.location.href = `/admin/ngbs`;
						}
					}
				})
				.catch((error) => {
					console.error(error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				});
		};

		const getCountryOptions = () => {
			console.log('countries', countries);
			if (countries) {
				return countries.map((item) => {
					return {
						label: item.country,
						value: item.code
					}
				});
			}
		}

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							// const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type) : null;

							return (
								<Form>
									<div className="form-group-flex">
										<label htmlFor="username">NGB Name:</label>
										<Field type="text" className="form-control" name="name" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">NGB Email:</label>
										<Field type="text" name="email" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Country</label>
										<Field component={SelectField} name="country_code" className="form-control" options={getCountryOptions()} />
									</div>

									<hr />

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/ngbs"><FontAwesomeIcon icon={faArrowLeft} /> Back to NGBs</Link></div>
				<div className="col-12 col-md-6">
					{(ngb) ? <h1>Edit NGB</h1> : <h1>Add NGB</h1>}
				</div>

				<div className="col-12 form-group">
					{ngbForm()}
				</div>
			</div>
		</div>
	);
}

export default withRouter(NgbSingle);
