import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
// import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getQualifyingGroups, saveQualifyingGroupResults, getQualifyingGroupResults, resetQualifyingGroupResults } from '../../../api/qualifying';

const QualifiersTimetable = (props) => {
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState(null);
	const [results, setResults] = useState({});
	// const [needsReset, setNeedsReset] = useState(false); // State to track breaks

	// get user
	// const currentUser = useSelector((state) => state.user);

	// const [table, setTable] = useState({}); // State to track breaks

	const getInitialData = async () => {
		try {
			const getQualifyingGroupsRes = await getQualifyingGroups(0);

			if (getQualifyingGroupsRes.data.status === "success") {
				setGroups(getQualifyingGroupsRes.data.events);
				setLoading(false);
			}
			
			const getQualifyingGroupsResultsRes = await getQualifyingGroupResults();

			if (getQualifyingGroupsResultsRes.data.status === "success") {
				// console.log('getQualifyingGroupsResultsRes', getQualifyingGroupsResultsRes.data)
				setResults(getQualifyingGroupsResultsRes.data.results);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, []);

	if (loading) {
		return <Loading />;
	}
	
	const submitTimetable = async (values, { setSubmitting }) => {
		setSubmitting(true);
		const toastId = toast("Saving Result...", { autoClose: false });

		try {
			const saveResultRes = await saveQualifyingGroupResults(values);
			if (saveResultRes.data.status === "success") {
				getInitialData();
				setSubmitting(false);
				toast.update(toastId, { render: "Results Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving Result.", type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	// const formatTime = (value) => {
	// 	if (!value) return '';

	// 	// Remove all non-digits and limit string to 6 characters (HHMMS)
	// 	const digits = value.replace(/\D/g, '').substring(0, 5);

	// 	// Split the string into HH, MM, and SS
	// 	const parts = [];
	// 	for (let i = 0; i < digits.length; i += 2) {
	// 		parts.push(digits.substring(i, i + 2));
	// 	}

	// 	return parts.join(':');
	// };

	const resetTable = async () => {
		const confirmReset = window.confirm("Are you sure you want to reset the results?");
			if (confirmReset) {
			const toastId = toast("Resetting Qualifier Results...", { autoClose: false });
			try {
				const resetTableRes = await resetQualifyingGroupResults();
				if (resetTableRes.data.status === "success") {
					getInitialData();
					toast.update(toastId, { render: "Results Reset!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
				}
			} catch (error) {
				console.log('error', error);
			}
		}
	}

	const QualifyingGroupForm = () => {
		let rowId = 1;

		const initialValues = {
			crews: groups.reduce((crewAcc, group) => {
				group.forEach((crew, group_num) => {
					crewAcc[rowId] = {
						type: (crew.break === true || crew.break === false) ? 'BREAK':'CREW',
						crew_id: (crew.crew?.crew?.id) ? crew.crew?.crew?.id:0, // This is actually the entry ID
						start_time: (results[crew.crew?.crew?.id]?.start_time) ? results[crew.crew?.crew?.id].start_time:'00:00:0',
						// finish_time: (results[crew.crew?.crew?.id]?.finish_time) ? results[crew.crew?.crew?.id].finish_time:'00:00:0',
						// notes: (results[rowId]?.notes) ? results[rowId].notes:'',
						// break_enabled: (results[rowId]?.break_enabled) ? "1":"0",
						group_number: group_num,
					};

					rowId++;
				});

				return crewAcc;
			}, {})
		};
		
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={submitTimetable}
			>
				{({ values, errors, touched, setFieldValue, isValid }) => {
					rowId = 0;
					return (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Crew #</th>
										<th>Crew</th>
										<th>Group #</th>
										<th>Event</th>
										<th>ST</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{groups.map((group, index) => (
										<React.Fragment key={index}>
											{group.map((crew, i) => {
												rowId++;
												return (
													<tr key={`tr${i}`}>
														<td>{(crew.crew?.crew?.crew_number) ? crew.crew?.crew?.crew_number : 'N/A'}</td>
														<td>{crew.crew?.crew?.crew_name}</td>
														<td>{crew.group_number}</td>
														<td>{crew.crew.event.name}</td>
														<td width="110px">
															{values.crews[rowId].start_time}
															{/* <Field
																name={`crews[${rowId}].start_time`}
																className="form-control"
																onClick={(e) => e.target.select()}
																onChange={e => handleChange(e, setFieldValue, 'start_time', crew.crew.crew.id, crew.group_number, index, values)}
															/> */}
														</td>
														<td>
															<Field
																name={`crews[${rowId}].finish_time`}
																className="form-control"
																type="hidden"
															// onClick={(e) => e.target.select()}

															// onChange={(e) => handleChange(e, setFieldValue, 'finish_time', crew.crew.crew.id, crew.group_number)}
															/>
															<Field
																// component="textarea"
																name={`crews[${rowId}].notes`}
																className="form-control"
																type="hidden"
															/>
															<Field type="hidden" name={`crews[${rowId}].crew_id`} value={crew.crew.crew.id} />
															<Field type="hidden" name={`crews[${rowId}].type`} value="RACE" />
															{/* <button type="button" onClick={() => saveRowResult(values.crew[crew.id])} className="btn btn-primary">Save</button> */}
														</td>
													</tr>
												)
											})}
										</React.Fragment>
									))}
								</tbody>
							</table>

							{/* <div className="floating-buttons">
								<button type="submit" className="btn btn-primary btn-lg">Save</button>
							</div> */}
						</Form>
					)
				}}
			</Formik>
		)
	};

	return (
		<div className="container-fluid">
			<div className="row align-items-center">
				<div className="col-12">
					<h1>Qualifiers Timetable</h1>
				</div>
				{/* {(results && Object.entries(results).length > 0 && currentUser.user.role.includes('admin')) &&
					<div className="col-12">
						<div className="alert alert-info">
							If you have changed the event max crew number or changed any crews since generating this timetable, please reset the timetable or the timetable may be incorrect.<br/>
							<button type="button" className="btn btn-danger me-2 mt-2" onClick={() => resetTable()}>Reset Table Results</button>
						</div>
					</div>
				} */}

				<div className="col-12 mt-3 form-group">
					{(groups && groups.length > 0) ? <QualifyingGroupForm /> : <div className="alert alert-danger">No Races Found</div>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(QualifiersTimetable);
