import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNgbs } from '../../../api/ngb';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const NgbsList = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [ngbs, setNgbs] = useState({});
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('name');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getNgbRes = await getNgbs(page, sortDirection, sortedColumn);
			if (getNgbRes.data.status === 'success') {
				console.log('NGBRES', getNgbRes.data.ngbs);
				setNgbs(getNgbRes.data.ngbs);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
		console.log('NGBS', ngbs)

	}, [page, sortDirection, sortedColumn]);

	const handleSearch = (newSearchTerm) => {
		setSearchTerm(newSearchTerm);
		// Now you can use `searchTerm` to filter your table data, make API calls, etc.
	};



	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			try {
				// Make your fetch/axios call to delete the item from backend
				fetch(`${API_URL}/admin/ngbs/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.error) {
							alert(data.error);
							return;
						}

						setNgbs(prevData => prevData.filter(item => item.id !== id));
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			} catch (error) {
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(ngbs).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				name: value.name,
				country: value.country_code,
				email: value.email,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>NGBs <Link to="/admin/ngbs/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} />
				</div>

				<div className="col-12 form-group">
					<div>
						{(Object.entries(ngbs).length > 0) ?
							<Table
								data={columns()}
								visibleColumns={[
									{
										name: 'country',
										sort: 'country_code'
									},
									{
										name: 'name',
										sort: 'name'
									},
									{
										name: 'email',
										sort: 'email'
									},
								]}
								onSort={handleSort}
								sortedColumn={sortedColumn}
								sortDirection={sortDirection}
								linkColumn="name"
								linkLocation="/admin/ngbs/edit"
								editable={false}
								handleDelete={handleDeleteItem}
							/> : <div className="alert alert-danger">No NGBs found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(NgbsList);
