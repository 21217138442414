import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Loading from '../../../components/global/loading';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCoupons, deleteCoupon } from '../../../api/coupons';

const CouponsList = (props) => {
	const [loading, setLoading] = useState(true);
	const [coupons, setCoupons] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};
	
	const getInitialData = async () => {
		try {
			const getCouponsRes = await getCoupons(page, sortDirection, sortedColumn);
			if (getCouponsRes.data.status === 'success') {
				setCoupons(getCouponsRes.data.coupons);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [page, sortDirection, sortedColumn]);


	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			try {
				const couponDeleteRes = await deleteCoupon(id);
				if (couponDeleteRes.data.status === 'success') {
					setCoupons(prevData => prevData.filter(item => item.id !== id));
				}
			} catch (error) {
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(coupons).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				code: value.code,
				amount: value.amount,
				type: (value.type === 1) ? 'Percentage':'Fixed',
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Coupons <Link to="/admin/coupons/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>

				<div className="col-12 form-group">
					<div>
						
						{(coupons && coupons.length > 0) ?
							<Table
								data={columns()}
								visibleColumns={[
									{
										name: 'code',
										sort: 'code'
									},
									{
										name: 'amount',
										sort: 'amount'
									},
									{
										name: 'type',
										sort: 'type'
									},
								]}
								onSort={handleSort}
								sortedColumn={sortedColumn}
								sortDirection={sortDirection}
								linkColumn="code"
								linkLocation="/admin/coupons/edit"
								editable={true}
								handleDelete={handleDeleteItem}
							/> : <div className="alert alert-danger">No Coupons found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(CouponsList);
