const orderStatusList = [
	{value: 0, label: 'Pending Payment'},
	{value: 1, label: 'Complete'},
	{value: 2, label: 'Refunded'},
	{value: 3, label: 'On-hold'},
];

const statusList = [
	{value: 0, label: 'Draft'},
	{value: 1, label: 'Payment Required'},
	{value: 3, label: 'Withdrawn'},
	{value: 4, label: 'Awaiting NGB Approval'},
	{value: 5, label: 'NGB Approved'},
	{value: 6, label: 'NGB Rejected'},
	{value: 7, label: 'Entries Office Approved'},
	{value: 8, label: 'Entries Office Rejected'},
	{value: 9, label: 'Committee Accepted'},
	{value: 10, label: 'Committee Rejected'},
	{value: 11, label: 'Cancelled'},
	{value: 20, label: 'Complete'},
	{value: 99, label: 'Autosave'}
];

const selectedList = [
	{value: 0, label: 'Not Selected'},
	{value: 1, label: 'Group 1'},
	{value: 2, label: 'Group 2'},
	{value: 3, label: 'Group 3'},
];

const rankList = [
	{value: "", label: "None"},
	{value: "A", label: "A"},
	{value: "B", label: "B"},
	{value: "C", label: "C"},
	{value: "D", label: "D"},
	{value: "E", label: "E"},
	{value: "F", label: "F"},
];

const qualifiedOptions = [
	{ value: 0, label: 'null' },
	{ value: 1, label: 'Qualify' },
	{ value: 2, label: 'Through' },
	{ value: 3, label: 'Qualified' },
	{ value: 4, label: 'Failed to Qualify' },
	{ value: 5, label: 'Did Not Report' },
	{ value: 6, label: 'Withdrawn (Before Regatta)' },
	{ value: 7, label: 'Withdrawn (During Regatta)' },
];

const verdictsOpts = [
	{ value: 'dh', label: 'Dead Heat' },
	{ value: '1ft', label: '1 foot' },
	{ value: '2ft', label: '2 feet' },
	{ value: '3ft', label: '3 feet' },
	{ value: '4ft', label: '4 feet' },
	{ value: 'canvas', label: 'Canvas' },
	{ value: '14l', label: '¼ length' },
	{ value: '13l', label: '⅓ length' },
	{ value: '12l', label: '½ length' },
	{ value: '23l', label: '⅔ length' },
	{ value: '34l', label: '¾ length' },
	{ value: '1l', label: '1 length' },
	{ value: '114l', label: '1¼ length' },
	{ value: '113l', label: '1 ⅓ length' },
	{ value: '112l', label: '1 ½ length' },
	{ value: '123l', label: '1 ⅔ length' },
	{ value: '134l', label: '1 ¾ length' },
	{ value: '2l', label: '2 lengths' },
	{ value: '214l', label: '2 ¼ length' },
	{ value: '213l', label: '2 ⅓ length' },
	{ value: '212l', label: '2 ½ length' },
	{ value: '223l', label: '2 ⅔ length' },
	{ value: '234l', label: '2 ¾ length' },
	{ value: '3l', label: '3 lengths' },
	{ value: '314l', label: '3 ¼ length' },
	{ value: '313l', label: '3 ⅓ length' },
	{ value: '312l', label: '3 ½ length' },
	{ value: '223l', label: '3 ⅔ length' },
	{ value: '334l', label: '3 ¾ length' },
	{ value: '4l', label: '4 lengths' },
	{ value: '414l', label: '4 ¼ length' },
	{ value: '413l', label: '4 ⅓ length' },
	{ value: '412l', label: '4 ½ length' },
	{ value: '423l', label: '4 ⅔ length' },
	{ value: '434l', label: '4 ¾ length' },
	{ value: '5l', label: '5 lengths' },
	{ value: 'easily', label: 'Easily' },
	{ value: 'nro', label: 'NRO(Not Rowed Out)' },
	{ value: 'dq', label: 'Disqualified' },
	{ value: 'ro', label: 'Rowed Over' },
	{ value: 'so', label: 'Sculled Over' },
	{ value: 'dns', label: 'Did not start' },
]

const countryListAlpha2 = [
	{ label: "Afghanistan", value: "AF" },
	{ value: "AL", label: "Albania" },
	{ value: "DZ", label: "Algeria" },
	{ value: "AS", label: "American Samoa" },
	{ value: "AD", label: "Andorra" },
	{ value: "AO", label: "Angola" },
	{ value: "AI", label: "Anguilla" },
	{ value: "AQ", label: "Antarctica" },
	{ value: "AG", label: "Antigua and Barbuda" },
	{ value: "AR", label: "Argentina" },
	{ value: "AM", label: "Armenia" },
	{ value: "AW", label: "Aruba" },
	{ value: "AU", label: "Australia" },
	{ value: "AT", label: "Austria" },
	{ value: "AZ", label: "Azerbaijan" },
	{ value: "BS", label: "Bahamas" },
	{ value: "BH", label: "Bahrain" },
	{ value: "BD", label: "Bangladesh" },
	{ value: "BB", label: "Barbados" },
	{ value: "BY", label: "Belarus" },
	{ value: "BE", label: "Belgium" },
	{ value: "BZ", label: "Belize" },
	{ value: "BJ", label: "Benin" },
	{ value: "BM", label: "Bermuda" },
	{ value: "BT", label: "Bhutan" },
	{ value: "BO", label: "Bolivia (Plurinational State of)" },
	{ value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
	{ value: "BA", label: "Bosnia and Herzegovina" },
	{ value: "BW", label: "Botswana" },
	{ value: "BV", label: "Bouvet Island" },
	{ value: "BR", label: "Brazil" },
	{ value: "IO", label: "British Indian Ocean Territory" },
	{ value: "BN", label: "Brunei Darussalam" },
	{ value: "BG", label: "Bulgaria" },
	{ value: "BF", label: "Burkina Faso" },
	{ value: "BI", label: "Burundi" },
	{ value: "CV", label: "Cabo Verde" },
	{ value: "KH", label: "Cambodia" },
	{ value: "CM", label: "Cameroon" },
	{ value: "CA", label: "Canada" },
	{ value: "KY", label: "Cayman Islands" },
	{ value: "CF", label: "Central African Republic" },
	{ value: "TD", label: "Chad" },
	{ value: "CL", label: "Chile" },
	{ value: "CN", label: "China" },
	{ value: "CX", label: "Christmas Island" },
	{ value: "CC", label: "Cocos (Keeling) Islands" },
	{ value: "CO", label: "Colombia" },
	{ value: "KM", label: "Comoros" },
	{ value: "CD", label: "Congo (the Democratic Republic of the)" },
	{ value: "CG", label: "Congo" },
	{ value: "CK", label: "Cook Islands" },
	{ value: "CR", label: "Costa Rica" },
	{ value: "HR", label: "Croatia" },
	{ value: "CU", label: "Cuba" },
	{ value: "CW", label: "Curaçao" },
	{ value: "CY", label: "Cyprus" },
	{ value: "CZ", label: "Czechia" },
	{ value: "CI", label: "Côte d'Ivoire" },
	{ value: "DK", label: "Denmark" },
	{ value: "DJ", label: "Djibouti" },
	{ value: "DM", label: "Dominica" },
	{ value: "DO", label: "Dominican Republic" },
	{ value: "EC", label: "Ecuador" },
	{ value: "EG", label: "Egypt" },
	{ value: "SV", label: "El Salvador" },
	{ value: "GQ", label: "Equatorial Guinea" },
	{ value: "ER", label: "Eritrea" },
	{ value: "EE", label: "Estonia" },
	{ value: "SZ", label: "Eswatini" },
	{ value: "ET", label: "Ethiopia" },
	{ value: "FK", label: "Falkland Islands [Malvinas]" },
	{ value: "FO", label: "Faroe Islands" },
	{ value: "FJ", label: "Fiji" },
	{ value: "FI", label: "Finland" },
	{ value: "FR", label: "France" },
	{ value: "GF", label: "French Guiana" },
	{ value: "PF", label: "French Polynesia" },
	{ value: "TF", label: "French Southern Territories" },
	{ value: "GA", label: "Gabon" },
	{ value: "GM", label: "Gambia" },
	{ value: "GE", label: "Georgia" },
	{ value: "DE", label: "Germany" },
	{ value: "GH", label: "Ghana" },
	{ value: "GI", label: "Gibraltar" },
	{ value: "GR", label: "Greece" },
	{ value: "GL", label: "Greenland" },
	{ value: "GD", label: "Grenada" },
	{ value: "GP", label: "Guadeloupe" },
	{ value: "GU", label: "Guam" },
	{ value: "GT", label: "Guatemala" },
	{ value: "GG", label: "Guernsey" },
	{ value: "GN", label: "Guinea" },
	{ value: "GW", label: "Guinea-Bissau" },
	{ value: "GY", label: "Guyana" },
	{ value: "HT", label: "Haiti" },
	{ value: "HM", label: "Heard Island and McDonald Islands" },
	{ value: "VA", label: "Holy See" },
	{ value: "HN", label: "Honduras" },
	{ value: "HK", label: "Hong Kong" },
	{ value: "HU", label: "Hungary" },
	{ value: "IS", label: "Iceland" },
	{ value: "IN", label: "India" },
	{ value: "ID", label: "Indonesia" },
	{ value: "IR", label: "Iran (Islamic Republic of)" },
	{ value: "IQ", label: "Iraq" },
	{ value: "IE", label: "Ireland" },
	{ value: "IM", label: "Isle of Man" },
	{ value: "IL", label: "Israel" },
	{ value: "IT", label: "Italy" },
	{ value: "JM", label: "Jamaica" },
	{ value: "JP", label: "Japan" },
	{ value: "JE", label: "Jersey" },
	{ value: "JO", label: "Jordan" },
	{ value: "KZ", label: "Kazakhstan" },
	{ value: "KE", label: "Kenya" },
	{ value: "KI", label: "Kiribati" },
	{ value: "KP", label: "Korea (the Democratic People's Republic of)" },
	{ value: "KR", label: "Korea (the Republic of)" },
	{ value: "KW", label: "Kuwait" },
	{ value: "KG", label: "Kyrgyzstan" },
	{ value: "LA", label: "Lao People's Democratic Republic" },
	{ value: "LV", label: "Latvia" },
	{ value: "LB", label: "Lebanon" },
	{ value: "LS", label: "Lesotho" },
	{ value: "LR", label: "Liberia" },
	{ value: "LY", label: "Libya" },
	{ value: "LI", label: "Liechtenstein" },
	{ value: "LT", label: "Lithuania" },
	{ value: "LU", label: "Luxembourg" },
	{ value: "MO", label: "Macao" },
	{ value: "MG", label: "Madagascar" },
	{ value: "MW", label: "Malawi" },
	{ value: "MY", label: "Malaysia" },
	{ value: "MV", label: "Maldives" },
	{ value: "ML", label: "Mali" },
	{ value: "MT", label: "Malta" },
	{ value: "MH", label: "Marshall Islands" },
	{ value: "MQ", label: "Martinique" },
	{ value: "MR", label: "Mauritania" },
	{ value: "MU", label: "Mauritius" },
	{ value: "YT", label: "Mayotte" },
	{ value: "MX", label: "Mexico" },
	{ value: "FM", label: "Micronesia (Federated States of)" },
	{ value: "MD", label: "Moldova (the Republic of)" },
	{ value: "MC", label: "Monaco" },
	{ value: "MN", label: "Mongolia" },
	{ value: "ME", label: "Montenegro" },
	{ value: "MS", label: "Montserrat" },
	{ value: "MA", label: "Morocco" },
	{ value: "MZ", label: "Mozambique" },
	{ value: "MM", label: "Myanmar" },
	{ value: "NA", label: "Namibia" },
	{ value: "NR", label: "Nauru" },
	{ value: "NP", label: "Nepal" },
	{ value: "NL", label: "Netherlands" },
	{ value: "NC", label: "New Caledonia" },
	{ value: "NZ", label: "New Zealand" },
	{ value: "NI", label: "Nicaragua" },
	{ value: "NE", label: "Niger" },
	{ value: "NG", label: "Nigeria" },
	{ value: "NU", label: "Niue" },
	{ value: "NF", label: "Norfolk Island" },
	{ value: "MP", label: "Northern Mariana Islands" },
	{ value: "NO", label: "Norway" },
	{ value: "OM", label: "Oman" },
	{ value: "PK", label: "Pakistan" },
	{ value: "PW", label: "Palau" },
	{ value: "PS", label: "Palestine, State of" },
	{ value: "PA", label: "Panama" },
	{ value: "PG", label: "Papua New Guinea" },
	{ value: "PY", label: "Paraguay" },
	{ value: "PE", label: "Peru" },
	{ value: "PH", label: "Philippines" },
	{ value: "PN", label: "Pitcairn" },
	{ value: "PL", label: "Poland" },
	{ value: "PT", label: "Portugal" },
	{ value: "PR", label: "Puerto Rico" },
	{ value: "QA", label: "Qatar" },
	{ value: "MK", label: "Republic of North Macedonia" },
	{ value: "RO", label: "Romania" },
	{ value: "RU", label: "Russian Federation" },
	{ value: "RW", label: "Rwanda" },
	{ value: "RE", label: "Réunion" },
	{ value: "BL", label: "Saint Barthélemy" },
	{ value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
	{ value: "KN", label: "Saint Kitts and Nevis" },
	{ value: "LC", label: "Saint Lucia" },
	{ value: "MF", label: "Saint Martin (French part)" },
	{ value: "PM", label: "Saint Pierre and Miquelon" },
	{ value: "VC", label: "Saint Vincent and the Grenadines" },
	{ value: "WS", label: "Samoa" },
	{ value: "SM", label: "San Marino" },
	{ value: "ST", label: "Sao Tome and Principe" },
	{ value: "SA", label: "Saudi Arabia" },
	{ value: "SN", label: "Senegal" },
	{ value: "RS", label: "Serbia" },
	{ value: "SC", label: "Seychelles" },
	{ value: "SL", label: "Sierra Leone" },
	{ value: "SG", label: "Singapore" },
	{ value: "SX", label: "Sint Maarten (Dutch part)" },
	{ value: "SK", label: "Slovakia" },
	{ value: "SI", label: "Slovenia" },
	{ value: "SB", label: "Solomon Islands" },
	{ value: "SO", label: "Somalia" },
	{ value: "ZA", label: "South Africa" },
	{ value: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ value: "SS", label: "South Sudan" },
	{ value: "ES", label: "Spain" },
	{ value: "LK", label: "Sri Lanka" },
	{ value: "SD", label: "Sudan" },
	{ value: "SR", label: "Suriname" },
	{ value: "SJ", label: "Svalbard and Jan Mayen" },
	{ value: "SE", label: "Sweden" },
	{ value: "CH", label: "Switzerland" },
	{ value: "SY", label: "Syrian Arab Republic" },
	{ value: "TW", label: "Taiwan" },
	{ value: "TJ", label: "Tajikistan" },
	{ value: "TZ", label: "Tanzania, United Republic of" },
	{ value: "TH", label: "Thailand" },
	{ value: "TL", label: "Timor-Leste" },
	{ value: "TG", label: "Togo" },
	{ value: "TK", label: "Tokelau" },
	{ value: "TO", label: "Tonga" },
	{ value: "TT", label: "Trinidad and Tobago" },
	{ value: "TN", label: "Tunisia" },
	{ value: "TR", label: "Turkey" },
	{ value: "TM", label: "Turkmenistan" },
	{ value: "TC", label: "Turks and Caicos Islands" },
	{ value: "TV", label: "Tuvalu" },
	{ value: "UG", label: "Uganda" },
	{ value: "UA", label: "Ukraine" },
	{ value: "AE", label: "United Arab Emirates" },
	{ value: "GB", label: "United Kingdom" },
	{ value: "UM", label: "United States Minor Outlying Islands" },
	{ value: "US", label: "United States of America" },
	{ value: "UY", label: "Uruguay" },
	{ value: "UZ", label: "Uzbekistan" },
	{ value: "VU", label: "Vanuatu" },
	{ value: "VE", label: "Venezuela (Bolivarian Republic of)" },
	{ value: "VN", label: "Viet Nam" },
	{ value: "VG", label: "Virgin Islands (British)" },
	{ value: "VI", label: "Virgin Islands (U.S.)" },
	{ value: "WF", label: "Wallis and Futuna" },
	{ value: "EH", label: "Western Sahara" },
	{ value: "YE", label: "Yemen" },
	{ value: "ZM", label: "Zambia" },
	{ value: "ZW", label: "Zimbabwe" },
	{ value: "AX", label: "Åland Islands" }
];

const USStates = [
	{ label: "Alabama", value: "AL" },
	{ label: "Alaska", value: "AK" },
	{ label: "Arizona", value: "AZ" },
	{ label: "Arkansas", value: "AR" },
	{ label: "California", value: "CA" },
	{ label: "Colorado", value: "CO" },
	{ label: "Connecticut", value: "CT" },
	{ label: "Delaware", value: "DE" },
	{ label: "Florida", value: "FL" },
	{ label: "Georgia", value: "GA" },
	{ label: "Hawaii", value: "HI" },
	{ label: "Idaho", value: "ID" },
	{ label: "Illinois", value: "IL" },
	{ label: "Indiana", value: "IN" },
	{ label: "Iowa", value: "IA" },
	{ label: "Kansas", value: "KS" },
	{ label: "Kentucky", value: "KY" },
	{ label: "Louisiana", value: "LA" },
	{ label: "Maine", value: "ME" },
	{ label: "Maryland", value: "MD" },
	{ label: "Massachusetts", value: "MA" },
	{ label: "Michigan", value: "MI" },
	{ label: "Minnesota", value: "MN" },
	{ label: "Mississippi", value: "MS" },
	{ label: "Missouri", value: "MO" },
	{ label: "Montana", value: "MT" },
	{ label: "Nebraska", value: "NE" },
	{ label: "Nevada", value: "NV" },
	{ label: "New Hampshire", value: "NH" },
	{ label: "New Jersey", value: "NJ" },
	{ label: "New Mexico", value: "NM" },
	{ label: "New York", value: "NY" },
	{ label: "North Carolina", value: "NC" },
	{ label: "North Dakota", value: "ND" },
	{ label: "Ohio", value: "OH" },
	{ label: "Oklahoma", value: "OK" },
	{ label: "Oregon", value: "OR" },
	{ label: "Pennsylvania", value: "PA" },
	{ label: "Rhode Island", value: "RI" },
	{ label: "South Carolina", value: "SC" },
	{ label: "South Dakota", value: "SD" },
	{ label: "Tennessee", value: "TN" },
	{ label: "Texas", value: "TX" },
	{ label: "Utah", value: "UT" },
	{ label: "Vermont", value: "VT" },
	{ label: "Virginia", value: "VA" },
	{ label: "Washington", value: "WA" },
	{ label: "West Virginia", value: "WV" },
	{ label: "Wisconsin", value: "WI" },
	{ label: "Wyoming", value: "WY" }
];

const getCrewMemberOrder = (crewMembers) => {
	if (crewMembers && crewMembers.length) {
		const count = crewMembers.length;
		let memberData = [];

		// Check if there's a cox in the crew
		const cox = crewMembers.some(member => member.position === 'Cox');

		crewMembers.forEach((member, key) => {
			if (member.position === 'Bow') {
				memberData[0] = member;
			} else if (member.position === 'Cox' && cox) {
				memberData[count - 1] = member;
			} else if (member.position === 'Stroke') {
				if (cox) {
					memberData[count - 2] = member;
				} else {
					memberData[count - 1] = member;
				}
			} else {
				memberData[key] = member;
			}
		});

		// Filter out possible empty slots and return
		return memberData.filter(Boolean);
	}

	return [];
}

const fuzzySearch = (pattern, str) => {
	// Score consts
	var adjacency_bonus = 55;                // bonus for adjacent matches
	var separator_bonus = 10;               // bonus if match occurs after a separator
	var camel_bonus = 10;                   // bonus if match is uppercase and prev is lower
	var leading_letter_penalty = -3;        // penalty applied for every letter in str before the first match
	var max_leading_letter_penalty = -9;    // maximum penalty for leading letters
	var unmatched_letter_penalty = -1;      // penalty for every letter that doesn't matter

	// Loop variables
	var score = 0;
	var patternIdx = 0;
	var patternLength = pattern.length;
	var strIdx = 0;
	var strLength = str.length;
	var prevMatched = false;
	var prevLower = false;
	var prevSeparator = true;       // true so if first letter match gets separator bonus

	// Use "best" matched letter if multiple string letters match the pattern
	var bestLetter = null;
	var bestLower = null;
	var bestLetterIdx = null;
	var bestLetterScore = 0;

	var matchedIndices = [];
	var formattedStr = "";


	// Loop over strings
	while (strIdx !== strLength) {
		var patternChar = patternIdx !== patternLength ? pattern.charAt(patternIdx) : null;
		var strChar = str.charAt(strIdx);

		var patternLower = patternChar !== null ? patternChar.toLowerCase() : null;
		var strLower = strChar.toLowerCase();
		var strUpper = strChar.toUpperCase();

		var nextMatch = patternChar && patternLower === strLower;
		var rematch = bestLetter && bestLower === strLower;

		var advanced = nextMatch && bestLetter;
		var patternRepeat = bestLetter && patternChar && bestLower === patternLower;
		if (advanced || patternRepeat) {
			score += bestLetterScore;
			matchedIndices.push(bestLetterIdx);
			bestLetter = null;
			bestLower = null;
			bestLetterIdx = null;
			bestLetterScore = 0;
		}

		if (nextMatch || rematch) {
			var newScore = 0;

			// Apply penalty for each letter before the first pattern match
			// Note: std::max because penalties are negative values. So max is smallest penalty.
			if (patternIdx === 0) {
				var penalty = Math.max(strIdx * leading_letter_penalty, max_leading_letter_penalty);
				score += penalty;
			}

			// Apply bonus for consecutive bonuses
			if (prevMatched)
				newScore += adjacency_bonus;

			// Apply bonus for matches after a separator
			if (prevSeparator)
				newScore += separator_bonus;

			// Apply bonus across camel case boundaries. Includes "clever" isLetter check.
			if (prevLower && strChar === strUpper && strLower !== strUpper)
				newScore += camel_bonus;

			// Update patter index IFF the next pattern letter was matched
			if (nextMatch)
				++patternIdx;

			// Update best letter in str which may be for a "next" letter or a "rematch"
			if (newScore >= bestLetterScore) {

				// Apply penalty for now skipped letter
				if (bestLetter !== null)
					score += unmatched_letter_penalty;

				bestLetter = strChar;
				bestLower = bestLetter.toLowerCase();
				bestLetterIdx = strIdx;
				bestLetterScore = newScore;
			}

			prevMatched = true;
		}
		else {
			// Append unmatch characters
			formattedStr += strChar;

			score += unmatched_letter_penalty;
			prevMatched = false;
		}

		// Includes "clever" isLetter check.
		prevLower = strChar === strLower && strLower !== strUpper;
		prevSeparator = strChar === '_' || strChar === ' ';

		++strIdx;
	}

	// Apply score for last match
	if (bestLetter) {
		score += bestLetterScore;
		matchedIndices.push(bestLetterIdx);
	}

	// Finish out formatted string after last pattern matched
	// Build formated string based on matched letters
	var lastIdx = 0;
	for (var i = 0; i < matchedIndices.length; ++i) {
		var idx = matchedIndices[i];
		formattedStr += str.substr(lastIdx, idx - lastIdx) + "<b>" + str.charAt(idx) + "</b>";
		lastIdx = idx + 1;
	}
	formattedStr += str.substr(lastIdx, str.length - lastIdx);

	var matched = patternIdx === patternLength;
	return [matched, score, formattedStr];
}

// export
export {
	orderStatusList,
	statusList,
	selectedList,
	rankList,
	qualifiedOptions,
	getCrewMemberOrder,
	verdictsOpts,
	countryListAlpha2,
	fuzzySearch,
	USStates,
};
