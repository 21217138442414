import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import Pagination from '../../../components/Pagination';
import { getEntries } from '../../../api/entries';

const Entries = () => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({ status: null, selected: null, event: null });
	const [isSearching, setIsSearching] = useState(false);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');
	const [selectedRows, setSelectedRows] = useState([]);
	const [resetSelectedRows, setResetSelectedRows] = useState(false);

	const handleSelectedRowsChange = (newSelectedRows) => {
		setSelectedRows(newSelectedRows);
	};

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getEntriesRes = await getEntries(filters, page, sortDirection, sortedColumn);
			if (getEntriesRes.data.status === 'success') {
				setEntries(getEntriesRes.data.entries);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page, filters, sortDirection, sortedColumn]);

	const handleFilters = (newFilters) => {
		// merge the new filters with the existing filters
		const mergedFilters = { ...filters, ...newFilters };
		setFilters(mergedFilters);
	};

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		// setPage(1);
		setEntries(data);
	};

	// const handleSort = (column, direction) => {
	// 	setLoading(true);
	// 	// do a fetch to sort with a get request
	// 	fetch(`${API_URL}/admin-entries`, {
	// 		headers: {
	// 			// auth url
	// 			'Content-Type': 'application/json',
	// 			'Authorization': `Bearer ${token}`,
	// 		},
	// 		// add params
	// 		params: {
	// 			sort: column,
	// 			direction: direction,
	// 			status: (status) ? status : 'approved',
	// 		}
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			setEntries(data);
	// 			// dispatch({ type: 'EVENTS', payload: data });
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// };


	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				view: 'admin',
				checkbox: <input type="checkbox" name={`entry[${value.id}]`} />,
				id: value.id,
				crew_id: value.crew.identifier,
				crew_number: (value.crew.crew_number) ? value.crew.crew_number : '-',
				crew_name: value.crew.crew_name,
				event_name: value.event.name,
				status: value.status_text,
				last_updated: value.updated_at_formatted,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Entries</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/entries/search" page={page} setIsSearching={setIsSearching} placeholder="Search ENC Number or ID" setLoading={setLoading} />
				</div>
				<div className="filters-container">
					<div className="col-12">
						<h3>Filters</h3>
					</div>
					<div className="col-12 col-md-12 mb-3">
						<EntryFilter handleFilters={handleFilters} filters={filters} />
					</div>
				</div>
				<div className="col-12 mb-3">
					{
						(Object.entries(filters).length > 1) ?
							Object.entries(filters).map((filter, key) => {
								if (!filter[1]) {
									return '';
								} else {
									return (
										<div className="filter" key={key}>
											<strong>{filter[0].toUpperCase()}</strong>: {filter[1].label}
										</div>
									);
								}
							}) : 'Viewing all entries.'
					}
				</div>
				<div className="col-12 form-group">
					<div>
						{(entries.data && entries.data.length > 0) ?
							<div>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										// {
										// 	name: 'checkbox',
										// 	type: 'checkbox',
										// 	sort: false
										// },
										{
											name: 'crew_id',
											sort: 'id'
										},
										{
											name: 'crew_number',
											sort: 'crew_number'
										},
										{
											name: 'crew_name',
											sort: 'crew_name'
										},
										{
											name: 'event_name',
											sort: 'event_name'
										},
										{
											name: 'status',
											sort: 'status'
										},
										{
											name: 'last_updated',
											sort: 'updated_at'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="crew_name"
									linkLocation="/admin/entries/edit"
									editable={true}
									onSelectedRowsChange={handleSelectedRowsChange}
									resetSelected={resetSelectedRows}
								/>
								<Pagination data={entries} setPage={setPage} />
							</div>: <div className="alert alert-danger">No entries found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Entries);
