import React, { useEffect, useState } from 'react';
import { verify } from '../../api/auth';

function VerifyEmail(props) {
	const [message, setMessage] = useState("Verifying...");

	useEffect(() => {
		const token = new URLSearchParams(window.location.search).get("token");

		if (token) {
			(async () => {
				try {
					const verifyEmail = await verify({token: token});
					if (verifyEmail.data.status === "success") {
						setMessage('<div className="alert alert-success">Email verified. Please <a href="/login">login</a>.</div>');
					}
				} catch (error) {
					if (error.response.data.status === "error") {
						const errorMessage = error.response.data.message;
						setMessage('<div className="alert alert-info">' + errorMessage + '</div>');
					} else {
						setMessage('<div className="alert alert-danger">Error verifying email.</div>');
					}
				}
			})();
		}
	}, []);

	return (
		<div className="container h-100">
			<div dangerouslySetInnerHTML={{__html: message}}></div>
		</div>
	);
}

export default VerifyEmail;
