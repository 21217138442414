import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getCoupon, saveCoupon } from '../../../api/coupons';

const CouponSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(false);
	const [coupon, setCoupon] = useState(null);

	// get events
	const events = useSelector(state => state.events);

	// sort events into array of objects for select field
	const eventTypeList = Object.values(events.events).map((event) => {
		return {
			label: event.name,
			value: event.id,
		}
	});

	// add a blank option to the beginning of the array
	eventTypeList.unshift({ label: "All", value: "" });

	const getInitialData = async (id) => {
		try {
			const getCouponRes = await getCoupon(id);
			if (getCouponRes.data.status === "success") {
				setCoupon(getCouponRes.data.coupon);
				setLoading(false);
			}
		} catch (error) {
			
		}
	}

	useEffect(() => {
		// getEventTypes().finally(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		}
		// });
	}, []);

	const CouponForm = () => {
		// if entry is an object
		const initialValues = {
			code: (coupon && coupon.code) ? coupon.code : '',
			amount: (coupon &&coupon.amount) ? coupon.amount:'',
			type: (coupon && coupon.type) ? coupon.type : '',
			max_uses: (coupon && coupon.max_uses) ? coupon.max_uses: '',
			event_id: (coupon && coupon.event_id) ? coupon.event_id : '',
		};

		const validationSchema = Yup.object().shape({
			code: Yup.string().required('Required'),
			amount: Yup.string().required('Required'),
			type: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/coupons/${data.id}` : `${API_URL}/admin/coupons`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT' : 'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						setLoading(false);
						setSubmitting(false);
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "coupon saved successfully!", autoClose: 5000 });
	
						if (res.id && !data.id) {
							window.location.href = `/admin/coupons`;
						}
					}
				})
				.catch((error) => {
					console.error(error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							// const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type) : null;

							return (
								<Form>
									<div className="row">
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="username">Coupon Code:</label>
												<Field type="text" className="form-control" name="code" />
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="event_code">Amount:</label>
												<Field type="number" name="amount" className="form-control" />
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="event_code">Discount Type</label>
												<Field component={SelectField} name="type" className="form-control" options={[{ label: "Percentage", value: 1 }, { label: "Fixed", value: 2 }]} />
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="event_code">Max Uses</label>
												<Field type="number" name="max_uses" className="form-control" />
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group-flex">
												<label htmlFor="event_code">Event</label>
												<Field component={SelectField} name="event_id" className="form-control" options={eventTypeList} />
											</div>
										</div>
									</div>

									<hr />

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/coupons"><FontAwesomeIcon icon={faArrowLeft} /> Back to Coupons</Link></div>
				<div className="col-12 col-md-6">
					{(coupon) ? <h1>Edit Coupon</h1> : <h1>Add Coupon</h1>}
				</div>

				<div className="col-12 form-group">
					{<CouponForm />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(CouponSingle);
