import instance from './config';

const refreshToken = () => {
	return instance.get('/refresh');
};

const login = (data) => {
	return instance.post('/login', data);
};

const register = (data) => {
	return instance.post('/register', data);
};

const logout = (data) => {
	return instance.put(`/logout`, data);
};

const verify = (token) => {
	return instance.post(`/validate`, token);
};

export {
	refreshToken,
	login,
	register,
	logout,
	verify
}
