import instance from './config';

const getQualifyingCrews = () => {
	return instance.get('/admin/qualifying/crews');
};

const getQualifyingGroups = (event_id) => {
	return instance.get(`/admin/qualifying/groups/${event_id}`);
};

const getQualifyingGroup = (event_id) => {
	return instance.get(`/admin/qualifying/group/${event_id}`);
};

const getQualifyingGroupSettings = (event_id) => {
	return instance.get(`/admin/qualifying/settings/${event_id}`);
};

const saveQualifyingGroupStatus = (data) => {
	return instance.post('/admin/qualifying/groups', data);
}

const saveQualifyingGroupSettings = (data) => {
	return instance.post('/admin/qualifying/settings', data);
}

const getQualifyingGroupResults = () => {
	return instance.get('/admin/qualifying/results');
}

const saveQualifyingGroupResults = (data) => {
	console.log('D', data);
	return instance.post('/admin/qualifying/results', data);
}

const saveQualifyingGroupResult = (data) => {
	return instance.post('/admin/qualifying/result', data);
}

const resetQualifyingGroupResults = () => {
	return instance.delete('/admin/qualifying/results');
}

export {
	getQualifyingCrews,
	getQualifyingGroups,
	getQualifyingGroup,
	saveQualifyingGroupStatus,
	saveQualifyingGroupSettings,
	getQualifyingGroupSettings,
	getQualifyingGroupResults,
	saveQualifyingGroupResults,
	saveQualifyingGroupResult,
	resetQualifyingGroupResults
}
