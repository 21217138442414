import instance from './config';

const getCountries = () => {
	return instance.get('/countries');
};

const getLog = (id) => {
	return instance.get(`/admin/log/entry/${id}`);
};

export {
	getCountries,
	getLog,
}
