import { useState } from 'react';
import { withRouter } from '../common/with-router';
import { useLocation } from 'react-router-dom';
import Loading from '../components/global/loading';
import { doNgbApprovals } from '../api/ngb';

const NgbApprovals = () => {
	// const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const members = searchParams.getAll('members[]');
	const act = searchParams.get('act');

	const ngbApprovals = async () => {
		if (act && members) {
			try {
				const ngbApprove = await doNgbApprovals({ act: act, members: members });
				if (ngbApprove.data.status === 'success') {
					setMessage('Crew successfully ' + act + 'ed.');
				}

				// setLoading(false);
			} catch (error) {
				if (error.response.status === 400) {
					setMessage('Error: ' + error.response.data.message);
				} else {
					setMessage('Error: An unknown error occurred.');
				}

				// setLoading(false);
			}
		} else {
			setMessage('Missing required parameters.');
			// setLoading(false);
		}
	};

	// if (loading) {
	// 	return <Loading />;
	// }

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Crew Approval</h1>
					{(message) &&
						<div className="row">
							<div className="col-12 mb-2">
								<div className="alert alert-info">{message}</div>
							</div>
						</div>
					}

					<div className="row">
						<div className="col-12 mb-2">
							{(act === 'approve') && <button className="btn btn-primary" onClick={() => ngbApprovals()}>Click to Approve Crew</button>}
							{(act === 'deny') && <button className="btn btn-primary" onClick={() => ngbApprovals()}>Click to Deny Crew</button>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(NgbApprovals);
