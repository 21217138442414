import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

const EntryFilter = ({ handleFilters, filters }) => {
	const [status, setStatus] = useState(null);
	const [selected, setSelected] = useState(null);
	const [qualified, setQualified] = useState(null);
	const [event, setEvent] = useState(null);
	const [events, setEvents] = useState(null);

	const eventsList = useSelector((state) => state.events)

	useEffect(() => {
		mapEventsToSelect()
	}, []);

	const mapEventsToSelect = () => {
		let eventOptions = [];
		eventOptions.push({ value: '', label: 'All' });
		Object.entries(eventsList.events).map(([key, value]) => {
			eventOptions.push({ value: value.id, label: value.name });
		});

		setEvents(eventOptions);
	}

	const statusOptions = [
		{ value: '', label: 'All' },
		{ value: 0, label: 'Draft' },
		{ value: 1, label: 'Payment Required' },
		{ value: 3, label: 'Withdrawn' },
		{ value: 4, label: 'Awaiting NGB Approval' },
		{ value: 5, label: 'NGB Approved' },
		{ value: 6, label: 'NGB Rejected' },
		{ value: 7, label: 'Entries Office Approved' },
		{ value: 8, label: 'Entries Office Rejected' },
		{ value: 9, label: 'Committee Accepted' },
		{ value: 10, label: 'Committee Rejected' },
		{ value: 11, label: 'Cancelled' },
		{ value: 20, label: 'Complete' },
		{ value: 99, label: 'Autosave' },
	];

	const selectedOptions = [
		{ value: '', label: 'All' },
		{ value: 1, label: 'Selected' },
		{ value: 0, label: 'Not Selected' },
	];
	
	const qualifiedOptions = [
		{ value: '', label: 'All' },
		{ value: 0, label: 'null' },
		{ value: 1, label: 'Qualify' },
		{ value: 2, label: 'Through' },
		{ value: 3, label: 'Qualified' },
		{ value: 4, label: 'Failed to Qualify' },
		{ value: 5, label: 'Did Not Report' },
	];

	const eventOptions = events;

	const handleStatusChange = selectedOption => {
		// if blank, set to null
		if (selectedOption.value === '') {
			selectedOption = null;
		}

		setStatus(selectedOption);
		handleFilters({ status: selectedOption, selected, event, qualified });
		// save to localstorage
		// if (selectedOption !== null) {
		// 	localStorage.setItem('status', JSON.stringify(selectedOption));
		// } else {
		// 	localStorage.removeItem('status');
		// }

	};

	const handleSelectedChange = selectedOption => {
		if (selectedOption.value === '') {
			selectedOption = null;
		}
		setSelected(selectedOption);
		handleFilters({ status, selected: selectedOption, event, qualified });
		// save to localstorage

	};

	const handleEventChange = selectedOption => {
		if (selectedOption.value === '') {
			selectedOption = null;
		}
		setEvent(selectedOption);
		handleFilters({ status, selected, event: selectedOption, qualified });
		// save to localstorage

	};
	
	const handleQualifiedChange = selectedOption => {
		if (selectedOption.value === '') {
			selectedOption = null;
		}
		setQualified(selectedOption);
		handleFilters({ status, selected, event, qualified: selectedOption });
		// save to localstorage

	};

	return (
		<div className="filter-container row">
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Status</label>
				<Select
					value={filters.status}
					onChange={handleStatusChange}
					options={statusOptions}
				/>
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Selected</label>
				<Select
					value={filters.selected}
					onChange={handleSelectedChange}
					options={selectedOptions}
				/>
			</div>
			<div className="filter-item col-12 col-md-6">
				<label>Qualified Status</label>
				<Select
					value={filters.qualified}
					onChange={handleQualifiedChange}
					options={qualifiedOptions}
				/>
			</div>
			<div className="filter-item col-12 col-md-6">
				<label>Event</label>
				<Select
					value={filters.event}
					onChange={handleEventChange}
					options={eventOptions}
				/>
			</div>
		</div>
	);
};

export default EntryFilter;
