import React, { useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { getDuplicateAthletes, mergeAthleteData } from '../../../api/athletes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import SelectField from '../../../components/forms/select';
import { toast } from 'react-toastify';

const AthleteDuplicateList = (props) => {
	const [loading, setLoading] = useState(false);
	const [duplicates, setDuplicates] = useState({});
	const [hiddenRows, setHiddenRows] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [athlete, setAthlete] = useState({});

	const toggleRowVisibility = (index) => {
		let newHiddenRows = [...hiddenRows];
		newHiddenRows[index] = !newHiddenRows[index];
		setHiddenRows(newHiddenRows);
	}

	const getDuplicatesReport = async () => {
		setLoading(true);

		try {
			const fetchAthletes = await getDuplicateAthletes();
			if (fetchAthletes.data.status === 'success') {
				setDuplicates(fetchAthletes.data.duplicates);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	const mergeModal = (id, original, type, index = null) => {
		setShowModal(true);
		setAthlete({id: id, original: original, index: index, type: type});
	}

	const MergeModalDialog = () => {
		let duplicateList = duplicates[athlete.original].duplicates.map((athlete, index) => (
			{
				value: athlete.id,
				label: athlete.hrr_number + ' - ' + athlete.first_name + ' ' + athlete.initials + ' ' + athlete.last_name,
			}
		));

		duplicateList.unshift({ value: duplicates[athlete.original].original.id, label: duplicates[athlete.original].original.hrr_number + ' - ' + duplicates[athlete.original].original.first_name + ' ' + duplicates[athlete.original].original.initials + ' ' + duplicates[athlete.original].original.last_name });
		duplicateList = duplicateList.filter((list) => {
			return parseInt(list.value) !== parseInt(athlete.id);
		});

		return (
			<div className="modal show d-block" id="mergeModal" tabIndex="-1" role="dialog" aria-labelledby="mergeModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<Formik
							initialValues={{
								id: athlete.id,
								hrr_number: null,
								first_name: false,
								initials: false,
								bio: false,
							}}
							onSubmit={async (values, { setSubmitting }) => {
								// set toast
								const toastId = toast.loading('Merging Athletes...');
								try {
									const mergeAthletes = await mergeAthleteData(values);
									if (mergeAthletes.data.status === 'success') {
										toast.update(toastId, { render: 'Athlete Merged', type: 'success', isLoading: false, autoClose: 3000 });
										setShowModal(false);
										// getDuplicatesReport();
									} else {
										toast.update(toastId, { render: mergeAthletes.data.message, type: 'error', isLoading: false, autoClose: 3000 });
									}
								} catch (error) {
									toast.update(toastId, { render: 'Error Merging Athlete', type: 'error', isLoading: false, autoClose: 3000 });
									console.log('error', error);
								}
							}}
						>
							{({ isSubmitting, values }) => (
								<Form>
									<div className="modal-header">
										{console.log('athlete', athlete)}
										<h5 className="modal-title" id="mergeModalLabel">Merging {(athlete.type === 'duplicates') ? duplicates[athlete.original][athlete.type][athlete.index].hrr_number + ' - ' + duplicates[athlete.original][athlete.type][athlete.index].first_name + ' ' + duplicates[athlete.original][athlete.type][athlete.index].initials + ' ' + duplicates[athlete.original][athlete.type][athlete.index].last_name + ' ' : duplicates[athlete.original][athlete.type].hrr_number + ' - ' + duplicates[athlete.original][athlete.type].first_name + ' ' + duplicates[athlete.original][athlete.type].initials + ' ' + duplicates[athlete.original][athlete.type].last_name + ' '}</h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<h2>Athlete Merging</h2>
										<div className="alert alert-warning"><strong>Warning:</strong> This action cannot be undone. Please ensure a backup is taken of the database <strong>before</strong> running this merging process.</div>
										<p>When merging athletes, the athlete you are merging with will be the primary athlete. The current athlete you are merging will be removed from the system. All entries containing this athlete will be changed to the primary athlete.</p>
										<Field type="hidden" name="id" />
										<div className="form-group">
											<label htmlFor="hrr_number">You are deleting and merging {(athlete.type === 'duplicates') ? duplicates[athlete.original][athlete.type][athlete.index].hrr_number + ' - ' + duplicates[athlete.original][athlete.type][athlete.index].first_name + ' ' + duplicates[athlete.original][athlete.type][athlete.index].initials + ' ' + duplicates[athlete.original][athlete.type][athlete.index].last_name + ' ' : duplicates[athlete.original][athlete.type].hrr_number + ' - ' + duplicates[athlete.original][athlete.type].first_name + ' ' + duplicates[athlete.original][athlete.type].initials + ' ' + duplicates[athlete.original][athlete.type].last_name + ' '} with:</label>
											<Field component={SelectField} name="hrr_number" options={duplicateList} />
										</div>

										<div className="form-group">
											<h3>Fields to Merge</h3>
											<p>Select the fields to merge from this athlete to the new primary athlete.</p>
											<table className="table table-striped">
												<thead>
													<tr>
														<th width="50px"></th>
														<th>Fields to Merge</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<Field type="checkbox" name="first_name" />
														</td>
														<td>First Name</td>
													</tr>
													<tr>
														<td>
															<Field type="checkbox" name="initials" />
														</td>
														<td>Initials</td>
													</tr>
													<tr>
														<td>
															<Field type="checkbox" name="bio" />
														</td>
														<td>Bio Data</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="modal-footer">
										<button type="submit" className="btn btn-primary">Merge</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}

	const generateTable = () => {
		return (
			<table className="table table-striped">
				<thead style={{position: 'sticky', top: 0}}>
					<tr>
						<th></th>
						<th>HRR</th>
						<th>First Name</th>
						<th>Initials</th>
						<th>Last Name</th>
						<th>Date of Birth</th>
						<th>History</th>
						<th>Registered</th>
						<th>Bio</th>
						<th>Crew ENC</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(duplicates).map((athlete, index) => (
						<React.Fragment key={index}>
							<tr className={!hiddenRows[index] ? "duplicate-parent" : "op-1"}>
								<td><button onClick={() => toggleRowVisibility(index)}><FontAwesomeIcon icon={faEye} /></button></td>
								<td><Link to={`/admin/athletes/edit/${athlete[1].original.id}`} target="_blank">{athlete[1].original.hrr_number}</Link></td>
								<td>{athlete[1].original.first_name}</td>
								<td>{athlete[1].original.initials}</td>
								<td>{athlete[1].original.last_name}</td>
								<td>{athlete[1].original.dob}</td>
								<td>{(athlete[1].original.athlete.history.length > 0) ? 'Yes':'No'}</td>
								<td>{(athlete[1].original.athlete.user_id) ? 'Yes':'No'}</td>
								<td>{(athlete[1].original.athlete.sporting_highlights || athlete[1].original.athlete.job_course_university || athlete[1].original.athlete.other_info) ? 'Yes' : 'No'}</td>
								<td>{(athlete[1].original.crews.length > 0) ?
									athlete[1].original.crews.map((crew, i) => (
										<React.Fragment key={i}>
											<Link to={`/admin/entries/edit/${crew.id}`} target="_blank">{crew.id}</Link>
											{(i < athlete[1].original.crews.length - 1) ? ', ' : ''}
										</React.Fragment>
									))
									: '-'}
								</td>
								<td>
									{(athlete[1].highest_hrr !== athlete[1].original.hrr_number) && (
										<button onClick={() => mergeModal(athlete[1].original.id, athlete[0], 'original', null)} className="btn btn-secondary"><FontAwesomeIcon icon={faLayerGroup} /></button>
									)}
								</td>
							</tr>
							{athlete[1].duplicates.map((dup, idx) => (
								<tr key={`${index}-${idx}`} className={!hiddenRows[index] ? "duplicate-child" : "hidden"}>
									<td></td>
									<td><Link to={`/admin/athletes/edit/${dup.id}`} target="_blank">{dup.hrr_number}</Link></td>
									<td>{dup.first_name}</td>
									<td>{dup.initials}</td>
									<td>{dup.last_name}</td>
									<td>{dup.dob}</td>
									<td>{(dup.athlete.history.length > 0) ? 'Yes':'No'}</td>
									<td>{(dup.athlete.user_id) ? 'Yes':'No'}</td>
									<td>{(dup.athlete.sporting_highlights || dup.athlete.job_course_university || dup.athlete.other_info) ? 'Yes':'No'}</td>
									<td>
										{(dup.crews.length > 0) ? 
											dup.crews.map((crew, i) => (
												<React.Fragment key={i}>
													<Link to={`/admin/entries/edit/${crew.id}`} target="_blank">{crew.id}</Link>
													{(i < dup.crews.length - 1) ? ', ' : ''}
												</React.Fragment>
											))
										: '-'}
									</td>
									<td>
										{(athlete[1].highest_hrr !== dup.hrr_number) && (
											<button onClick={() => mergeModal(dup.id, athlete[0], 'duplicates', idx)} className="btn btn-secondary"><FontAwesomeIcon icon={faLayerGroup} /></button>
										)}
									</td>
								</tr>
							))}
						</React.Fragment>
					))}
				</tbody>
			</table>
		);
	}



	return (
		<div className="container-fluid">
			<h1>Athlete Duplicates</h1>
			<p>Running this report is a highly intensive process and can take up to several minutes to complete. It is recommended you do not run this report while the application is in heavy use.</p>
			<button className="btn btn-primary" onClick={() => getDuplicatesReport()} disabled={(loading) ? true : false}>{(loading) ? 'Generating report. This may take several minutes...' :'Run Duplicate Report'}</button>
			{console.log('duplicates', Object.entries(duplicates).length)}
			{(Object.entries(duplicates).length > 0) &&
				<div className="mt-3">
					{generateTable()}
				</div>
			}
			{(showModal !== false) ? <MergeModalDialog />:''}
		</div>
	);
}

export default withRouter(AthleteDuplicateList);
