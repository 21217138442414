import instance from './config';

// Public routes
const downloadMyEntries = (statuses = []) => {
	const statusQuery = statuses.join('&status[]=');
	return instance.get(`/entries/complete?status[]=${statusQuery}`);
	// return instance.get('/entries/complete');
};

export {
	downloadMyEntries,
}
