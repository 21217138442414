import { DELETE_CART_ITEM, CART, CLEAR_CART } from '../actions/cartActions';

const cartReducer = (state = {items: []}, action) => {
	switch (action.type) {
		case CART:
			return {
				...state,
				items: {
					...state.items,
					...action.payload
				}
			};

		case CLEAR_CART:
			return {
				...state,
				items: {},
			};

		case DELETE_CART_ITEM:
			const { [action.payload]: _, ...updatedItems } = state.items;
			return {
				...state,
				items: updatedItems,
			};
		default:
			return state;
	}
};

export default cartReducer;
