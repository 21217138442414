import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { addCountry } from '../../../api/countries';

const CountriesSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(false);
	const [country, setCountry] = useState(null);

	const getInitialData = (id) => {
		fetch(`${API_URL}/admin/countries/${id}`, {
			headers: {
				// auth url
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
			.then((response) => response.json())
			.then((data) => {
				setCountry(data.country);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	useEffect(() => {
		// getEventTypes().finally(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		}
		// });
	}, []);

	const CountriesForm = () => {
		// get object from eventTypeList that matches the id of event.event_type

		// if entry is an object
		const initialValues = {
			code: (country && country.code) ? country.code : '',
			country: (country && country.amount) ? country.amount : '',
		};

		const validationSchema = Yup.object().shape({
			code: Yup.string().required('Required'),
			country: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			(async () => {
				try {
					const saveResponse = await addCountry(data);
					if (saveResponse.data.status === 'success') {
						setLoading(false);
						setSubmitting(false);
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "country saved successfully!", autoClose: 5000 });

						window.location.href = `/admin/countries`;
					}
				} catch (error) {
					console.log('error');
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				}
			})();
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							// const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type) : null;

							return (
								<Form>
									<div className="row">
										<div className="col-12">
											<div className="form-group-flex">
												<label htmlFor="username">Country Code:</label>
												<Field type="text" className="form-control" name="code" />
											</div>
										</div>
										<div className="col-12">
											<div className="form-group-flex">
												<label htmlFor="event_code">Country:</label>
												<Field type="text" name="country" className="form-control" />
											</div>
										</div>
									</div>

									<hr />

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/countries"><FontAwesomeIcon icon={faArrowLeft} /> Back to Countries</Link></div>
				<div className="col-12 col-md-6">
					{(country) ? <h1>Edit Country</h1> : <h1>Add Country</h1>}
				</div>

				<div className="col-12 form-group">
					{<CountriesForm />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(CountriesSingle);
