import instance from './config';

const getCommentatorRaceList = (page, day, sortDir, sortCol) => {
	return instance.get(`/admin/commentators/races/${day}?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getCommentatorCrew = (id, params = null) => {
	if (params) {
		params = `?${params}`;
	}
	return instance.get(`/admin/commentators/crew/${id}${params}`);
};

const getCommentatorPrintDoc = (race_id) => {
	return instance.get(`/admin/commentators/print/${race_id}`);
}

const getCommentatorPrintDocAll = (day) => {
	return instance.get(`/admin/commentators/print-all/${day}`);
}

export {
	getCommentatorRaceList,
	getCommentatorCrew,
	getCommentatorPrintDoc,
	getCommentatorPrintDocAll
}

