import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../common/with-router';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select'
import Loading from '../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import Declaration from '../Declaration';
import { toast } from 'react-toastify';
import { checkCoupon } from '../../api/coupons';
import { countryListAlpha2, USStates } from '../../utils/Helpers';
import { getPayment, completeBasket } from '../../api/payments';
import { getCart, removeFromCart } from '../../api/cart';

const Basket = (props) => {
	const PAYMENT_URL = process.env.REACT_APP_OPAYO_URL;

	const formRef = useRef(null);

	// get user
	const user = useSelector((state) => state.user);

	const [loading, setLoading] = useState(false);
	const [declaration, setDeclaration] = useState(false);
	const [modal, setModal] = useState(false);
	const [order, setOrder] = useState(false);
	const [totals, setTotals] = useState(false);
	const [cart, setCart] = useState({});
	const [paymentKey, setPaymentKey] = useState(false);
	const [message, setMessage] = useState(false);
	const [coupon, setCoupon] = useState(false);
	// if crypt parameter exists, then it is a payment response. Send it to backend.
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const crypt = searchParams.get('crypt');

	const navigate = useNavigate();

	const getInitialData = async () => {
		try {
			const getCartItemsRes = await getCart();
			if (getCartItemsRes.data.status === 'success') {
				setCart(getCartItemsRes.data.cartItems);
				setTotals(getCartItemsRes.data.cartTotal);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, []);

	const paymentResponse = async () => {
		try {
			const paymentResponseRes = await getPayment();
			setLoading(false);

			if (paymentResponseRes.data.status === 'success') {
				setMessage(paymentResponseRes.data.message);
				setDeclaration(paymentResponseRes.data.order.declaration);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		if (crypt) {
			setLoading(true);
			paymentResponse();
		}
	}, [crypt]);

	useEffect(() => {
		if (formRef.current) {
			formRef.current.submit();
		}
	}, [paymentKey]);

	const handleDelete = (id) => {
		const deleteAlert = window.confirm('Are you sure you want to remove this entry from the cart?', 'Yes', 'No');
		if ( deleteAlert ) {
			(async () => {
				const deleteCartItemRes = await removeFromCart(id);
				if (deleteCartItemRes.data.status === 'success') {
					setCart(deleteCartItemRes.data.cartItems);
					setTotals(deleteCartItemRes.data.cartTotal);
				}
			})();
		}
	}

	const getTotals = () => {
		let total = 0;
		Object.entries(cart).forEach((item) => {
			total += item[1]?.price;

			if (coupon && coupon.type === 1) {
				total -= (total * coupon.amount / 100);
			} else if (coupon && coupon.type !== 1) {
				total -= coupon.amount;
			}
		});

		return {
			total: total.toFixed(2),
			totalExVat: (total / 1.2).toFixed(2),
		}
	}

	const getCartContents = () => {
		return Object.entries(cart).map((item, index) => {
			return (
				<tr key={index}>
					<td><button onClick={() => handleDelete(item[1].id)} className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button></td>
					<td><Link to={`/entries/edit/${item[1].entry_id}`}>{item[1]?.entry?.crew?.crew_name}</Link></td>
					<td>{item[1]?.entry?.event?.name}</td>
					<td>
						{item[1]?.discounted_price !== undefined && item[1]?.discounted_price !== null ? (
							<>
								<s>&pound;{item[1]?.price?.toFixed(2)}</s>
								&nbsp;&pound;{item[1]?.discounted_price.toFixed(2)}
							</>
						) : (
							<>&pound;{item[1]?.price?.toFixed(2)}</>
						)}
					</td>
				</tr>
			);
		});
	}

	const resetCoupon = async () => {
		setCoupon(false);
		getInitialData();
		toast('Coupon removed', { type: 'success', autoClose: 5000 });
	}

	const DoPaymentModule = () => {
		const initialValues = {
			paymentData: {
				BillingFirstnames: user.user.first_name,
				BillingSurname: user.user.last_name,
				BillingAddress1: null,
				BillingCity: null,
				BillingPostCode: null,
				BillingCountry: null,
				BillingState: null,
				DeliveryFirstnames: user.user.first_name,
				DeliverySurname: user.user.last_name,
				DeliveryAddress1: null,
				DeliveryCity: null,
				DeliveryPostCode: null,
				DeliveryCountry: null,
				DeliveryState: null,
			}
		};

		const validationSchema = Yup.object({
			paymentData: Yup.object({
				BillingFirstnames: Yup.string().required('Required'),
				BillingSurname: Yup.string().required('Required'),
				BillingAddress1: Yup.string().required('Required'),
				BillingCity: Yup.string().required('Required'),
				BillingPostCode: Yup.string().required('Required'),
				BillingCountry: Yup.string().required('Required'),
				BillingState: Yup.string().when('BillingCountry', {
					is: 'US',
					then: () => Yup.string().required('Required'),
					otherwise: () => Yup.string().notRequired(),
				}),
			})
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);

			const paymentDataObj = {
				...values,
				// basket: cart,
				declaration: declaration,
				coupon: coupon,
			};

			(async () => {
				const toastId = toast('Fetching payment information...', { type: 'info', autoClose: false });

				try {
					const paymentReponse = await getPayment(paymentDataObj);
					if (paymentReponse.data.status === 'success') {
						console.log('Success:', paymentReponse.data);
						setLoading(false);
						setPaymentKey(paymentReponse.data);
						setSubmitting(false);
						toast.update(toastId, { render: 'Redirecting. Please wait...', type: 'success', autoClose: 5000 });
					}
				} catch (error) {
					toast.update(toastId, { render: 'Error fetching payment information. Please contact support.', type: 'error', autoClose: 5000 });
				}
			})();

			// fetch(`${API_URL}/payment`, {
			// 	method: 'POST',
			// 	headers: {
			// 		// auth url
			// 		'Content-Type': 'application/json',
			// 		'Authorization': `Bearer ${token}`,
			// 	},
			// 	body: JSON.stringify(paymentDataObj),
			// })
			// 	.then((response) => response.json())
			// 	.then((data) => {
			// 		console.log('Success:', data);
			// 		setLoading(false);
			// 		setPaymentKey(data);
			// 		setSubmitting(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Error:', error);
			// 	});
		};

		return (
			<div className="container">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ values, setFieldValue, isValid, isSubmitting, errors }) => (
						<Form>
							<div>
								<h2>Billing Details</h2>
								{console.log('ERRORS', errors)}
								<div>
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="form-group">
												<label htmlFor="BillingFirstnames">First Name<span className="required">*</span></label>
												<Field type="text" name="paymentData[BillingFirstnames]" onChange={(e) => {
														setFieldValue('paymentData[BillingFirstnames]', e.target.value);
														setFieldValue('paymentData[DeliveryFirstnames]', e.target.value);
													}}
													className="form-control"
												/>
												<ErrorMessage name="paymentData[BillingFirstnames]" component="div" className="field-error" />
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<label htmlFor="BillingSurname">Last Name<span className="required">*</span></label>
												<Field type="text" name="paymentData[BillingSurname]" onChange={(e) => {
														setFieldValue('paymentData[BillingSurname]', e.target.value);
														setFieldValue('paymentData[DeliverySurname]', e.target.value);
													}}
													className="form-control"
												/>
												<ErrorMessage name="paymentData[BillingSurname]" component="div" className="field-error" />

											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label htmlFor="BillingAddress1">Address 1<span className="required">*</span></label>
												<Field type="text" name="paymentData[BillingAddress1]" onChange={(e) => {
														setFieldValue('paymentData[BillingAddress1]', e.target.value);
														setFieldValue('paymentData[DeliveryAddress1]', e.target.value);
													}}
													className="form-control"
												/>
												<ErrorMessage name="paymentData[BillingAddress1]" component="div" className="field-error" />

											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<label htmlFor="BillingCity">City<span className="required">*</span></label>
												<Field type="text" name="paymentData[BillingCity]" onChange={(e) => {
														setFieldValue('paymentData[BillingCity]', e.target.value);
														setFieldValue('paymentData[DeliveryCity]', e.target.value);
													}}
													className="form-control"
												/>
												<ErrorMessage name="paymentData[BillingCity]" component="div" className="field-error" />

											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group">
												<label htmlFor="BillingCountry">Country<span className="required">*</span></label>
												<Select name="paymentData[BillingCountry]" options={countryListAlpha2} onChange={(e) => {
													setFieldValue('paymentData[BillingCountry]', e.value);
													setFieldValue('paymentData[DeliveryCountry]', e.value);
												}} />
											</div>
										</div>
										{(values.paymentData.BillingCountry === 'US') &&
											<div className="col-12 col-md-6">
												<div className="form-group">
													<label htmlFor="BillingState">County/State<span className="required">*</span></label>
													<Select name="paymentData[BillingState]" options={USStates} onChange={(e) => {
															setFieldValue('paymentData[BillingState]', e.value);
															setFieldValue('paymentData[DeliveryState]', e.value);
														}}
													/>
													<ErrorMessage name="paymentData[BillingState]" component="div" className="field-error" />
													<ErrorMessage name="paymentData[DeliveryState]" component="div" className="field-error" />
												</div>
											</div>
										}
										<div className="col-12 col-md-6">
											<div className="form-group">
												<label htmlFor="BillingPostCode">Zip/Postcode<span className="required">*</span></label>
												<Field type="text" name="paymentData[BillingPostCode]" onChange={(e) => {
														setFieldValue('paymentData[BillingPostCode]', e.target.value);
														setFieldValue('paymentData[DeliveryPostCode]', e.target.value);
													}}
													className="form-control"
												/>
												<ErrorMessage name="paymentData[BillingPostCode]" component="div" className="field-error" />
											</div>
										</div>
										
										<div className="col-12 text-end">
											<p><strong>Note:</strong> You will be securely redirected to Sagepay/Opayo to complete your transaction.</p>
												<button
													className="btn btn-primary btn-block"
													type="submit"
													disabled={(isSubmitting)}
												>
													{isSubmitting && (
														<span className="spinner-border spinner-border-sm"></span>
													)}
													<span>Make Payment</span>
												</button>
										</div>
									</div>
								</div>
								<Field type="hidden" name="paymentData[DeliveryFirstnames]" />
								<Field type="hidden" name="paymentData[DeliverySurname]" />
								<Field type="hidden" name="paymentData[DeliveryAddress1]" />
								<Field type="hidden" name="paymentData[DeliveryCity]" />
								<Field type="hidden" name="paymentData[DeliveryPostCode]" />
								<Field type="hidden" name="paymentData[DeliveryCountry]" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		);
	}

	const processCompleteBasket = async () => {
		const toastId = toast('Completing basket...', { type: 'info', autoClose: false });
		try {
			const data = {
				declaration: declaration,
				coupon: coupon,
			}
			const completeBasketRes = await completeBasket(data);
			if (completeBasketRes.data.status === 'success') {
				// redirect to payment complete
				toast.update(toastId, { render: 'Basket completed. Redirecting...', type: 'success', autoClose: 5000 });
				navigate(`/payment/confirmation`, { state: { order: completeBasketRes.data.order } });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: 'Error completing basket. Please contact support.', type: 'error', autoClose: 5000 });
		}

		// fetch(`${API_URL}/basket/complete`, {
		// 	method: 'POST',
		// 	headers: {
		// 		// auth url
		// 		'Content-Type': 'application/json',
		// 		'Authorization': `Bearer ${token}`,
		// 	},
		// 	body: JSON.stringify({ basket: cart, declaration: declaration, coupon: coupon }),
		// })
		// 	.then((response) => response.json())
		// 	.then((data) => {
		// 		setLoading(false);
		// 		if (data.status === "success") {
		// 			console.log(data);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.error('Error:', error);
		// 	});
	}

	// const DoCompleteModule = () => {
	// 	return (
	// 		<button onClick={() => completeBasket()} className="btn btn-primary">Complete</button>
	// 	);
	// }

	const Coupon = () => {
		const initialValues = {
			coupon_code: '',
		};

		const validationSchema = Yup.object({
			coupon_code: Yup.string().required('Required'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			setSubmitting(true);
			const checkCouponNotice = toast('Applying coupon code...', {type: 'info', autoClose: false});

			try {
				const checkCouponCode = await checkCoupon(values.coupon_code);

				if (checkCouponCode.data.status === "success") {
					toast.update(checkCouponNotice, {render: 'Coupon code applied', type: 'success', autoClose: 5000});
					setCoupon(checkCouponCode.data.coupon);
					setCart(checkCouponCode.data.cartItems);
					setTotals(checkCouponCode.data.cartTotal);
				}
			} catch (error) {
				toast.update(checkCouponNotice, { render: 'Coupon is invalid', type: 'error', autoClose: 5000 });
			}
		}

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ values, setFieldValue, isValid }) => (
					<Form>
						<div className="d-flex">
							<div style={{ flexBasis: '65%' }}>
								<Field type="text" name='coupon_code' className="form-control" placeholder="Coupon Code" />
							</div>
							<div style={{ flexBasis: '35%' }}>
								<button
									className="btn btn-primary btn-block"
									type="submit"
									disabled={(loading)}
								>Apply Coupon</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		);
	}

	if (loading) {
		return <Loading />;
	}

	const modalShow = (modal) ? ' show' : '';

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Basket</h1>
					{(message) && (
						<div className="alert alert-danger" role="alert">
							{message}
						</div>
					)}
					{(Object.entries(cart).length) ?
						<div className="row">
							<div className="col-12">
								<table className="table table-striped align-middle">
									<thead>
										<tr>
											<th scope="col" width="50px"></th>
											<th scope="col">Crew Name</th>
											<th scope="col">Event</th>
											<th scope="col">Price</th>
										</tr>
									</thead>
									<tbody>
										{getCartContents()}
									</tbody>
								</table>
							</div>
							<div className="col-12 col-md-7">
								<Link to="/add-entry" className="btn btn-primary">Add Another Entry</Link>
							</div>
							<div className="col-12 col-md-5 text-end">
								{<Coupon />}
							</div>
							<div className="col-12 col-md-4 mt-4 offset-md-8 text-end">
								<table className="table">
									<tbody>
										{(coupon) && (
											<tr>
												<td><strong>Coupon ({coupon.code}): </strong></td>
												<td>
													{(coupon.type === 1) ?
														<span>{coupon.amount}% OFF</span> :
														<span>-&pound;{coupon.amount}</span>
													}
													<br/><button onClick={() => resetCoupon()} className="btn btn-delete">Remove Coupon</button>
												</td>
											</tr>
										)}
										<tr>
											<td><strong>Total (ex VAT):</strong></td>
											<td>&pound;{getTotals().totalExVat}</td>
										</tr>
										<tr>
											<td><strong>Total:</strong></td>
											<td>&pound;{getTotals().total}</td>
										</tr>
									</tbody>
								</table>
								{!declaration && <button onClick={() => setModal(true)} className="btn btn-primary">Sign Declaration</button>}
							</div>
							{console.log('TOTALS', totals)}
							{
								declaration
									? (totals && totals !== "0"
										? <DoPaymentModule />
										: <button onClick={() => processCompleteBasket(coupon, cart)} className="btn btn-primary">Complete</button>
									)
									: null
							}
							{paymentKey.crypt && 
								<div>
									<form ref={formRef} method="POST" action={PAYMENT_URL}>
										<input type="hidden" name="VPSProtocol" value="4.00" />
										<input type="hidden" name="TxType" value="PAYMENT" />
										<input type="hidden" name="Vendor" value="henleyroyalrega" />
										<input type="hidden" name="Crypt" value={paymentKey.crypt} />
									</form>
								</div>
							}
							
						</div> : <>
							<div className="alert alert-info">No items in your basket</div>
							<Link to="/add-entry" className="btn btn-primary">Add Entry</Link>
						</>
					}

					<div className={`modal fade${modalShow}`} style={(modalShow) ? {display: 'block'} : {}}>
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<h3 className="modal-title">Sign Declaration</h3>
									<button type="button" className="btn btn-close" onClick={() => setModal(false)}><FontAwesomeIcon icon={faTimes} /></button>
								</div>
								<div className="modal-body">
									<Declaration setModal={setModal} cart={cart} setDeclaration={setDeclaration} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Basket);
