import instance from './config';

const getWeighingList = (page = 1, sortDir = 'asc', sortCol = 'id') => {
	return instance.get(`/admin/weighinglist?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const editBTCrew = (id, data) => {
	return instance.put(`/admin/boattent/crews/${id}`, data);
}

export {
	getWeighingList,
	editBTCrew
}
