import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCountries, deleteCountry } from '../../../api/countries';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const CountriesList = (props) => {
	const [loading, setLoading] = useState(true);
	const [countries, setCountries] = useState({});
	const [filters, setFilters] = useState({ status: null, selected: null, event: null });
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('country');

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const response = await getCountries(page, sortDirection, sortedColumn);
			if (response.data.status === 'success') {
				setCountries(response.data.countries);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [filters, page, sortDirection, sortedColumn]);

	const handleSearch = (newSearchTerm) => {
		setSearchTerm(newSearchTerm);
		// Now you can use `searchTerm` to filter your table data, make API calls, etc.
	};

	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item? Please note this action should NOT be done during a LIVE Regatta registration. This application may crash or have issues with entry relationships as a result.")) {
			try {
				const deleteResponse = await deleteCountry(id);

				if (deleteResponse.data.status === 'success') {
					setCountries(prevData => prevData.filter(item => item.code !== id));
				}
			} catch (error) {
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(countries).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.code,
				code: value.code,
				country: value.country,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Countries <Link to="/admin/countries/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} />
				</div>

				<div className="col-12 form-group">
					<div>
						{(countries.length) ?
							<Table
								data={columns()}
								visibleColumns={[
									{
										name: 'country',
										sort: 'country'
									},
									{
										name: 'code',
										sort: 'code'
									},
								]}
								onSort={handleSort}
								sortedColumn={sortedColumn}
								sortDirection={sortDirection}
								// linkColumn="country"
								linkLocation="/admin/countries/edit"
								editable={false}
								handleDelete={handleDeleteItem}
							/> : <div className="alert alert-danger">No countries found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(CountriesList);
