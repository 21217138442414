import instance from './config';

const getAdminDashboard = () => {
	return instance.get(`/admin/dashboard`);
};

const getClubsReport = () => {
	return instance.get(`/admin/reports/clubs`);
};

const getEntriesReport = () => {
	return instance.get(`/admin/reports/entries`);
};

const getQualificationResultsReport = () => {
	return instance.get(`/admin/reports/qualification-results`);
};

const getCrewNumberingReport = () => {
	return instance.get(`/admin/reports/crew-numbering`);
};

const getReport = (report, type, options) => {
	// generate params for options except .format
	const params = {};
	Object.keys(options).forEach((key) => {
		if (key !== 'format') {
			params[key] = options[key];
		}
	});

	return instance.get(`/admin/reports/${report}/${type}`, {
		params: {
			...params,
		}
	});
}

export {
	getAdminDashboard,
	getClubsReport,
	getEntriesReport,
	getQualificationResultsReport,
	getCrewNumberingReport,
	getReport
}
