import instance from './config';

const getNgbs = (page = 1, sortDir = 'asc', sortCol = 'id') => {
	return instance.get(`/admin/ngbs?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};


const doNgbApprovals = (data) => {
	return instance.post('/ngb/approval', data, {
		headers: {
			'Authorization': undefined,
		}
	});
};

export {
	doNgbApprovals,
	getNgbs
}
