import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import Table from '../../../components/Table';
// import EntryFilter from '../../../components/EntryFilter';
// import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import { getCommentatorCrew } from '../../../api/commentators';
import LogTable from '../../../components/LogTable';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const CommentatorSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	// get the entry id from the url
	const entryId = props.router.params.id;

	// get ?race_id= from url
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const raceId = searchParams.get('race_id');
	const station = searchParams.get('station');

	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState({});
	const [race, setRace] = useState({});

	const days = {
		"Tue": '#ed9a9a',
		"Weds": '#bc9bff',
		"Thurs": '#c5e0b3',
		"Fri": '#a8abaa',
		"Sat": '#FCE599',
		"Sun": '#BDD7EE',
	};

	const getInitialData = async () => {
		let params = '';
		if (raceId && station) {
			params = 'race_id=' + raceId + '&station=' + station;
		}

		try {
			const response = await getCommentatorCrew(entryId, params);
			if (response.data.status === "success") {
				setEntry(response.data.entry);
				setRace(response.data.race);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getInitialData()
	}, []);

	const commentatorForm = () => {
		// if entry is an object
		// console.log('entry', entry);
		const initialValues = {
			athlete: entry.crew?.crew_members?.reduce((acc, item) => {
				if (item.athletes ) {
					const athleteId = item.athletes.id;

					return {
						...acc,
						[athleteId]: {
							sporting_highlights: item.athletes?.sporting_highlights,
							job_course_university: item.athletes?.job_course_university,
							other_info: item.athletes?.other_info,
						},
					};
				}

				return acc;
			}, {}),
			coach_bio: entry.crew?.coach?.bio,
			supplementary_info: entry.supplementary_info,
			commentator_nuggets: entry.commentator_nuggets,
			commentators: entry.crew.commentator_notes,
		};

		console.log('initialValues', initialValues);

		const validationSchema = Yup.object().shape({
			// athletes: Yup.array()
			// 	.of(
			// 		Yup.object().shape({
			// 			position: Yup.string().required('Required'),
			// 			club: Yup.string().required('Required'),
			// 			first_name: Yup.string().required('Required'),
			// 			last_name: Yup.string().required('Required'),
			// 			dob: Yup.string().required('Required'),
			// 			previously_rowed: Yup.string().required('Required'),
			// 		})
			// 	)
			// 	.required('Must have athletes'),
			// emergency_contact: Yup.string().required('Required'),
			// emergency_contact_name: Yup.string().required('Required'),
			coach_bio: Yup.string().notRequired(),
			supplementary_info: Yup.string().notRequired(),
			commentator_nuggets: Yup.string().notRequired(),
		});

		const onSubmit = (values, { setSubmitting }) => {
			// add toast
			const toastId = toast("Saving...", { autoClose: false });
			setSubmitting(true);

			fetch(`${API_URL}/admin/commentators/crew/${entry.id}`, {
				method: 'PUT',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('Success:', data);
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Saved!", autoClose: 5000 });
				})
				.catch((error) => {
					console.error('Error:', error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });
				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid }) => (
							<Form>
								<div>
									<table className="table table-striped align-middle">
										<thead>
											<tr>
												<th>Pos</th>
												<th>Name</th>
												<th>Age</th>
												<th>Prev HRRs</th>
												<th>Highlights - Job/Education - Further Info</th>
											</tr>
										</thead>
										<tbody>
											{Object.values(entry.crew.crew_members).map((itm, ind) => {
												return (
													<tr key={ind}>
														<td>
															{itm.position}
														</td>
														<td>
															{itm.athletes?.first_name} {itm.athletes?.last_name}
														</td>
														<td>
															{itm.athletes?.age}
														</td>
														<td>
															{/* {itm.athletes.previously_rowed} */}
														</td>
														<td>
															<Field name={`athlete[${itm.athletes?.id}].sporting_highlights`} component="textarea" className="form-control" />
															<Field name={`athlete[${itm.athletes?.id}].job_course_university`} component="textarea" className="form-control" />
															<Field name={`athlete[${itm.athletes?.id}].other_info`} component="textarea" className="form-control" />
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
									<table className="table align-middle">
										<tbody>
											<tr>
												<td width="200px">
													Coach Name
												</td>
												<td>
													{(entry.crew?.coach?.user_id) ? entry.crew?.coach?.user?.first_name + ' ' + entry.crew?.coach?.user?.last_name : entry.crew?.coach?.first_name + ' ' + entry.crew?.coach?.last_name}
												</td>
											</tr>
											<tr>
												<td>
													Coach Bio
												</td>
												<td>
													<Field name="coach_bio" component="textarea" className="form-control" />
												</td>
											</tr>
											<tr>
												<td>
													Supplementary Info
												</td>
												<td>
													<Field name="supplementary_info" component="textarea" className="form-control" />
												</td>
											</tr>
											<tr>
												<td>
													Commentator Nuggets
												</td>
												<td>
													<Field name="commentator_nuggets" component="textarea" className="form-control" />
												</td>
											</tr>
											{Object.entries(days).map((day, index) => {
												const dayLower = day[0].toLowerCase();
												return (
													<tr key={index}>
														<td style={{ backgroundColor: day[1] }}>
															{day[0]}
														</td>
														<td>
															<Field name={`commentators[${dayLower}]`} component="textarea" className="form-control" />
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>

								<div className="form-group form-buttons">
									<button
										className="btn btn-primary btn-block"
										type="submit"
										disabled={(loading)}
									>
										{loading && (
											<span className="spinner-border spinner-border-sm"></span>
										)}
										<span>Save</span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loading />;
	}

	console.log('CREW', entry.crew);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-12">
					<table className="table table-borderless align-middle">
						<tbody>
							<tr>
								<td>{race?.day}</td>
								<td></td>
								<td>{race?.race}</td>
								<td className="text-center"><h3>{entry.event.name}</h3></td>
								<td className="text-end">{race?.start_time}</td>
							</tr>
							<tr>
								<td>{(race?.berks_id === entryId) ? <span style="color:#1E6600">2, Bucks</span> :<span style={{color: '#C00000'}}>1, Berks</span>}</td>
								<td style={{ color: '#1E14FF' }}>{(entry.qualification_status === 1) ? 'Q' : (entry.qualification_status === 2) ? 'T' : ''}</td>
								<td style={{ color: '#1E14FF' }}>{entry.crew.crew_number}</td>
								<td className="text-center"><h3 style={{ color: '#1E14FF' }}>{(entry.selected && entry.selected !== "0") ? <i>{entry.crew.full_name_country} ({entry.crew.crew_number})</i> : <>{entry.crew.full_name_country} ({entry.crew.crew_number})</>}</h3></td>
								<td className="text-end">{entry.crew?.crew_members[0]?.athletes?.club?.colours}</td>
							</tr>
							{/* <tr>
								<td className="text-start" width="33.3333%"></td>
								<td className="text-center" width="33.3333%"><h3>{entry.event.name}</h3></td>
								<td className="text-end" width="33.3333%"></td>
							</tr>
							<tr>
								<td className="text-start"></td>
								<td className="text-center"><h3>{entry.crew.crew_name}</h3></td>
								<td className="text-end">{entry.crew?.crew_members[0]?.athletes?.club?.colours}</td>
							</tr> */}
						</tbody>
					</table>
					{(entry) && commentatorForm()}
					{(entry && entry.id) ? <LogTable entryId={entry.id} /> : null}
				</div>
			</div>
		</div>
	);
}

export default withRouter(CommentatorSingle);
