const eventsReducer = (state = null, action) => {
	switch (action.type) {
		case "EVENTS":
			return {
				events: action.payload
			};
		default:
			return state;
	}
};

export default eventsReducer;
