import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const OfflineStatusChecker = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	const handleOnlineStatusChange = () => {
		setIsOnline(navigator.onLine);
	};

	useEffect(() => {
		// Add event listeners to detect online/offline status changes
		window.addEventListener('online', handleOnlineStatusChange);
		window.addEventListener('offline', handleOnlineStatusChange);

		// Clean up the event listeners when the component is unmounted
		return () => {
			window.removeEventListener('online', handleOnlineStatusChange);
			window.removeEventListener('offline', handleOnlineStatusChange);
		};
	}, []);

	useEffect(() => {
		// Show a toast when the user goes offline
		if (!isOnline) {
			toast.error('You are offline. Some features may not be available.', {
				autoClose: false, // Disable autoClose
				closeButton: false
			});
		} else {
			// Remove the toast when the user comes back online
			toast.dismiss();
			// toast.success('You are back online! You can now use all features.');
		}
	}, [isOnline]);

	return null;
};

export default OfflineStatusChecker;
