import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getCountries } from '../../../api/countries';
import { adminGetClub } from '../../../api/clubs';

const ClubSingle = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [club, setClub] = useState(null);
	const [countries, setCountries] = useState(null);

	const getCountriesData = async () => {
		try {
			const getAllCountries = await getCountries();
			if (getAllCountries.data.status === 'success') {
				getAllCountries.data.countries.forEach((item) => {
					item.label = item.country;
					item.value = item.code;
				});

				setCountries(getAllCountries.data.countries);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getInitialData = async (id) => {
		await getCountriesData();

		try {
			const getClubRes = await adminGetClub(id);
			
			if (getClubRes.data.status === 'success') {
				setClub(getClubRes.data.club);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		} else {
			getCountriesData();
			setLoading(false);
		}
	}, []);

	const clubForm = () => {
		// get object from eventTypeList that matches the id of event.event_type

		// if entry is an object
		const initialValues = {
			name: (club && club.name) ? club.name : '',
			short_name: (club && club.short_name) ? club.short_name : '',
			alpha_code: (club && club.alpha_code) ? club.alpha_code : '',
			colours: (club && club.colours) ? club.colours : '',
			blades: (club && club.blades) ? club.blades : '',
			address_1: (club && club.address_1) ? club.address_1 : '',
			address_2: (club && club.address_2) ? club.address_2 : '',
			postcode_zip: (club && club.postcode_zip) ? club.postcode_zip : '',
			town: (club && club.town) ? club.town : '',
			state: (club && club.state) ? club.state : '',
			// contact
			contact: (club && club.contact) ? club.contact : '',
			tel_day: (club && club.tel_day) ? club.tel_day : '',
			tel_eve: (club && club.tel_eve) ? club.tel_eve : '',
			mobile: (club && club.mobile) ? club.mobile : '',
			email: (club && club.email) ? club.email : '',
			secondary_email: (club && club.secondary_email) ? club.secondary_email : '',
			country_code: (club && club.country_code) ? club.country_code : '',
			// misc
			notes: (club && club.notes) ? club.notes : '',
			hold: (club && club.hold) ? true : false,
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required('Required'),
			short_name: Yup.string().required('Required'),
			alpha_code: Yup.string().required('Required'),
			colours: Yup.string().required('Required'),
			address_1: Yup.string().required('Required'),
			email: Yup.string().required('Required'),
			country_code: Yup.string().required('Required'),
		});

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			if (props.router?.params?.id) {
				data.id = props.router?.params?.id;
			}

			const endpoint = (data.id) ? `${API_URL}/admin/clubs/${data.id}` : `${API_URL}/admin/clubs`;
			fetch(endpoint, {
				method: (data.id) ? 'PUT' : 'POST',
				headers: {
					// auth url
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then((res) => {
					setLoading(false);
					setSubmitting(false);
					toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Club saved successfully!", autoClose: 5000 });

					if (res.id && !data.id) {
						window.location.href = `/admin/clubs/edit/${res.id}`;
					}
				})
				.catch((error) => {
					console.error('Error:', error);
					toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving. Please contact support.", autoClose: 5000 });

				});
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						// validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							// const eventType = (values.event_type) ? eventTypes.find((item) => item.id === values.event_type) : null;

							return (
								<Form>
									<div className="form-group-flex">
										<label htmlFor="username">Club Name:</label>
										<Field type="text" className="form-control" name="name" />
										<ErrorMessage name="name" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Club Short Name</label>
										<Field type="text" name="short_name" className="form-control" />
										<ErrorMessage name="short_name" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Club Alpha Code</label>
										<Field type="text" name="alpha_code" className="form-control" />
										<ErrorMessage name="alpha_code" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Colours</label>
										<Field type="text" name="colours" className="form-control" />
										<ErrorMessage name="colours" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Blades</label>
										<Field type="text" name="blades" className="form-control" />
										<ErrorMessage name="blades" component="div" className="invalid-feedback" />
									</div>
									<hr />
									<h3>Address</h3>
									<div className="form-group-flex">
										<label htmlFor="event_code">Address 1</label>
										<Field type="text" name="address_1" className="form-control" />
										<ErrorMessage name="address_1" component="div" className="invalid-feedback" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Address 2</label>
										<Field type="text" name="address_2" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Town</label>
										<Field type="text" name="town" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">State</label>
										<Field type="text" name="state" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Zip/Postcode</label>
										<Field type="text" name="postcode_zip" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Country</label>
										<Field component={SelectField} name="country_code" className="form-control" options={countries} />
										<ErrorMessage name="country_code" component="div" className="invalid-feedback" />
									</div>

									<hr />

									<h3>Contact</h3>
									<div className="form-group-flex">
										<label htmlFor="event_code">Contact</label>
										<Field type="text" name="contact" className="form-control" />
									</div>
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Daytime Telephone</label>
												<Field type="text" name="tel_day" className="form-control" />
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Evening Telephone</label>
												<Field type="text" name="tel_eve" className="form-control" />
											</div>
										</div>
									</div>
									<div className="form-group-flex">
										<label htmlFor="event_code">Mobile</label>
										<Field type="text" name="mobile" className="form-control" />
									</div>
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Email</label>
												<Field type="text" name="email" className="form-control" />
												<ErrorMessage name="email" component="div" className="invalid-feedback" />
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group-flex">
												<label htmlFor="event_code">Secondary Email</label>
												<Field type="text" name="secondary_email" className="form-control" />
												<ErrorMessage name="secondary_email" component="div" className="invalid-feedback" />
											</div>
										</div>
									</div>

									<hr/>

									<h3>Misc</h3>
									<div className="form-group-flex">
										<label htmlFor="event_code">Notes</label>
										<Field component="textarea" name="notes" className="form-control" />
									</div>
									<div className="form-group-flex">
										<label htmlFor="hold">On Hold</label>
										<Field type="checkbox" name="hold" className="form-check-input" />
									</div>

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin/>
											)}
											<span>Save</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/clubs"><FontAwesomeIcon icon={faArrowLeft} /> Back to Clubs</Link></div>
				<div className="col-12 col-md-6">
					{(club) ? <h1>Edit Club</h1> : <h1>Add Club</h1>}
				</div>

				<div className="col-12 form-group">
					{clubForm()}
				</div>
			</div>
		</div>
	);
}

export default withRouter(ClubSingle);
