import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { useSelector } from 'react-redux';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getQualifyingGroups, saveQualifyingGroupResults, getQualifyingGroupResults } from '../../../api/qualifying';

const QualifierResults = (props) => {
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState({});
	const [groups, setGroups] = useState(null);
	const [event, setEvent] = useState();

	const getEvents = useSelector((state) => state.events);

	// get first event where has_qualifiers is 1
	useEffect(() => {
		const foundEvent = getEvents.events.find(event => event.has_qualifiers === 1);
		if (foundEvent) {
			setEvent(foundEvent.id);
		}
	}, [getEvents]);

	const getInitialData = async () => {
		try {
			const getQualifyingGroupsRes = await getQualifyingGroups(event);

			if (getQualifyingGroupsRes.data.status === "success") {
				setGroups(getQualifyingGroupsRes.data.events);
				setLoading(false);
			}

			const getQualifyingGroupsResultsRes = await getQualifyingGroupResults();

			if (getQualifyingGroupsResultsRes.data.status === "success") {
				setResults(getQualifyingGroupsResultsRes.data.results);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [event]);

	if (loading) {
		return <Loading />;
	}

	// const saveRowResult = async (rowValues) => {
	// 	const toastId = toast("Saving Result...", { autoClose: false });

	// 	try {
	// 		console.log(rowValues);
	// 		const saveResultRes = await saveQualifyingGroupResult(rowValues);
	// 		if (saveResultRes.data.status === "success") {
	// 			getInitialData();
	// 			toast.update(toastId, { render: "Results Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
	// 		}
	// 	} catch (error) {
	// 		console.log('error', error);
	// 		toast.update(toastId, { render: "Error Saving Result.", type: toast.TYPE.ERROR, autoClose: 5000 });
	// 	}
	// }
	
	const submitTimetable = async (values, { setSubmitting }) => {
	// const submitTimetable = async (values) => {
		setSubmitting(true);
		const toastId = toast("Saving Result...", { autoClose: false });
		console.log(values);
		try {
			const saveResultRes = await saveQualifyingGroupResults(values);
			if (saveResultRes.data.status === "success") {
				getInitialData();
				setSubmitting(false);
				toast.update(toastId, { render: "Results Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving Result.", type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	const parseTime = (timeString) => {
		// Split time string and convert to numbers
		const [minutes, seconds, tenths] = timeString.split(':').map(Number);
		// Convert the entire time to milliseconds accurately
		return (minutes * 60 * 1000) + (seconds * 1000) + (tenths * 100);
	};

	const formatDifference = (difference) => {
		const minutes = Math.floor(difference / (60 * 1000));
		difference -= minutes * 60 * 1000;
		const seconds = Math.floor(difference / 1000);
		difference -= seconds * 1000;
		const tenths = Math.round(difference / 100); // Convert to tenths of a second

		return `${padZero(minutes)}:${padZero(seconds)}:${padZero(tenths, 1)}`;
	};

	const padZero = (number, length = 2) => {
		return number.toString().padStart(length, '0');
	}

	const formatTime = (value) => {
		if (!value) return '';

		// Remove all non-digits and limit string to accommodate MMSSS
		const digits = value.replace(/\D/g, '').substring(0, 6);

		// Initialize parts array to temporarily hold MM, SS, and T
		const parts = [];
		// Extract potential minutes, seconds, and tenths
		const minutes = digits.substring(0, 2);
		const seconds = digits.substring(2, 4);
		const tenths = digits.substring(4, 5);

		// Validate and correct minutes and seconds if necessary
		const correctedMinutes = parseInt(minutes, 10) > 59 ? '59' : minutes;
		const correctedSeconds = parseInt(seconds, 10) > 59 ? '59' : seconds;

		// Reassemble the time parts, checking each segment for existence before adding
		if (correctedMinutes) parts.push(correctedMinutes);
		if (correctedSeconds) parts.push(correctedSeconds);
		if (tenths) parts.push(tenths);

		return parts.join(':');
	};

	const handleChange = (e, setFieldValue, crewId, field, values) => {
		const formattedTime = formatTime(e.target.value);

		// Update the start time for the crew that initiated the change
		setFieldValue(`crews[${crewId}][${field}]`, formattedTime);

		if (field === 'rstart_time' || field === 'rfinish_time') {
			if (formattedTime !== '00:00:0') {
				// work out time difference between start_time and finish_time
				const startTime = (field === 'rstart_time') ? formattedTime:values.crews[crewId].rstart_time;
				const finishTime = (field === 'rfinish_time') ? formattedTime:values.crews[crewId].rfinish_time;
				const diff = Math.abs(parseTime(startTime) - parseTime(finishTime));
				const formattedDiff = formatDifference(diff);
				setFieldValue(`crews[${crewId}].qualifying_time`, formattedDiff);
			}
		}
	};

	const QualifyingGroupForm = () => {
		const initialValues = {
			crews: groups.reduce((crewAcc, group) => {
				group.forEach((crew) => {
					if (crew.crew?.id) {
						const crewresult = Object.values(results).find(item => item.crew_id === crew.crew?.id);
						crewAcc[crew.crew?.id] = {
							crew_id: crew.crew?.id || 0,
							group_num: crew.group_number,
							rstart_time: '00:00:0',
							rfinish_time: '00:00:0',
							qualifying_time: crewresult?.qualifying_time || '00:00:0',
							notes: crewresult?.notes || '',
						};
					}
				});
				return crewAcc;
			}, {})
		};

		const validationSchema = Yup.object().shape({
			crews: Yup.object().shape(
				groups.reduce((crewAcc, group) => {
					group.forEach((crew) => {
						if (crew.crew?.id) {
							crewAcc[crew.crew.id] = Yup.object().shape({
								rstart_time: Yup.string().matches(/^(?:[0-5]?[0-9]):[0-5][0-9]:[0-9]$/, 'Invalid time format (MM:SS:T)'),
								rfinish_time: Yup.string().matches(/^(?:[0-5]?[0-9]):[0-5][0-9]:[0-9]$/, 'Invalid time format (MM:SS:T)'),
								qualifying_time: Yup.string().matches(/^(?:[0-5]?[0-9]):[0-5][0-9]:[0-9]$/, 'Invalid time format (MM:SS:T)').required('Qualifying time is required'),
								notes: Yup.string(),
							});
						}
					});
					return crewAcc;
				}, {})
			)
		});

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submitTimetable}
			>
				{({ values, errors, touched, setFieldValue, isValid }) => {
					return (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Event Qualifying Sort</th>
										<th>Event</th>
										<th>Crew #</th>
										<th>Crew</th>
										<th>Group #</th>
										<th>ST</th>
										<th>FT</th>
										<th>QT</th>
										<th>Notes</th>
										<th></th>
									</tr>
								</thead>
								<FieldArray
									name="crews"
									render={arrayHelpers => (
									<tbody>
										{groups.map((group, index) => (
											<React.Fragment key={index}>
												{group.map((crew, i) => {
													if (crew.break === true || crew.break === false) {
														
													} else {
														return (
															<tr key={`tr${i}`}>
																<td>{crew.crew.event.qsort}</td>
																<td>{crew.crew.event.name}</td>
																<td>{(crew.crew?.crew?.crew_number) ? crew.crew?.crew?.crew_number : 'N/A'}</td>
																<td>{crew.crew?.crew?.crew_name}</td>
																<td>{crew.group_number}</td>
																<td width="110px">
																	<Field
																		name={`crews[${crew.crew.id}].rstart_time`}
																		className="form-control"
																		onClick={(e) => e.target.select()}
																		onChange={e => handleChange(e, setFieldValue, crew.crew.id, 'rstart_time', values)}
																	/>
																	<ErrorMessage name={`crews[${crew.crew.id}].rstart_time`} component="div" className="invalid-feedback" />
																</td>
																<td>
																	<Field
																		name={`crews[${crew.crew.id}].rfinish_time`}
																		className="form-control"
																		onClick={(e) => e.target.select()}
																		onChange={(e) => handleChange(e, setFieldValue, crew.crew.id, 'rfinish_time', values)}
																	/>
																	<ErrorMessage name={`crews[${crew.crew.id}].rfinish_time`} component="div" className="invalid-feedback" />
																</td>
																<td>
																	<Field
																		name={`crews[${crew.crew.id}].qualifying_time`}
																		className="form-control"
																		onClick={(e) => e.target.select()}
																		onChange={(e) => handleChange(e, setFieldValue, crew.crew.id, 'qualifying_time', values)}
																	/>
																	<ErrorMessage name={`crews[${crew.crew.id}].qualifying_time`} component="div" className="invalid" />
																</td>
																<td>
																	<Field
																		component="textarea"
																		name={`crews[${crew.crew.id}].notes`}
																		className="form-control"
																	/>
																</td>
																<td>
																	<Field type="hidden" name={`crews[${crew.crew.id}].crew_id`} value={crew.crew.id} />
																	<Field type="hidden" name={`crews[${crew.crew.id}].type`} value="RACE" />
																	<Field type="hidden" name={`crews[${crew.crew.id}].group_num`} value={crew.group_number} />
																	{/* <button type="button" onClick={() => saveRowResult(values.crews[crew.crew.id])} className="btn btn-primary" tabindex="-1">Save</button> */}
																</td>
															</tr>
														)
													}
												})}
											</React.Fragment>
										))}
								</tbody>
								)}
							/>
							</table>

							<div className="floating-buttons">
								{console.log('errs', errors)}
								<button type="submit" className="btn btn-primary btn-lg" disabled={(errors && errors.crews?.length > 0) ? true:false}>Save</button>
							</div>
						</Form>
					)
				}}
			</Formik>
		)
	};

	const DoEventSelect = () => {
		return (
			<select className="form-control" onChange={(e) => setEvent(e.target.value)}>
				{
					getEvents.events.map((events, index) => {
						if (events.has_qualifiers === 1) {
							return (
								<option key={index} value={events.id} selected={(events.id === parseInt(event)) ? true : false}>{events.name}</option>
							)
						}
					})
				}
			</select>
		)
	}

	return (
		<div className="container-fluid">
			<div className="row align-items-center">
				<div className="col-12 col-md-6">
					<h1>Qualifier Results</h1>
				</div>
				<div className="col-12 col-md-6">
					<DoEventSelect />
				</div>

				{(Object.entries(results).length <= 0 ) && <div className="alert alert-danger">Please generate the timetable before being able to enter results.</div>}
				<div className="col-12 form-group">
					{(groups && groups.length > 0) ? <QualifyingGroupForm /> : <div className="alert alert-danger">No Races Found</div>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(QualifierResults);
