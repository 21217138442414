import React, { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { saveResult, getResultsList } from '../../../api/race';

// import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import SelectField from '../../../components/forms/select';
import { toast } from 'react-toastify';
import { verdictsOpts } from '../../../utils/Helpers';

const RaceResults = () => {
	const [loading, setLoading] = useState(true);
	const [day, setDay] = useState('tues');
	const [timetable, setTimetable] = useState({});

	const getInitialData = async () => {
		try {
			const getTimetableRes = await getResultsList(day);

			if (getTimetableRes.data.status === "success") {
				setTimetable(getTimetableRes.data.timetable);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, [day]);

	const changeDay = (e) => {
		const { value } = e.target;
		setLoading(true);
		setDay(value);
	};

	if (loading) {
		return <Loading />;
	}

	const saveRowResult = async (rowValues) => {
		const toastId = toast("Saving Result...", { autoClose: false });

		try {
			const saveResultRes = await saveResult(rowValues);
			if (saveResultRes.data.status === "success") {
				getInitialData();
				toast.update(toastId, { render: "Result Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving.", type: toast.TYPE.ERROR, autoClose: 2000 });
		}
	}

	// const formatTime = (value) => {
	// 	if (!value) return '';

	// 	// Remove all non-digits and limit string to 5 characters (MM:SST)
	// 	const digits = value.replace(/\D/g, '').substring(0, 5);

	// 	// Initialize parts array to hold MM, SS, and optionally T
	// 	const parts = [];

	// 	// Add minutes (MM)
	// 	if (digits.length >= 2) {
	// 		parts.push(digits.substring(0, 2));
	// 	} else if (digits.length > 0) {
	// 		parts.push(digits.substring(0, digits.length));
	// 	}

	// 	// Add seconds (SS)
	// 	if (digits.length > 2) {
	// 		parts.push(digits.substring(2, 4));
	// 	}

	// 	// Add tenths of a second (T) if present
	// 	if (digits.length === 5) {
	// 		parts.push(digits.substring(4));
	// 	}

	// 	return parts.join(':');
	// };


	// const handleChange = (e, setFieldValue, index, field, values) => {
	// 	const formattedTime = formatTime(e.target.value);

	// 	// Update the start time for the crew that initiated the change
	// 	setFieldValue(`races[${index}][${field}]`, formattedTime);
	// };

	const formatTime = (value) => {
		if (!value) return '';

		// Remove all non-digits and limit string to accommodate MMSSS
		const digits = value.replace(/\D/g, '').substring(0, 5);

		// Initialize parts array to temporarily hold MM, SS, and T
		const parts = [];
		// Extract potential minutes, seconds, and tenths
		const minutes = digits.substring(0, 2);
		const seconds = digits.substring(2, 4);

		// Validate and correct minutes and seconds if necessary
		const correctedMinutes = parseInt(minutes, 10) > 59 ? '59' : minutes;
		const correctedSeconds = parseInt(seconds, 10) > 59 ? '59' : seconds;

		// Reassemble the time parts, checking each segment for existence before adding
		if (correctedMinutes) parts.push(correctedMinutes);
		if (correctedSeconds) parts.push(correctedSeconds);

		return parts.join(':');
	};

	const handleChange = (e, setFieldValue, field) => {
		const formattedTime = formatTime(e.target.value);
		setFieldValue(`${field}`, formattedTime);
	};

	const ResultForm = () => {
		const getRaces = (timetable) ? timetable.filter(item => item.type === 'RACE'):null;
		return (
			<div className="striped">
				{getRaces.map((race, index) => (
					<Formik
						key={race.race}
						initialValues={{
							race: race.race,
							day: day,
							// event: (race.event?.abbr_name) ? race.event?.abbr_name : 'N/A',
							event_id: race.event_id,
							bucks_id: (race.bucks?.id) ? race.bucks?.id : 'N/A',
							bucks: (race.bucks?.crew_name) ? race.bucks?.crew_name : 'N/A',
							berks_id: (race.berks?.id) ? race.berks?.id : 'N/A',
							berks: (race.berks?.crew_name) ? race.berks?.crew_name : 'N/A',
							round: (race.race_result?.round) ? race.race_result?.round : '',
							winner: (race.race_result?.winner) ? String(race.race_result?.winner) : '',
							loser: (race.race_result?.loser) ? String(race.race_result?.loser) : '',
							barrier_time: (race.race_result?.barrier_time) ? race.race_result?.barrier_time : '',
							loser_leading_1: (race.race_result?.loser_leading_1 === "1") ? race.race_result?.loser_leading_1 : '',
							fawley_time: (race.race_result?.fawley_time) ? race.race_result?.fawley_time : '',
							loser_leading_2: (race.race_result?.loser_leading_2 === "1") ? race.race_result?.loser_leading_2 : '',
							finish_time: (race.race_result?.finish_time) ? race.race_result?.finish_time : '',
							verdict: (race.race_result?.verdict) ? race.race_result?.verdict : '',
							second_result: (race.race_result?.second_result === 1) ? true : false,
							winner_2: (race.race_result?.winner_2) ? String(race.race_result?.winner_2) : '',
							loser_2: (race.race_result?.loser_2) ? String(race.race_result?.loser_2) : '',
							barrier_time_2: (race.race_result?.barrier_time_2) ? race.race_result?.barrier_time_2 : '',
							loser_leading_1_2: (race.race_result?.loser_leading_1_2 === "1") ? race.race_result?.loser_leading_1_2 : '',
							fawley_time_2: (race.race_result?.fawley_time_2) ? race.race_result?.fawley_time_2 : '',
							loser_leading_2_2: (race.race_result?.loser_leading_2_2 === "1") ? race.race_result?.loser_leading_2_2 : '',
							finish_time_2: (race.race_result?.finish_time_2) ? race.race_result?.finish_time_2 : '',
							verdict_2: (race.race_result?.verdict_2) ? race.race_result?.verdict_2 : '',
							tweet: (race.race_result?.tweet === 1) ? "1" : "0",
							tweet_text: (race.race_result?.tweet_text) ? race.race_result?.tweet_text : '',
						}}
						validationSchema={Yup.object({
							event_id: Yup.string().required('Required'),
							round: Yup.string().required('Required'),
							// winner: Yup.string().required('Required'),
							// loser: Yup.string().required('Required'),
							barrier_time: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							loser_leading_1: Yup.boolean(),
							fawley_time: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							loser_leading_2: Yup.boolean(),
							finish_time: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							verdict: Yup.string().required('Required'),
							winner: Yup.string().when('verdict', {
								is: (val) => val !== 'dh', // dh represents 'dead heat'
								then: () => Yup.string().test(
									'not-empty-or-zero', // Test name
									'Winner is required', // Error message
									value => value && value !== '0' // Test function to check the value is neither empty nor zero
								).required('Winner is required'), // Ensures the field isn't left empty
								otherwise: () => Yup.string().default('0')
							}),
							loser: Yup.string().when('verdict', {
								is: (val) => val !== 'dh', // dh represents 'dead heat'
								then: () => Yup.string().test(
									'not-empty-or-zero', // Test name
									'Loser is required', // Error message
									value => value && value !== '0' // Test function to check the value is neither empty nor zero
								).required('Loser is required'), // Ensures the field isn't left empty
								otherwise: () => Yup.string().default('0')
							}),
							// barrier_time_2: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							// loser_leading_1_2: Yup.boolean(),
							// fawley_time_2: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							// loser_leading_2_2: Yup.boolean(),
							// finish_time_2: Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
							// verdict_2: Yup.string().required('Required'),
							// if second_result is checked, then the second result fields are required
							second_result: Yup.boolean().notRequired(),
							barrier_time_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
								otherwise: () => Yup.string().notRequired(),
							}),
							loser_leading_1_2: Yup.boolean().notRequired(),
							fawley_time_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
								otherwise: () => Yup.string().notRequired(),
							}),
							loser_leading_2_2: Yup.boolean().notRequired(),
							finish_time_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().required('Required').matches(/^([0-9]{1,2}:[0-9]{2})$/, 'Must be in the format 00:00'),
								otherwise: () => Yup.string().notRequired(),
							}),
							winner_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().test(
									'not-empty-or-zero', // Test name
									'Winner is required', // Error message
									value => value && value !== '0' // Test function to check the value is neither empty nor zero
								).required('Winner is required'), // Ensures the field isn't left empty
								otherwise: () => Yup.string().notRequired()
							}),
							loser_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().test(
									'not-empty-or-zero', // Test name
									'Loser is required', // Error message
									value => value && value !== '0' // Test function to check the value is neither empty nor zero
								).required('Loser is required'), // Ensures the field isn't left empty
								otherwise: () => Yup.string().notRequired()
							}),
							verdict_2: Yup.string().when('second_result', {
								is: true,
								then: () => Yup.string().required('Required'),
								otherwise: () => Yup.string().notRequired(),
							}),
						})}
						onSubmit={saveRowResult}
					>
						{({ values, setFieldValue, errors }) => (
							<Form className="mb-3">
								<>
									<div className="row">
										<div className="col-12 mb-2">
											<div className={`text-light p-1${race?.race_result ? ' bg-success' : ' bg-dark'}`}>Race #: {race.race} - {(race.event?.abbr_name) ? race.event?.abbr_name : 'N/A'}</div>
											<Field name={`race`} type="hidden" className="form-control" disabled />
										</div>
										<div className="col-4 mb-2">
											<label>Round</label>
											<Field
												name={`round`}
												component={SelectField}
												options={[
													{ value: "heats", label: "Heats" },
													{ value: "semi-finals", label: "Semi-finals" },
													{ value: "final", label: "Final" },
												]}
												className="form-control"
											/>
											<ErrorMessage name={`round`} component="div" className="text-danger" />
										</div>
										<div className="col-8">
											<label>Winner</label>
											<div className="row">
												<div className="col-6">
													<div className="form-check">
														<Field
															type="radio"
															name={`winner`}
															value={String(race?.berks_id)}
															disabled={race.verdict === 'dh'}
															// on change, set the loser to the other crew
															onChange={(e) => {
																setFieldValue(`winner`, e.target.value);
																setFieldValue(`loser`, (e.target.value === String(race?.bucks_id)) ? String(race?.berks_id) : String(race?.bucks_id));
															}}
														/>
														<label className="form-check-label">
															{values.berks}
														</label>
													</div>
													<ErrorMessage name={`winner`} component="div" className="text-danger" />
												</div>
												<div className="col-6">
													<div className="form-check">
														<Field
															type="radio"
															name={`winner`}
															value={String(race?.bucks_id)}
															disabled={race.verdict === 'dh'}
															// on change, set the loser to the other crew
															onChange={(e) => {
																setFieldValue(`winner`, e.target.value);
																setFieldValue(`loser`, (e.target.value === String(race?.bucks_id)) ? String(race?.berks_id) : String(race?.bucks_id));
															}}
														/>
														<label className="form-check-label">
															{values.bucks}
														</label>
													</div>
													
													<ErrorMessage name={`winner`} component="div" className="text-danger" />
												</div>
											</div>
										</div>
										<div className="col-2">
											<label>Barrier Time</label>
											<Field name={`barrier_time`} className="form-control" onChange={
												(e) => handleChange(e, setFieldValue, 'barrier_time')
											} />
											<ErrorMessage name={`barrier_time`} component="div" className="text-danger" />
										</div>
										<div className="col text-center">
											<label>Loser Leading</label>
											<Field
												name={`loser_leading_1`}
												type="checkbox"
												value="1"
												checked={values.loser_leading_1 === "1"}
												onChange={(e) => {
													setFieldValue(`loser_leading_1`, e.target.checked ? "1" : "0");
												}}
											/>
											<ErrorMessage name={`loser_leading_1`} component="div" className="text-danger" />
										</div>
										<div className="col-2">
											<label>Fawley Time</label>
											<Field name={`fawley_time`} className="form-control" onChange={
												(e) => handleChange(e, setFieldValue, 'fawley_time')
											} />
											<ErrorMessage name={`fawley_time`} component="div" className="text-danger" />
										</div>
										<div className="col text-center">
											<label>Loser Leading</label>
											<Field
												name={`loser_leading`}
												type="checkbox"
												value="1"
												checked={values.loser_leading_2 === "1"}
												onChange={(e) => {
													setFieldValue(`loser_leading_2`, e.target.checked ? "1" : "0");
												}}
											/>
											<ErrorMessage name={`loser_leading_2`} component="div" className="text-danger" />
										</div>
										<div className="col-2">
											<label>Finish Time</label>
											<Field name={`finish_time`} className="form-control" onChange={
												(e) => handleChange(e, setFieldValue, 'finish_time')
											} />
											<ErrorMessage name={`finish_time`} component="div" className="text-danger" />
										</div>
										<div className="col-2">
											<label>Verdict</label>
											<Field
												name={`verdict`}
												component={SelectField}
												options={verdictsOpts}
												onChange={(e) => {
													setFieldValue(`verdict`, e);
													if (e === 'dh') {
														setFieldValue(`winner`, '0');
														setFieldValue(`loser`, '0');
													}
												}}
											/>
											<ErrorMessage name={`verdict`} component="div" className="text-danger" />
										</div>
										{console.log(values.second_result)}
										{(values.verdict === 'dh') &&
											<div className="col-12">
													<div className="form-check bg-darkgray text-light p-1 text-center my-3">
														<Field
															name="second_result"
															type="checkbox"
															// value="1"
															// checked={values.second_result === }
															// onChange={(e) => {
																// console.log('e', e.target.checked);
																// await setFieldValue();
																// setFieldValue(`second_result`, e.target.checked ? "1" : "0");
																// updateRaceRows(setFieldValue, index, values.races, e.target.checked);
																// arrayHelpers.insert(index + 1, {...values.races[index], id: ''});
															// }}
														/>
														<label className="form-check-label">Record Second Verdict</label>
													</div>
											</div>
										}
									</div>
									{(values.second_result === true && values.verdict === 'dh') &&
										<>
										<div className="row">
											{/* <div className="col-12">
												<hr />
											</div> */}
											<div className="col-8">
												<div className="row">
													<div className="col-6">
														<div className="form-check">
															<Field
																type="radio"
																name={`winner_2`}
																value={String(race?.berks_id)}
																// on change, set the loser to the other crew
																onChange={(e) => {
																	setFieldValue(`winner_2`, e.target.value);
																	setFieldValue(`loser_2`, (e.target.value === String(race?.berks_id)) ? String(race?.bucks_id) : String(race?.berks_id));
																}}
															/>
															<label className="form-check-label">
																{values.berks}
															</label>
															<ErrorMessage name={`winner_2`} component="div" className="text-danger" />
														</div>
													</div>
													<div className="col-6">
														<div className="form-check">
															<Field
																type="radio"
																name={`winner_2`}
																value={String(race?.bucks_id)}
																// on change, set the loser to the other crew
																onChange={(e) => {
																	setFieldValue(`winner_2`, e.target.value);
																	setFieldValue(`loser_2`, (e.target.value === String(race?.berks_id)) ? String(race?.bucks_id) : String(race?.berks_id));
																}}
															/>
															<label className="form-check-label">
																{values.bucks}
															</label>
															<ErrorMessage name={`winner_2`} component="div" className="text-danger" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row mt-2 mb-2">
											<div className="col-2">
												<label>Barrier Time</label>
												<Field name={`barrier_time_2`} className="form-control" onChange={
													(e) => handleChange(e, setFieldValue, 'barrier_time_2')
												} />
												<ErrorMessage name={`barrier_time_2`} component="div" className="text-danger" />
											</div>
											<div className="col text-center">
												<label>Loser Leading</label>
												<Field
													name={`loser_leading_1_2`}
													type="checkbox"
													value="1"
													checked={values.loser_leading_1_2 === "1"}
													onChange={(e) => {
														setFieldValue(`loser_leading_1_2`, e.target.checked ? "1" : "0");
													}}
												/>
												<ErrorMessage name={`loser_leading_1_2`} component="div" className="text-danger" />
											</div>
											<div className="col-2">
												<label>Fawley Time</label>
												<Field name={`fawley_time_2`} className="form-control" onChange={
													(e) => handleChange(e, setFieldValue, 'fawley_time_2')
												} />
												<ErrorMessage name={`fawley_time_2`} component="div" className="text-danger" />
											</div>
											<div className="col text-center">
												<label>Loser Leading</label>
												<Field
													name={`loser_leading_2_2`}
													type="checkbox"
													value="1"
													checked={values.loser_leading_2_2 === "1"}
													onChange={(e) => {
														setFieldValue(`loser_leading_2_2`, e.target.checked ? "1" : "0");
													}}
												/>
												<ErrorMessage name={`loser_leading_2_2`} component="div" className="text-danger" />
											</div>
											<div className="col-2">
												<label>Finish Time</label>
												<Field name={`finish_time_2`} className="form-control" onChange={
													(e) => handleChange(e, setFieldValue, 'finish_time_2')
												} />
												<ErrorMessage name={`finish_time_2`} component="div" className="text-danger" />
											</div>
											<div className="col-2">
												<label>Verdict</label>
												<Field
													name={`verdict_2`}
													component={SelectField}
													options={verdictsOpts}
												/>
												<ErrorMessage name={`verdict_2`} component="div" className="text-danger" />
											</div>
										</div>
										</>
									}
									<div className="row my-2">
									<div className="col-2">
											<div className="form-check">
											<Field
												name={`tweet`}
												type="checkbox"
												value="1"
												checked={values.tweet === "1"}
												onChange={(e) => {
													setFieldValue(`tweet`, e.target.checked ? "1" : "0");
												}}
											/>
											<label className="form-label-check">Tweet</label>
											<ErrorMessage name={`tweet`} component="div" className="text-danger" />
										</div>
									</div>
									{(values.tweet === "1") &&
										<div className="col-10">
											<label>Tweet Text</label>
											<Field component="textarea" name={`tweet_text`} className="form-control" />
											<ErrorMessage name={`tweet_text`} component="div" className="text-danger" />
										</div>
									}
									</div>
									<div>
										<Field type="hidden" name={`loser`} />
										<Field type="hidden" value={day} name={`day`} />
										<Field type="hidden" name={`id`} />
										<Field name={`event_id`} type="hidden" />
										<button type="submit" className={(getRaces[index]?.race_result) ? 'btn btn-success w-100' : 'btn btn-primary w-100'}>{(getRaces[index]?.race_result) ? 'Saved!' : 'Save'}</button>
									</div>
								</>
							</Form>
						)}
					</Formik>
				))}
			</div>
		);
	};

	return (
		<div className="container-fluid">
			<div className="row align-items-center">
				<div className="col-12 col-md-6">
					<h1>Race Results</h1>
				</div>
				<div className="col-12 col-md-6">
					<select className="form-control" onChange={(e) => changeDay(e)} defaultValue={day}>
						<option value="tues">Tuesday</option>
						<option value="weds">Wednesday</option>
						<option value="thurs">Thursday</option>
						<option value="fri">Friday</option>
						<option value="sat">Saturday</option>
						<option value="sun">Sunday</option>
					</select>
				</div>

				<div className="col-12 form-group">
					{(timetable) ? <ResultForm />:<div className="alert alert-danger">No Races Found</div>}
				</div>
			</div>
		</div>
	);
}

export default withRouter(RaceResults);
