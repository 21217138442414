import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getQualifyingCrews, saveQualifyingGroupStatus } from '../../../api/qualifying';

const Qualifiers = (props) => {
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState();

	const getInitialData = async () => {
		try {
			const getQualifyingGroupsRes = await getQualifyingCrews();
			// const getQualifyingGroupsRes = await getQualifyingGroups(0);

			if (getQualifyingGroupsRes.data.status === "success") {
				setGroups(getQualifyingGroupsRes.data.events);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		getInitialData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	const saveRowResult = async (rowValues) => {
		const toastId = toast("Saving New Status...", { autoClose: false });

		try {
			const saveResultRes = await saveQualifyingGroupStatus(rowValues);
			if (saveResultRes.data.status === "success") {
				// getInitialData();
				toast.update(toastId, { render: "Status Saved!", type: toast.TYPE.SUCCESS, autoClose: 2000 });
			}
		} catch (error) {
			console.log('error', error);
			toast.update(toastId, { render: "Error Saving.", type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	const QualifyingGroupForm = () => {
		const initialValues = {
			crew: groups.reduce((crewAcc, crew) => {
				// console.log('group', group);
				// group.crews?.forEach((crews, group_num) => {
					// group.forEach(crew => {
						if (!crew.hasOwnProperty('break')) {
							const letterMap = {
								1: 'Q',
								2: 'T',
								3: 'Y',
								4: 'F',
								// 5: 'X',
								6: 'X',
								7: 'W',
							};

							crewAcc[crew.crew.id] = {
								qualified_status: letterMap[parseInt(crew.crew.qualification_status)]
							};
						}
						// crewAcc[crew.crew.id] = { qualified_status: parseInt(crew.crew.crew.qualification_status) };
					// });
				// });

				return crewAcc;
			}, {})
		};

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object({
					crew: Yup.object().shape({
						qualified_status: Yup.string().required("Required"),
					}),
				})}
			>
				{({ submitForm, values, errors, touched, setFieldValue, isValid }) => {
					return (
						<Form>
							<table className="table table-striped align-middle">
								<thead>
									<tr>
										<th>Qualifying Status</th>
										<th>Crew #</th>
										<th>Crew</th>
										<th>Event</th>
									</tr>
								</thead>
								<tbody>
									{groups.map((crew, i) => {
										// return {
											// group.map((crew, i) => {
											if (!crew.hasOwnProperty('break')) {
												return (
													<tr key={`tr${i}`}>
														<td>
															<Field
																name={`crew[${crew.crew.crew.id}].qualified_status`}
																className="form-control"
																maxLength={1}
																onBlur={(event) => {
																	// Uppercase the value
																	const value = event.target.value.toUpperCase();

																	// Define the letterMap
																	const letterMap = {
																		'Q': 1,
																		'T': 2,
																		'F': 4,
																		'Y': 3,
																		// 'X': 5,
																		// 'W': 6,
																	};

																	// Check if the value is one of the keys in letterMap
																	if (Object.keys(letterMap).includes(value)) {
																		// If the value is in letterMap, use it to get the corresponding status
																		const qualified_status = letterMap[value];

																		// Call saveRowResult with the crew ID and the qualified_status
																		saveRowResult({
																			crew_id: crew.crew.crew.id,
																			qualified_status: qualified_status,
																		});
																	} else {
																		// If the value is not valid, you might want to reset it or handle the error
																		// For example, resetting the field to empty
																		event.target.value = '';
																		// trigger notice
																		toast('Invalid input. Please enter one of the following: Q, T, F', { type: toast.TYPE.ERROR });
																		// toast('Invalid input. Please enter one of the following: Q, T, F, X, W.', { type: toast.TYPE.ERROR });

																		// Optionally, alert the user or display a message indicating the input was invalid
																		// alert('Invalid input. Please enter one of the following: Q, T, F, X, W.');
																	}

																	// Trigger any Formik internal onBlur handler if needed
																	event.target.onBlur && event.target.onBlur(event);
																}}
															/>

														</td>
														<td>{crew.crew.crew.crew_number}</td>
														<td>{crew.crew.crew.crew_name}</td>
														<td>{(crew.crew.event?.name) ? crew.crew.event.name : '-'}</td>
													</tr>
												);
											}
										// }
									})}
								</tbody>
							</table>
						</Form>
					)
				}}
			</Formik>
		)
	};

	return (
		<div className="container-fluid">
			<div className="row align-items-center">
				<div className="col-12 col-md-6">
					<h1>Qualifying Status</h1>
				</div>
				<div className="col-12 mb-3">
					<span>Q = Qualify,</span> <span>Y = Qualified,</span> <span>T = Through,</span> <span>F = Failed,</span> <span>X = Withdrawn (Before Regatta),</span> <span>W = Withdrawn (During Regatta)</span>
				</div>

				<div className="col-12 form-group">
					<QualifyingGroupForm />
				</div>
			</div>
		</div>
	);
}

export default withRouter(Qualifiers);
