import { useEffect } from 'react';
import { withRouter } from '../common/with-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loading from '../components/global/loading';
import { doPayment } from '../api/payments';

const PaymentProcessing = () => {
	// clear cart dispatch
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	// if crypt parameter exists, then it is a payment response. Send it to backend.
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const crypt = searchParams.get('crypt');

	useEffect(() => {
		const doPaymentProcess = async () => {
			if (crypt) {
				const paymentData = await doPayment({crypt: crypt});
				if (paymentData.data.status === "success") {
					// dispatch({ type: 'CLEAR_CART' });
					// go to another url and set the order
					navigate(`/payment/confirmation`, { state: { order: paymentData.data.order } });
				}
			}
		}

		doPaymentProcess();
	}, [crypt, navigate]);

	return <Loading />;
}

export default withRouter(PaymentProcessing);
