import React, { useState, createRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from '../../common/with-router';
import { resetPasswordReq } from '../../api/user';

const ResetPasswordRequest = () => {
	const [message, setMessage] = useState(null);

	const formsubmit = async (values) => {
		console.log(values);
		try {
			const loginData = await resetPasswordReq(values);

			if (loginData.data.status === 'success') {
				setMessage('Please check your email for your password reset link.');
			} else {
				setMessage('An error occured. Please try again.');
				window.grecaptcha.reset();
			}
		} catch (err) {
			console.log(err);
			// if (error.response.data.message) {
			// 	// reset the recaptcha
			// 	window.grecaptcha.reset();
			// 	setMessage(error.response.data.message);
			// }
		}
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-md-8">
					{(message) ? (
							<div className="alert alert-info" role="alert">{message}</div>
						):null
					}
					<h1>Reset Password</h1>
					<Formik initialValues={{
						email: '',
						recaptcha: '',
					}} onSubmit={formsubmit}>
						{({ handleSubmit, isSubmitting, setFieldValue }) => (
							<Form>
								<div className="mb-2">
									<label htmlFor="email">Email</label>
									<Field type="email" className="form-control" id="email" name="email" placeholder="Enter email" />
								</div>
								<div className="mb-2">
									<ReCAPTCHA
										sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
										onChange={(value) => {
											// value is the reCAPTCHA response, store it in the form values
											setFieldValue('recaptcha', value);
										}}
									/>
									<ErrorMessage name="recaptcha" />
								</div>
								<button type="submit" className="btn btn-primary" disabled={(isSubmitting) && true}>Request Reset</button>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ResetPasswordRequest);
