import React, { useState, useCallback, useEffect } from 'react';
import { JWTProvider } from './jwtContext';
import jwtDecode from 'jwt-decode';
import { refreshToken } from '../api/auth';

const JWTProviderComponent = ({ children }) => {
	const [jwt, setJWT] = useState(localStorage.getItem('_rrjt'));
	const [isLoading, setIsLoading] = useState(true); // Initialize as true to handle initial load
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const updateJWT = (newJWT) => {
		localStorage.setItem('_rrjt', newJWT);
		setJWT(newJWT);
	};

	const logout = () => {
		localStorage.removeItem('_rrjt');
		setJWT(null);
	};

	const refreshJWT = useCallback(async () => {
		try {
			const response = await refreshToken();
			console.log('Refreshed token', response.data.access_token);
			const newJWT = response.data.access_token;
			updateJWT(newJWT);
			return newJWT;
		} catch (error) {
			console.error('Error refreshing token', error);
			updateJWT(null);
			return null;
		}
	}, [jwt]);

	const checkAuthentication = useCallback(async () => {
		setIsLoading(true);
		if (!jwt) {
			setIsAuthenticated(false);
			setIsLoading(false);
			return;
		}

		try {
			const decoded = jwtDecode(jwt);

			if (decoded.exp && decoded.exp < Date.now() / 1000) {
				const refreshedToken = await refreshJWT();
				setIsAuthenticated(!!refreshedToken);
			} else {
				setIsAuthenticated(true);
			}
		} catch (err) {
			setIsAuthenticated(false);
		}
		setIsLoading(false);
	}, [jwt, refreshJWT]);

	useEffect(() => {
		checkAuthentication();
	}, [checkAuthentication]);

	return (
		<JWTProvider value={{ jwt, updateJWT, refreshJWT, isAuthenticated, isLoading, logout }}>
			{children}
		</JWTProvider>
	);
};

export default JWTProviderComponent;
