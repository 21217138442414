import useJWT from '../../jwt/useJWT';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faHome, faPerson, faRightFromBracket, faRightToBracket, faShoppingBasket } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
	const { logout, isAuthenticated } = useJWT();

	// const { logout, isAuthenticated } = useAuth0();

	const doLogout = () => {
		logout({
			returnTo: window.location.origin,
		});

		// delete localstorage token
		localStorage.removeItem('hrr_access_token');
	};

	return (
		<header>
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-8">
						<Link to="/">
							Regatta Entries
						</Link>
					</div>
					<div className="col-4 text-end">
						{
							(isAuthenticated) &&
							<>
								<Link to="/account" className="me-2"><FontAwesomeIcon icon={faAddressCard} /></Link>
								<Link to="/basket"><FontAwesomeIcon icon={faShoppingBasket} /></Link>
							</>
						}
						{
							(isAuthenticated) ? (
								<button onClick={() => doLogout()} className="btn"><FontAwesomeIcon icon={faRightFromBracket} /></button>
							) : <Link to="/login"><FontAwesomeIcon icon={faRightToBracket} /></Link>
						}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
