import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../api/user';
import { useDispatch } from 'react-redux';
// import { refreshToken } from '../api/auth';
// import useJWT from '../jwt/useJWT';

const PaymentConfirmation = (props) => {
	const dispatch = useDispatch();

	const order = props.router.location.state.order;

	const reFetchUser = async () => {
		const currentUser = await getCurrentUser();
		if (currentUser.data.status === 'success') {
			dispatch({ type: 'USER', payload: currentUser.data.user });
		}
	};

	useEffect(() => {
		reFetchUser();
	});

	const getOrderItems = () => {
		return Object.entries(order.items).map((item, index) => {
			return (
				<tr key={index}>
					<td>{item[1].entry?.crew?.crew_name}</td>
					<td>&pound;{item[1].amount.toFixed(2)}</td>
				</tr>
			);
		});
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Your Royal Regatta Entries Confirmed</h1>
					
					{(order) ?
						<div className="row">
							<div className="col-12 mb-2">
								<p>Thank you for your payment. Your order number is <strong>{order.orderId}</strong>.</p>
							</div>
							<div className="col-12 col-lg-8">
								<h2>Your Entries</h2>
								<table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">Crew Name</th>
											<th scope="col">Price</th>
										</tr>
									</thead>
									<tbody>
										{getOrderItems()}
									</tbody>
								</table>
								<Link to="/" className="btn btn-primary">Back to Home</Link>
							</div>
						</div> : <div className="notice notice-error">No order items found</div>
					}
				</div>
			</div>
		</div>
	);
}

export default withRouter(PaymentConfirmation);
