import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import Loading from '../../../components/global/loading';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getEmailTemplates, saveEmailTemplate } from '../../../api/settings';
import SelectField from '../../../components/forms/select';

const Emails = () => {
	const [loading, setLoading] = useState(true);
	const [templates, setTemplates] = useState({});

	const initialSettings = async () => {
		try {
			const getAllTemplates = await getEmailTemplates();
			setTemplates(getAllTemplates.data.templates);
			setLoading(false);
		} catch (error) {
			console.log('Error getting settings:', error);
		}
	};

	useEffect(() => {
		initialSettings();
	}, []);

	if (loading) {
		return <Loading />;
	}

	const saveTemplate = async (values) => {
		const toastId = toast("Saving template...", { autoClose: false, type: 'info' });
		try {
			const saveET = await saveEmailTemplate(values);
			if (saveET.data.status === 'success') {
				toast.update(toastId, { render: "Template saved!", type: 'success', autoClose: 5000 });
			}
		} catch (error) {
			console.log('Error saving template:', error);
			toast.update(toastId, { render: "Error saving template!", type: 'error', autoClose: 5000 });
		}
	}

	const EmailEditor = () => {
		return (
			<Formik
				initialValues={{
					path: null,
					template: null,
					content: '',
				}}
				onSubmit={saveTemplate}
			>
				{({ isSubmitting, setFieldValue }) => (
					<Form>
						<div className="row">
							<div className="col-12"><h3>Emails</h3></div>

							<div className="col-12 col-md-6">
								<div className="form-group">
									<label htmlFor="name">Select Template</label>
									<Field name="template" component={SelectField} options={
										Object.keys(templates).map((key) => {
											return {
												value: key,
												label: templates[key].name
											}
										})
									}
									onChange={(e) => {
										setFieldValue('content', templates[e].content);
										console.log(templates[e].file);
										setFieldValue('path', templates[e].file);
									}}
									className="form-control" />
									<ErrorMessage name="template" />
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-12 col-md">
										<div className="form-group">
											<label htmlFor="name">Content</label>
											<Field name="content" component="textarea" style={{height: 600}}className="form-control" />
											<ErrorMessage name="content" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">Save</button>
					</Form>
				)}
			</Formik>
		)
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Emails</h1>
				</div>
				<div className="col-12 col-md-6 mb-3"></div>

				<div className="col-12">
					<EmailEditor />
				</div>
			</div>
		</div>
	);
}

export default withRouter(Emails);
