import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { getClubs } from '../api/clubs';

const AthleteFilter = ({ handleFilters, filters }) => {
	const [year, setYear] = useState(null);
	const [club, setClub] = useState(null);
	const [event, setEvent] = useState(null);
	const [events, setEvents] = useState(null);
	const [clubsList, setClubsList] = useState(null);
	const [clubs, setClubs] = useState(null);

	const eventsList = useSelector((state) => state.events);

	useEffect(() => {
		const getInitialData = async () => {
			try {
				const res = await getClubs();
				if (res.data.status === 'success') {
					setClubsList(res.data.clubs);
				}
			} catch (error) {
				console.log('error', error);
			}
		};

		getInitialData();
	}, []);

	useEffect(() => {
		mapClubsToSelect();
	}, [clubsList]);

	useEffect(() => {
		mapEventsToSelect();
	}, [eventsList.events]);

	const mapClubsToSelect = () => {
		if (!clubsList) return;

		let clubOptions = [{ value: '', label: 'All' }];
		clubsList.forEach(value => {
			clubOptions.push({ value: value.id, label: value.name });
		});

		setClubs(clubOptions);
	};

	const mapEventsToSelect = () => {
		if (!eventsList.events) return;

		let eventOptions = [{ value: '', label: 'All' }];
		eventsList.events.forEach(value => {
			eventOptions.push({ value: value.id, label: value.name });
		});

		setEvents(eventOptions);
	};

	const generateYears = () => {
		const years = [{ value: null, label: 'All' }];
		const currentYear = new Date().getFullYear();
		for (let i = currentYear; i >= 1990; i--) {
			years.push({ value: i, label: i });
		}
		return years;
	};

	const handleYearChange = selectedOption => {
		setYear(selectedOption || null);
		handleFilters({ year: selectedOption || null, club, event });
	};

	const handleClubChange = selectedOption => {
		setClub(selectedOption || null);
		handleFilters({ year, club: selectedOption || null, event });
	};

	const handleEventChange = selectedOption => {
		setEvent(selectedOption || null);
		handleFilters({ year, club, event: selectedOption || null });
	};

	return (
		<div className="filter-container row">
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Year</label>
				<Select
					value={year}
					onChange={handleYearChange}
					options={generateYears()}
				/>
			</div>
			<div className="filter-item col-12 col-md-6 mb-3">
				<label>Club</label>
				<Select
					value={club}
					onChange={handleClubChange}
					options={clubs}
				/>
			</div>
			<div className="filter-item col-12 col-md-6">
				<label>Event</label>
				<Select
					value={event}
					onChange={handleEventChange}
					options={events}
				/>
			</div>
		</div>
	);
};

export default AthleteFilter;
