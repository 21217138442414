import { combineReducers } from 'redux';
import userReducer from './userReducer';
import eventsReducer from './eventsReducer';
import cartReducer from './cartReducer';

const rootReducer = combineReducers({
	user: userReducer,
	events: eventsReducer,
	cart: cartReducer,
});

export default rootReducer;
