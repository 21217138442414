import { useState } from 'react';
import { withRouter } from '../../../common/with-router';
import ReportOptions from './GenerateReport';

const Reports = () => {
	const [optionsModal, setOptionsModal] = useState(false);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Reports</h1>
				</div>
				<div className="col-12">
					<button className="btn btn-primary" type="button" onClick={() => setOptionsModal(true)}>Generate Report</button>
					<ReportOptions optionsModal={optionsModal} setOptionsModal={setOptionsModal} />
				</div>
			</div>
		</div>
	);
}

export default withRouter(Reports);
