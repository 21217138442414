import instance from './config';

const getOrders = (page = 1, sortDir = 'asc', sortCol = 'id') => {
	return instance.get(`/admin/orders?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getOrder = (id) => {
	return instance.get(`/admin/order/${id}`);
};

const changeOrderStatus = (id, status) => {
	return instance.put(`/admin/order/${id}`, { status });
}

export {
	changeOrderStatus,
	getOrders,
	getOrder
}
