import React from 'react';
import Select from 'react-select';

const SelectField = ({ options, field, form, noOptionsMessage, onChange, isMulti, isDisabled, isClearable, filterOption }) => {
	return (
		<Select
			options={options}
			name={field.name}
			value={
				options && field.value != null
					? isMulti
						? Array.isArray(field.value)
							? options.filter(option => field.value.includes(option.value))
							: []
						: options.find(option => option.value === field.value)
					: (isMulti ? [] : null)
			}
			onChange={(option) => {
				if (option === null) {
					form.setFieldValue(field.name, '');
					if (onChange) {
						onChange('', field.name);
					}
					return;
				}

				if (isMulti) {
					form.setFieldValue(field.name, option.map(item => item.value));
					if (onChange) {
						onChange(option.map(item => item.value), field.name);
					}
				} else {
					form.setFieldValue(field.name, option.value);
					if (onChange) {
						onChange(option.value, field.name);
					}
				}
			}}
			filterOption={filterOption}
			onBlur={field.onBlur}
			noOptionsMessage={noOptionsMessage}
			isMulti={isMulti}
			isDisabled={isDisabled}
			isClearable={isClearable}
		/>

	);
}


export default SelectField;
