import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../../components/Pagination';
import { getAthletes, deleteAthlete } from '../../../api/athletes';
import AthleteFilter from '../../../components/AthleteFilter';

const AthleteList = (props) => {
	const [loading, setLoading] = useState(true);
	const [athletes, setAthletes] = useState({});
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [sortDirection, setSortDirection] = useState('asc');
	const [sortedColumn, setSortedColumn] = useState('id');
	const [filters, setFilters] = useState({ year: null, club: null, event: null });

	const handleFilters = (newFilters) => {
		setFilters(newFilters);
	};

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const fetchAthletes = await getAthletes(page, sortDirection, sortedColumn, filters);
			if (fetchAthletes.data.status === 'success') {
				setAthletes(fetchAthletes.data.athletes);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		if ( !isSearching ) {
			getInitialData();
		}
	}, [page, sortDirection, sortedColumn, filters]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		setAthletes(data);
	};

	const handleDeleteItem = async (id) => {
		if (window.confirm("Are you sure you want to delete this item?")) {
			try {
				const deleteAthleteData = await deleteAthlete(id);
				if (deleteAthleteData.data.status === 'success') {
					setAthletes(prevData => prevData.filter(item => item.id !== id));
				}
			} catch (error) {
				// Handle your error
				console.error('Error:', error);
			}
		}
	}

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(athletes.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.id,
				hrr_number: value.hrr_number,
				first_name: value.first_name,
				middle_initials: value.initials,
				last_name: value.last_name,
				dob: value.dob,
				club: value.club?.name,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Athletes <Link to="/admin/athletes/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} term={searchTerm} endpoint="admin/athletes/search" page={page} setIsSearching={setIsSearching} placeholder="Search first name, last name, DOB (dd/mm/yyyy) or HRR Number" setLoading={setLoading} />
				</div>

				<div className="filters-container">
					<div className="col-12">
						<h3>Filters</h3>
					</div>
					<div className="col-12 col-md-12 mb-3">
						<AthleteFilter handleFilters={handleFilters} filters={filters} />
					</div>
				</div>

				<div className="col-12 form-group">
					<div>
						{(athletes.data.length) ?
							<div>
								<Table
									data={columns()}
									visibleColumns={[
										{
											name: 'hrr_number',
											sort: 'hrr_number'
										},
										{
											name: 'first_name',
											sort: 'first_name'
										},
										{
											name: 'middle_initials',
											sort: 'middle_initials'
										},
										{
											name: 'last_name',
											sort: 'last_name'
										},
										{
											name: 'dob',
											sort: 'dob'
										},
										{
											name: 'club',
											sort: 'clubs.name'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									linkColumn="hrr_number"
									linkLocation="/admin/athletes/edit"
									editable={true}
									handleDelete={handleDeleteItem}
								/>
								<Pagination data={athletes} setPage={setPage} page={page} />
							</div> : <div className="alert alert-danger">No athletes found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(AthleteList);
