import instance from './config';

const getEvents = (page = 1, sortDir = 'asc', sortCol = 'display_order') => {
	return instance.get(`/events?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getEvent = (id) => {
	return instance.get(`/admin/event/${id}`);
};

const getEventTypes = (page = 1, sortDir = 'asc', sortCol = 'name') => {
	return instance.get(`/admin/event-types?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const getEventType = (id) => {
	return instance.get(`/admin/event-type/${id}`);
};

export {
	getEvents,
	getEvent,
	getEventTypes,
	getEventType
}
