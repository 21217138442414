import instance from './config';

const doPayment = (crypt) => {
	return instance.post('/payment/response', crypt);
};

const getPayment = (paymentData) => {
	return instance.post(`/payment`, paymentData);
};

const completeBasket = (paymentData) => {
	return instance.post(`/payment/complete`, paymentData);
};

export {
	doPayment,
	getPayment,
	completeBasket
}
