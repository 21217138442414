import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loading from '../components/global/loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const AthleteEntry = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;

	// const { isAuthenticated } = useAuth0();
	const [loading, setLoading] = useState(true);
	const [validate, setValidate] = useState(false);
	const [signup, setSignup] = useState(false);
	const [message, setMessage] = useState('');
	const [complete, setComplete] = useState('');
	const [secret, setSecret] = useState(false);

	const hash = props.router.params.hash;

	const navigate = useNavigate();

	const getAthleteByHash = () => {
		if (hash) {
			setLoading(true);
			fetch(`${API_URL}/athlete/validate/${hash}`, {
				headers: {
					// auth url
					'Content-Type': 'application/json',
				}
			})
			.then((response) => response.json())
			.then((data) => {
				setLoading(false);
				if (data.status === 'success') {
					if (data.validate === true) {
						setValidate(true);
					} else if (data.validate === false) {
						// navigate to login
						navigate(`/login`);
						setValidate(false);
					}
				} else if (data.status === 'error') {
					setMessage('Invalid URL. Please contact support if you received this URL in an email.')
				} else {
					setValidate(false);
				}
			})
		}
	}

	const doValidation = () => {
		return (
			<div className="row">
				<div className="col-12">
					<h2>Validate your account</h2>
					<p>Before you can continue, you need to validate your account.</p>
					<Formik
						initialValues={{
							last_name: '',
						}}
						validationSchema={Yup.object({
							last_name: Yup.string()
								.required('Required'),
						})}
						onSubmit={(values, { setSubmitting }) => {
							console.log('vb', values);
							const toastId = toast("Validating athlete...", { autoClose: false });
							fetch(`${API_URL}/athlete/validate/${hash}`, {
								method: 'POST',
								headers: {
									// auth url
									'Content-Type': 'application/json',
								},
								body: JSON.stringify(values)
							})
								.then((response) => response.json())
								.then((data) => {
									if (data.status === 'success') {
										if (data.secret) {
											// clear toast
											toast.dismiss(toastId);
											setSecret(data.secret);
											setValidate(false);
											setSignup(true);
										} else {
											toast.update(toastId, { render: data.message, type: toast.TYPE.ERROR, autoClose: 3000 });
										}
									} else if (data.status === 'error') {
										toast.update(toastId, { render: data.message, type: toast.TYPE.ERROR, autoClose: 3000 });
									} else {
										toast.update(toastId, { render: 'Could not verify. Please contact support.', type: toast.TYPE.INFO, autoClose: 3000 });
										console.log('TUNA', data);
									}
								})
						}}
					>
						<Form>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="last_name">Enter your Last Name</label>
										<Field name="last_name" type="text" className="form-control" />
										<ErrorMessage name="last_name" />
									</div>
								</div>
								<div className="col-12">
									<button type="submit" className="btn btn-primary">Validate</button>
								</div>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
		);
	}


	const doSignup = () => {
		return (
			<div className="row">
				<div className="col-12">
					<h2>Athlete Registration</h2>
					<p>Before you can continue, please register.</p>
					<Formik
						initialValues={{
							email: '',
							password: '',
							password_confirmation: '',
							dob: '',
							dob2: '',
						}}
						validationSchema={Yup.object({
							email: Yup.string()
								.email('Invalid email address')
								.required('Required'),
							password: Yup.string()
								.required('Required'),
							password_confirmation: Yup.string()
								.oneOf([Yup.ref('password'), null], 'Passwords must match'),
							dob: Yup.string()
								.required('Date of birth is required')
								.test(
									'is-date',
									'Date format should be dd/mm/yyyy and a valid date',
									value => {
										return moment(value, 'DD/MM/YYYY', true).isValid();
									}
								),
							dob2: Yup.string()
								.oneOf([Yup.ref('dob'), null], 'Date of birth must match')
						})}
						onSubmit={(values, { setSubmitting }) => {
							const toastId = toast("Registering athlete...", { autoClose: false });
							const vals = {
								...values,
								secret: secret,
							}

							fetch(`${API_URL}/athlete/create/${hash}`, {
								method: 'POST',
								headers: {
									// auth url
									'Content-Type': 'application/json',
								},
								body: JSON.stringify(vals)
							})
								.then((response) => response.json())
								.then((data) => {
									if (data.status === 'success') {
										toast.update(toastId, { render: 'Registration complete. Redirecting...', type: toast.TYPE.SUCCESS, autoClose: 3000 });
										navigate(`/login?validateemail=true`);
										setValidate(false);
									} else {
										toast.update(toastId, { render: 'Error registering. Please contact support.', type: toast.TYPE.ERROR, autoClose: 3000 });
									}
								});
						}}
					>
						<Form>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="email">Email</label>
										<Field name="email" type="email" className="form-control" />
										<ErrorMessage name="email" component="div" className="field-error" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label htmlFor="password">Password</label>
										<Field name="password" type="password" className="form-control" />
										<ErrorMessage name="password" component="div" className="field-error" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label htmlFor="password">Password Confirm</label>
										<Field name="password_confirmation" type="password" className="form-control" />
										<ErrorMessage name="password_confirmation" component="div" className="field-error" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label htmlFor="password">Date of Birth (dd/mm/yyyy)</label>
										<Field name="dob" type="text" className="form-control" />
										<ErrorMessage name="dob" component="div" className="field-error" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label htmlFor="password">Confirm Date of Birth (dd/mm/yyyy)</label>
										<Field name="dob2" type="text" className="form-control" />
										<ErrorMessage name="dob2" component="div" className="field-error" />
									</div>
								</div>
								<div className="col-12">
									<button type="submit" className="btn btn-primary">Login</button>
								</div>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
		);
	}

	useEffect(() => {
		getAthleteByHash();
	}, []);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h1>Athlete Registration</h1>
					{(message) && (
						<div className="alert alert-danger" role="alert">
							{message}
						</div>
					)}

					{(validate) && (
						doValidation()
					)}

					{(signup) && (
						doSignup()
					)}
				</div>
			</div>
		</div>
	);
}

export default withRouter(AthleteEntry);
