import instance from './config';

const getClubs = () => {
	return instance.get('/clubs');
};

const adminGetClubs = (page = 1, sortDir = 'asc', sortCol = 'id') => {
	return instance.get(`/admin/clubs?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const adminGetClub = (id) => {
	return instance.get(`/admin/clubs/${id}`);
};

const addNewClub = (data) => {
	return instance.post('/clubs', data);
};

const deleteClub = (id) => {
	return instance.delete(`/admin/clubs/${id}`);
}

export {
	getClubs,
	adminGetClubs,
	adminGetClub,
	addNewClub,
	deleteClub,
}
