import { withRouter } from '../common/with-router';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Declaration = ({setDeclaration, cart, setModal}) => {
	const getClubCountries = () => {
		const countries = {};
		Object.entries(cart).forEach((item) => {
			if (item[1].entry?.crew?.crew_members) {
				item[1].entry?.crew?.crew_members.forEach((member) => {
					countries[member.club.country_code] = {
						name: member.club.name,
						country: member.club.country.country,
						code: member.club.country_code,
					}
				})
			}
		})

		return countries;
	}

	const getUniqueClubs = () => {
		const countries = {};
		Object.entries(cart).forEach((item) => {
			console.log('DBG', item[1]);
			if (item[1].entry?.crew?.crew_members) {
				item[1].entry?.crew?.crew_members.forEach((member) => {
					countries[member.club.id] = {
						name: member.club.name,
						country: member.club.country.country,
						code: member.club.country_code,
					}
				})
			}
		})

		return countries;
	}
	
	const DoDeclaration = () => {
		const clubCountries = getClubCountries();
		const uniqueClubs = getUniqueClubs();

		const onSubmit = (values, { setSubmitting }) => {
			setSubmitting(true);
			setDeclaration(values);
			setSubmitting(false);
			setModal(false);
		};

		const initialValues = {
			declaration: {
				countries: Object.keys(clubCountries).reduce((acc, key) => {
					acc[key] = { country: '' };
					return acc;
				}, {}),
				clubs: Object.keys(uniqueClubs).reduce((acc, key) => {
					acc[key] = { signed: '', contact: '' };
					return acc;
				}, {}),
			},
		};

		const countriesSchema = Object.keys(clubCountries).reduce((acc, key) => {
			acc[key] = Yup.object({
				country: Yup.string().required("Required"),
			});
			return acc;
		}, {});

		const clubsSchema = Object.keys(uniqueClubs).reduce((acc, key) => {
			acc[key] = Yup.object({
				signed: Yup.string().required("Signed By is required"),
				contact: Yup.string().required("Contact Number is required").matches(
					/^\+\d{10,15}$/,
					'Phone number must start with a "+" followed by the country code and number'
				),
			});
			return acc;
		}, {});

		const validationSchema = Yup.object({
			declaration: Yup.object({
				countries: Yup.object(countriesSchema),
				clubs: Yup.object(clubsSchema),
			}),
		});

		return (
			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ values, setFieldValue, isValid, errors, isSubmitting }) => (
						<Form>
							<p>To be signed by a duly authorised official of the Club.</p>
							<p>I hereby certify that this Club was established and affiliated to</p>
							<div className="row">

							{Object.entries(getClubCountries()).map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-6 mb-2">
											<p>Please enter the name of the national federation of <strong>{item[1].country}</strong></p>
											<Field type="text" name={`declaration[countries][${item[0]}].country`} className="form-control" />
											<ErrorMessage name={`declaration[countries][${item[0]}].country`} component="div" className="field-error" />
										</div>
									)
								})
							}
							</div>

							<p>on or before 17th June 2023, is still so affiliated and that each competitor entered, or whose name is to be entered under Rule 16 as a substitute, is eligible according to Rule 1 of the General Rules of Henley Royal Regatta; is eligible to compete for the Event(s) for which they are entered; is and has been a member of this Club since 17th April 2024 and:</p>

							<ul>
								<li>consents to the Regatta and its licensees filming and broadcasting the Regatta event both on and off water, including its participants, and acknowledges the rights of those parties to produce and exploit that footage in the manner set out in the Entry Notes; and</li>
								<li>understands and agrees that their Club and name will be disclosed to Accredited Press and included in the Racing Programme, Regatta Records and on the website (which for the avoidance of doubt may be included as part of the live and recorded online coverage and accompanying highlights programme for potential broadcast transmission at a future date).</li>
							</ul>

							<p>I confirm the accuracy of this entry and that I have read the Qualification Rules for the Event(s) entered, the General Rules of Henley Royal Regatta, and the Entry Notes and I agree to comply with and abide by them. I shall draw the attention of all competing members of my Club to the terms and conditions of these Rules and to such other instructions, rules or guidance notes, including any relevant COVID Code of Conduct issued by the Stewards of the Regatta or by their delegated officials, e.g. 'Notes and Instructions for Competitors and Coaches' and 'Traffic Rules and Training Times'.</p>

							<p>On behalf of the Club I acknowledge that Henley Royal Regatta has the exclusive right to grant any Media Rights (as defined at Clause 16 of the Entry Notes) in connection with the Regatta.</p>

							<p>I also confirm that each competitor and coach understands that the Regatta will receive their personal data as set out in the Entry Form, and by any other means they or the Club may provide it, for the purposes set out here (including the administration of the Regatta and future Regatta events) and as more generally set out in the Regatta's Privacy Notice available on the Regatta website <a href="https://www.hrr.co.uk/privacy-cookie-policy/" target="_blank">hrr.co.uk/privacy-cookie-policy</a>.</p>

							<p>I understand that as a condition of entry to the Regatta, each crew member (or the crew member's parent or guardian in the case of children) must consent to compete in the Regatta on this basis set out at 18 of the Entry Notes (Filming the 2024 Regatta) and assign, to the extent as may be required, all associated individual Media Rights to the Regatta. On behalf of my Club I confirm that I have brought this to the attention of each crew member (and their parent or guardian where applicable) and have procured their consent accordingly.</p>
							
							<div className="row">
								{Object.entries(getUniqueClubs()).map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-6 mb-3">
											<p>Duly authorised official of <strong>{item[1].name}</strong></p>
											<div className="mb-3">
												Signed By <Field type="text" name={`declaration[clubs][${item[0]}].signed`} className="form-control" />
												<ErrorMessage name={`declaration[clubs][${item[0]}].signed`} component="div" className="field-error" />
											</div>
											

											<div className="mb-3">
												Contact Number (including country code e.g. +44) <Field type="text" name={`declaration[clubs][${item[0]}].contact`} className="form-control" />
												<ErrorMessage name={`declaration[clubs][${item[0]}].contact`} component="div" className="field-error" />
											</div>
										</div>
									)
								})}
							</div>

							<div className="row">
								<div className="col-12">
									<div className="form-group form-buttons">
										<button
											className="btn btn-primary btn-block"
											type="submit"
										>
											<span>Save and Continue</span>
										</button>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		)
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-12">
							<DoDeclaration />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Declaration);
