import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Loading from '../../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getOrder, changeOrderStatus } from '../../../api/orders';
import { emailSend } from '../../../api/triggers';

const OrderSingle = (props) => {
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState(null);

	const getInitialData = async (id) => {

		try {
			const orderResponse = await getOrder(id);
			if (orderResponse.data.status === 'success') {
				setOrder(orderResponse.data.order);
				console.log(orderResponse.data.order)
				setLoading(false);
			}
		} catch (error) {
			
		}
		// fetch(`${API_URL}/admin/order/${id}`, {
		// 	headers: {
		// 		// auth url
		// 		'Content-Type': 'application/json',
		// 		'Authorization': `Bearer ${token}`,
		// 	}
		// })
		// .then((response) => response.json())
		// .then((data) => {
		// 	setOrder(data.order);
		// 	console.log(data.order);
		// 	setLoading(false);
		// })
		// .catch((error) => {
		// 	console.error('Error:', error);
		// });
	}

	useEffect(() => {
		if (props.router?.params?.id) {
			const id = props.router?.params?.id;

			if (id) {
				setLoading(true);
				getInitialData(id);
			}
		}
	}, []);

	const resendNGBAuth = (entryId) => {
		const toastId = toast("Resending NGB authorisation email...", { autoClose: false });

		(async () => {
			const sendEmail = await emailSend(entryId, 'ngb_resend');
			if (sendEmail.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "NGB authorisation email sent", autoClose: 5000 });
			} else {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error sending email", autoClose: 5000 });
			}
		})();
	};

	const changeOrderStatusDropdown = async (status) => {
		const toastId = toast("Updating order status...", { autoClose: false });

		try {
			const updateOrder = await changeOrderStatus(order.id, status);
			if (updateOrder.data.status === 'success') {
				toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Order status updated", autoClose: 5000 });
			} else {
				toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error updating order status", autoClose: 5000 });
			}
		} catch (error) {
			console.log('errr', error);
			toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error updating order status", autoClose: 5000 });
		}
	};


	const OrderData = () => {
		if (order) {
			return (
				<div>
					<div className="row">
						<div className="col-12 col-md-6">
							<h3>Order Summary</h3>
							<table className="table">
								<tbody>
									<tr>
										<td><strong>Order ID</strong></td>
										<td>{order.orderId}</td>
									</tr>
									<tr>
										<td><strong>Customer Name</strong></td>
										<td>{order.user.first_name} {order.user.last_name} (<Link to={`/admin/users/edit/${order.user.id}`}>{order.user.email}</Link>)</td>
									</tr>
									{(order.discount_code) && 
									<tr>
										<td><strong>Coupon Used</strong></td>
										<td>{order.discount_code}</td>
									</tr>
									}
									<tr>
										<td><strong>Status</strong></td>
										<td>
											<select name="status" className="form-control" onChange={(e) => changeOrderStatusDropdown(e.target.value)}>
												<option value="0" selected={order.status === 0}>Pending Payment</option>
												<option value="1" selected={order.status === 1}>Complete</option>
												<option value="2" selected={order.status === 2}>Refunded</option>
												<option value="3" selected={order.status === 3}>On-hold</option>
											</select>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col-12 col-md-6">
							<h3>Opayo Data</h3>
							<table className="table">
								<tbody>
									<tr>
										<td><strong>Order Total</strong></td>
										<td>£{order.amount.toFixed(2)}</td>
									</tr>
									<tr>
										<td><strong>TxCode</strong></td>
										<td>{order.VendorTxCode}</td>
									</tr>
									<tr>
										<td><strong>Opayo Auth No</strong></td>
										<td>{order.TxAuthNo}</td>
									</tr>
									<tr>
										<td><strong>Status Details</strong></td>
										<td>{order.StatusDetail}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col-12">
							<h3>Order Items</h3>
							<table className="table table-striped">
								<thead>
									<tr>
										<th>Item</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									{order.items.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<small>{item.entry.event.name}</small><br/>
													<Link to={`/admin/entries/edit/${item.entry.id}`}>{item.entry.crew.crew_name}</Link>
													<div className="d-block mt-2">
														<button className="btn btn-link" onClick={() => resendNGBAuth(item.entry.id)}>Resend Athlete NGB Auth</button>
													</div>
												</td>
												<td>£{item.amount.toFixed(2)}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 mb-3"><Link to="/admin/orders"><FontAwesomeIcon icon={faArrowLeft} /> Back to Orders</Link></div>
				<div className="col-12 col-md-6">
					<h1>Order: {order.id}</h1>
				</div>

				<div className="col-12 form-group">
					{<OrderData />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(OrderSingle);
