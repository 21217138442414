import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const Pagination = ({ data, setPage, page }) => {
	console.log('PAGdata', data);
	const [goToPage, setGoToPage] = useState(page);
	if (!data) return null;

	const handleClick = (pageNum) => {
		setPage(pageNum);
	};

	// Generate an array from 1 to last_page
	const pages = Array.from({ length: data.last_page }, (v, i) => i + 1);

	// Define how many pages to display
	const numOfPagesToShow = 5;
	const startPage = Math.max(data.current_page - Math.floor(numOfPagesToShow / 2), 1);
	const endPage = Math.min(startPage + numOfPagesToShow - 1, data.last_page);
	const displayedPages = pages.slice(startPage - 1, endPage);

	// console.log('pag', data);

	const handleGoToPage = () => {
		const pageNum = parseInt(goToPage, 10);
		if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= data.last_page) {
			setPage(pageNum);
		}
	};

	return (
		<nav className="pagination">
			<ul>
				{(data.prev_page_url) &&
					<>
						<li className={`page-item ${data.prev_page_url ? '' : 'disabled'}`}>
							<button
								className="page-link"
								onClick={() => handleClick(1)}
							>
								<FontAwesomeIcon icon={faAnglesLeft} />
							</button>
						</li>

						<li className={`page-item ${data.prev_page_url ? '' : 'disabled'}`}>
							<button
								className="page-link"
								onClick={() => handleClick(data.current_page - 1)}
							>
								<FontAwesomeIcon icon={faAngleLeft} />
							</button>
						</li>
					</>
				}

				{displayedPages.map((pageNum) => (
					<li
						key={pageNum}
						className={`page-item ${pageNum === data.current_page ? 'active' : ''}`}
					>
						<button className="page-link" onClick={() => handleClick(pageNum)}>
							{pageNum}
						</button>
					</li>
				))}

				{(data.next_page_url) && 
					<>
						<li className={`page-item ${data.next_page_url ? '' : 'disabled'}`}>
							<button
								className="page-link"
								onClick={() => handleClick(data.current_page + 1)}
							>
								<FontAwesomeIcon icon={faAngleRight} />
							</button>
						</li>


						<li className={`page-item ${data.next_page_url ? '' : 'disabled'}`}>
							<button
								className="page-link"
								onClick={() => handleClick(data.last_page)}
							>
								<FontAwesomeIcon icon={faAnglesRight} />
							</button>
						</li>
					</>
				}
			</ul>

			<div className="page-goto">
				<span>Go to Page:</span> <input
					type="number"
					value={goToPage}
					onChange={e => setGoToPage(e.target.value)}
					onKeyDown={e => (e.key === 'Enter' ? handleGoToPage() : null)}
				/>
				<button
					className="page-link"
					onClick={handleGoToPage}
				>
					Go
				</button>
			</div>
		</nav>
	);
};

export default Pagination;
