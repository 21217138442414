const userAction = {
	type: "USER",
	payload: "fag"
};

export const USER = 'USER';

const setUserAction = (user) => {
	return {
		type: USER,
		payload: user,
	}
};

export { setUserAction };
