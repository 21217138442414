import { React, useState } from "react";
import { withRouter } from '../../common/with-router';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { register } from '../../api/auth';
// import useJWT from '../../jwt/useJWT';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
	const [message, setMessage] = useState(null);

	const formsubmit = async (values, { resetForm }) => {
		const toastId = toast("Submitting Registration...", { autoClose: false });

		try {
			const registerData = await register(values);
			if (registerData.data.status === 'success') {
				setMessage({ type: 'success', message:'Please check your email to validate your address.'});
				toast.update(toastId, { autoClose: 5000, type: 'success', render: 'Please check your email to validate your address.' });
				resetForm();
			} else if (registerData.data.status === 'error' && registerData.data.message) {
				setMessage({ type: 'error', message: registerData.data.message });
				toast.dismiss(toastId);
			} else {
				setMessage('Error registering');
				toast.dismiss(toastId);
			}
		} catch (error) {
			if (error.response.data.message) {
				setMessage({ type: 'error', message: error.response.data.message });
				toast.dismiss(toastId);
			} else {
				toast.update(toastId, { autoClose: 5000, type: 'error', render: 'Error registering' });
			}
		}
	}

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required('Required'),
		last_name: Yup.string().required('Required'),
		password: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-{}\[\]\\:;"'<>,.?~])[a-zA-Z0-9!@#$%^&*()_+\-{}\[\]\\:;"'<>,.?~]+$/,
				'Password must contain at least one number and one symbol'
			),
		password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
		email: Yup.string().email('Invalid email').required('Required'),
	});

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-md-8">
					<h1>Register</h1>
					<Formik initialValues={{
						first_name: '',
						last_name: '',
						email: '',
						password: '',
						password_confirm: '',
						news_event_updates: false,
						shop_info: false,
						partner_marketing: false,
						recaptcha: '',
					}}
					validationSchema={validationSchema}
					onSubmit={formsubmit}>
						{({ handleSubmit, isSubmitting, setFieldValue }) => (
							<Form>
								<div className="row">
									<div className="col-12">
										<p className="alert alert-info"><strong>NOTE:</strong> User IDs will not be carried over from last year and you will need to <Link to="/register">re-register</Link>.</p>
									</div>
									{message && (
										<div className="col-12 mb-2">
											<div className={`alert alert-${message.type}`} role="alert">{message.message}</div>
										</div>
									)}
									<div className="col-12 col-md-6 mb-2">
										<label htmlFor="first_name">First Name</label>
										<Field type="text" className="form-control" id="first_name" name="first_name" placeholder="Enter first name" />
										<ErrorMessage name="first_name" component="div" className="field-error" />
									</div>
									<div className="col-12 col-md-6 mb-2">
										<label htmlFor="first_name">Last Name</label>
										<Field type="text" className="form-control" id="last_name" name="last_name" placeholder="Enter last name" />
										<ErrorMessage name="last_name" component="div" className="field-error" />
									</div>
								</div>
								<div className="mb-2">
									<label htmlFor="email">Email</label>
									<Field type="email" className="form-control" id="email" name="email" placeholder="Enter email" />
									<ErrorMessage name="email" component="div" className="field-error" />
								</div>
								<div className="row">
									<div className="col-12 col-md-6 mb-2">
										<label htmlFor="password">Password</label>
										<Field type="password" className="form-control" id="password" name="password" placeholder="Enter password" />
										<ErrorMessage name="password" component="div" className="field-error" />
									</div>
									<div className="col-12 col-md-6 mb-2">
										<label htmlFor="password">Password Confirm</label>
										<Field type="password" className="form-control" id="password_confirm" name="password_confirm" placeholder="Confirm password" />
										<ErrorMessage name="password_confirm" component="div" className="field-error" />
									</div>
									<div className="col-12 my-2">
										<div className="form-field form-check mb-2">
											<Field type="checkbox" className="form-check-input" name="news_event_updates" />
											<label htmlFor="terms" className="form-check-label">
												HRR may send me company news and event updates, including information about ticket sales and offers (including offers exclusive to Members and Competitors)
											</label>
											<ErrorMessage name="news_event_updates" component="div" className="field-error" />
										</div>
										<div className="form-field form-check mb-2">
											<Field type="checkbox" className="form-check-input" name="shop_info" />
											<label htmlFor="terms" className="form-check-label">
												The HRR Official Shop may send me information on its products and special offers (including offers exclusive to Members and Competitors)
											</label>
											<ErrorMessage name="shop_info" component="div" className="field-error" />
										</div>
										<div className="form-field form-check">
											<Field type="checkbox" className="form-check-input" name="partner_marketing" />
											<label htmlFor="terms" className="form-check-label">
												HRR's Official Partners may send me information relating to their products and services (including offers exclusive to Members and Competitors)
											</label>
											<ErrorMessage name="partner_marketing" component="div" className="field-error" />
										</div>
									</div>
									<div className="col-12">
										<ReCAPTCHA
											sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
											onChange={(value) => {
												// value is the reCAPTCHA response, store it in the form values
												setFieldValue('recaptcha', value);
											}}
										/>
										<ErrorMessage name="recaptcha" />
									</div>
								</div>
								<div className="row align-items-center">
									<div className="col-12 col-md-6">
										<Link to="/login">Already registered? Login</Link>
									</div>
									<div className="col-12 col-md-6 text-end">
										<button type="submit" className="btn btn-primary" disabled={(isSubmitting) && true}>Register</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>

				</div>
			</div>
		</div>
	);
};

export default withRouter(Register);
