const dateFormatterChange = (input, period) => {
	var values = input.split('/').map(function (v) {
		return v.replace(/\D/g, '');
	});

	var output = '';
	let currentYear = new Date().getFullYear();
	let parsedYear;

	if (values[0] !== undefined) {
		if (values[0] <= 31) {
			output += values[0].toString();
			if (output.length >= 2) {
				output = output.substr(0, 2) + '/';
			}
		}
	}
	if (values[1] !== undefined) {
		if (values[1] <= 12) {
			output += values[1].toString();
			if (output.length >= 5) {
				output = output.substr(0, 5) + '/';
			}
		}
	}
	if (values[2] !== undefined) {
		// Only attempt to validate or convert the year if it's fully typed.
		if (values[2].length === 4 || (values[2].length === 2 && input.length === 10)) {
			if (values[2].length === 2) {
				parsedYear = currentYear.toString().substr(0, 2) + values[2];
				output += parsedYear.toString();
			} else {
				parsedYear = values[2];
				output += parsedYear.toString();
			}

			// Additional logic to validate the full date (not just the year) is in the future/past.
			const enteredDate = new Date(parsedYear, values[1] - 1, values[0]);
			const currentDate = new Date();

			if (period === "future" && enteredDate <= currentDate) {
				return input.substr(0, input.length - 1); // remove the last character
			} else if (period === "past" && enteredDate >= currentDate) {
				return input.substr(0, input.length - 1); // remove the last character
			}
		} else {
			// If it's not 2 or 4 digits, just use the input as-is without validation.
			output += values[2];
		}
	}

	return output;
};





const dateFormatterBlur = (input, dateType) => {
	var values = input.split('/').map(function (v) {
		return v.replace(/\D/g, '');
	});

	if (values.length < 3) {
		return input; // Ensure there are enough parts for a complete date.
	}

	var year = parseInt(values[2], 10);
	var month = parseInt(values[1], 10) - 1; // JavaScript counts months from 0 to 11
	var day = parseInt(values[0], 10);

	// Autocomplete the year if it's just 2 digits
	if (year >= 0 && year <= 99) {
		var currentYear = new Date().getFullYear();
		var century = Math.floor(currentYear / 100) * 100;

		if (dateType === "future") {
			// For future dates, consider it to belong to the current century if less than 30 years in the future
			year += (year < ((currentYear + 30) % 100)) ? century : (century - 100);
		} else { // Assuming "past"
			// For past dates, adjust based on the assumption they belong to the last or current century
			year += (year < (currentYear % 100)) ? century : (century - 100);
		}

		// Update the year in the values array
		values[2] = year.toString();
	}

	input = values.join('/'); // Reconstruct the date string with updated year

	var enteredDate = new Date(year, month, day);
	var currentDate = new Date();

	if ((dateType === "future" && enteredDate <= currentDate) ||
		(dateType === "past" && enteredDate >= currentDate)) {
		// If the validation fails based on the dateType, return an indication or the original input
		return ""; // Or you could return `input` to not modify the user's entry.
	}

	// Format the date to ensure two digits for month and day
	return `${values[0].padStart(2, '0')}/${values[1].padStart(2, '0')}/${values[2]}`;
};


export { dateFormatterChange, dateFormatterBlur };
