import { useEffect, useState, useRef } from 'react';
import { withRouter } from '../../../common/with-router';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import EntryFilter from '../../../components/EntryFilter';
import Search from '../../../components/Search';
import Loading from '../../../components/global/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { getEventTypes } from '../../../api/events';

// import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Select from 'react-select'
// import dateFormatter from '../utils/dateFormatter';

const EventTypesList = (props) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem('_rrjt');

	const [loading, setLoading] = useState(true);
	const [eventtypes, setEventTypes] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('name');

	// const handleSort = (column) => {
	// 	const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
	// 	setSortDirection(newSortDirection);
	// 	setSortedColumn(column);
	// };

	const getInitialData = async () => {
		try {
			const getEventsTypesRes = await getEventTypes(page, sortDirection, sortedColumn);
			if (getEventsTypesRes.data.status === 'success') {
				setEventTypes(getEventsTypesRes.data.types);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	}

	useEffect(() => {
		setLoading(true);
		getInitialData();
	}, []);
	
	// useEffect(() => {
	// 	if (!searchTerm) return;

	// 	setLoading(true);
	// 	fetch(`${API_URL}/admin/events/search`, {
	// 		method: 'POST',
	// 		headers: {
	// 			// auth url
	// 			'Content-Type': 'application/json',
	// 			'Authorization': `Bearer ${token}`,
	// 		},
	// 		body: JSON.stringify({ searchTerm }),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			console.log('data', data);
	// 			setEvents(data);
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// }, [searchTerm]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setEventTypes(data);
	};
	
	// const handleDeleteItem = async (id) => {
	// 	if (window.confirm("Are you sure you want to delete this item?")) {
	// 		try {
	// 			// Make your fetch/axios call to delete the item from backend
	// 			fetch(`${API_URL}/admin/eventtypes/${id}`, {
	// 				method: 'DELETE',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					'Authorization': `Bearer ${token}`,
	// 				},
	// 			})
	// 				.then((response) => response.json())
	// 				.then((data) => {
	// 					if (data.error) {
	// 						alert(data.error);
	// 						return;
	// 					}

	// 					setEventTypes(prevData => prevData.filter(item => item.id !== id));
	// 				})
	// 				.catch((error) => {
	// 					console.error('Error:', error);
	// 				});
	// 		} catch (error) {
	// 			// Handle your error
	// 			console.error('Error:', error);
	// 		}
	// 	}
	// }

	if (loading) {
		return <Loading />;
	}

	const columns = () => {
		return Object.entries(eventtypes).map(([key, value]) => {
			console.log('value', value);
			return {
				view: 'admin',
				id: value.id,
				// event_code: value.event_code,
				name: value.name,
				gender: value.gender,
				athlete_qty: value.athlete_qty,
				cox: (value.cox === 1) ? 'Yes' : 'No',
				// event_type_name: value.event_type_name,
				// initials: value.initials,
				// last_name: value.last_name,
				// club: value.club?.name,
			};
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Events Types <Link to="/admin/eventtypes/edit" className="btn btn-new"><FontAwesomeIcon icon={faPlusSquare} /></Link></h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/eventtypes/search" placeholder="Search Events Types by Name" setLoading={setLoading} />
				</div>
				<div className="col-12">
				</div>
				<div className="col-12 form-group">
					<div>
						{(Object.entries(eventtypes).length) ?
							<Table
								data={columns()}
								linkColumn="name"
								linkLocation="/admin/event-types/edit" 
								visibleColumns={[
									{
										name: 'name',
										sort: 'name'
									},
									{
										name: 'gender',
										sort: 'gender'
									},
									{
										name: 'cox',
										sort: 'cox'
									},
									{
										name: 'athlete_qty',
										sort: 'athlete_qty'
									},
								]}
								editable={true}
								// handleDelete={handleDeleteItem}
							/> : <div className="alert alert-danger">No event types found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(EventTypesList);
