import { useEffect, useState } from 'react';
import { withRouter } from '../common/with-router';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../components/global/loading';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { bioUpdate } from '../api/user';

const Bio = (props) => {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.user);

	const [loading, setLoading] = useState(true);

	const getInitialData = () => {
		setLoading(false);
	}

	useEffect(() => {
		setLoading(true);
		getInitialData();
	}, []);

	const ClubForm = () => {
		let initialValues = {};
		if (currentUser && currentUser.user.role.includes('athlete')) {
			initialValues = {
				hrr_number: (currentUser && currentUser.user?.athlete?.hrr_number) ? currentUser.user?.athlete?.hrr_number : '',
				first_name: (currentUser && currentUser.user?.athlete?.first_name) ? currentUser.user?.athlete?.first_name : '',
				initials: {
					0: (currentUser && currentUser.user?.athlete?.initial_parts?.[0]) ? currentUser.user?.athlete?.initial_parts?.[0] : '',
					1: (currentUser && currentUser.user?.athlete?.initial_parts?.[1]) ? currentUser.user?.athlete?.initial_parts?.[1] : '',
					2: (currentUser && currentUser.user?.athlete?.initial_parts?.[2]) ? currentUser.user?.athlete?.initial_parts?.[2] : '',
				},
				// last_name: (currentUser && currentUser.user?.athlete?.last_name) ? currentUser.user?.athlete?.last_name : '',
				dob: (currentUser && currentUser.user?.athlete?.dob) ? currentUser.user?.athlete?.dob : '',
				bio: (currentUser && currentUser.user?.coach?.bio) ? currentUser.user.coach?.bio : '',
				sporting_highlights: (currentUser && currentUser.user?.athlete?.sporting_highlights) ? currentUser.user.athlete.sporting_highlights : '',
				job_course_university: (currentUser && currentUser.user?.athlete?.job_course_university) ? currentUser.user.athlete.job_course_university : '',
				other_info: (currentUser && currentUser.user?.athlete?.other_info) ? currentUser.user.athlete.other_info : '',
			};
		} else if (currentUser && currentUser.user.role.includes('coach')) {
			initialValues = {
				first_name: (currentUser && currentUser.user?.coach?.first_name) ? currentUser.user?.coach?.first_name : '',
				initials: {
					0: (currentUser && currentUser.user?.coach?.initial_parts?.[0]) ? currentUser.user?.coach?.initial_parts?.[0] : '',
					1: (currentUser && currentUser.user?.coach?.initial_parts?.[1]) ? currentUser.user?.coach?.initial_parts?.[1] : '',
					2: (currentUser && currentUser.user?.coach?.initial_parts?.[2]) ? currentUser.user?.coach?.initial_parts?.[2] : '',
				},
				// last_name: (currentUser && currentUser.user?.coach?.last_name) ? currentUser.user?.coach?.last_name : '',
				bio: (currentUser && currentUser.user?.coach?.bio) ? currentUser.user.coach?.bio : '',
			};
		} else {
			initialValues = {
				hrr_number: '',
				first_name: '',
				initials: {
					0: '',
					1: '',
					2: '',
				},
				// last_name: '',
				dob: '',
				bio: '',
				sporting_highlights: '',
				job_course_university: '',
				other_info: '',
			};
		}

		const validationSchema = Yup.object().shape({
			// dob: Yup.string().required('Required'),
			// bio: Yup.string().required('Required'),
			// sporting_highlights: Yup.string().required('Required'),
			// job_course_university: Yup.string().required('Required'),
			// other_info: Yup.string().required('Required'),
		});

		const onSubmit = async (values, { setSubmitting }) => {
			setSubmitting(true);
			const toastId = toast("Saving...", { autoClose: false });

			const data = {
				...values,
			};

			// if (props.router?.params?.id) {
			// 	data.id = props.router?.params?.id;
			// }

			try {
				const doProfileUpdate = await bioUpdate(currentUser.user.id, data);
				if (doProfileUpdate.data) {
					try {
						// const thiscurrentUser = await setCurrentUser(user);
						dispatch({ type: 'USER', payload: doProfileUpdate.data.user });
						toast.update(toastId, { type: toast.TYPE.SUCCESS, render: "Profile saved!", autoClose: 5000 });
					} catch (error) {
						toast.update(toastId, { type: toast.TYPE.ERROR, render: "Error saving profile.", autoClose: 5000 });
						console.log(error);
					}
				}
			} catch (error) {
				console.log(error);
			}
		};

		return (
			<div className="col-md-12">
				<div className="card card-container">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, setFieldValue, isValid, isSubmitting }) => {
							return (
								<Form>
									<div className="row">
										{(currentUser && currentUser.user.role.includes('athlete')) && 
											<div className="col-md-12">
												<div className="form-group-flex">
													<label htmlFor="currentUsername">HRR Number</label>
													<Field type="text" className="form-control" name="hrr_number" disabled />
													<ErrorMessage name="hrr_number" component="div" className="field-error" />
												</div>
											</div>
										}
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="currentUsername">First Name</label>
												<Field type="text" className="form-control" name="first_name" />
												<ErrorMessage name="first_name" component="div" className="field-error" />
											</div>
										</div>
										<div className="col-md-4">
											<label htmlFor="currentUsername">Initials</label>
											<div className="d-flex">
												<div className="px-1">
													<Field type="text" className="form-control" name="initials[0]" />
													<ErrorMessage name="initials[0]" component="div" className="field-error" />
												</div>
												{(values.initials?.[0]?.length > 0) &&
													<div className="px-1">
														<Field type="text" className="form-control" name="initials[1]" />
														<ErrorMessage name="initials[1]" component="div" className="field-error" />
													</div>
												}
												{(values.initials?.[1]?.length > 0) &&
													<div className="px-1">
														<Field type="text" className="form-control" name="initials[2]" />
														<ErrorMessage name="initials[2]" component="div" className="field-error" />
													</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group-flex">
												<label htmlFor="event_code">Last Name</label>
												<Field type="text" name="last_name" className="form-control" value={(currentUser && currentUser.user?.athlete?.last_name) ? currentUser.user?.athlete?.last_name:''} disabled={true} />
												<a href="mailto:crews@hrr.co.uk">Change</a>
											</div>
										</div>
										{(currentUser && currentUser.user.role.includes('athlete')) && 
											<div className="col-md-6">
												<div className="form-group-flex">
													<label htmlFor="event_code">DOB (DD/MM/YYYY)</label>
													<Field type="text" name="dob" className="form-control" disabled />
													<ErrorMessage name="dob" component="div" className="field-error" />
													<a href="mailto:crews@hrr.co.uk">Change</a>
												</div>
											</div>
										}
										<div className="col-12"></div>
										{(currentUser && currentUser.user.role.includes('athlete')) && 
											<div className="col-md-6">
												<div className="form-group-flex">
													<label htmlFor="currentUsername">HRR Appearances</label>
													<Field type="text" className="form-control" name="participations" value={currentUser.user?.athlete?.participations} disabled={true} />
													<a href="mailto:crews@hrr.co.uk">Incorrect appearance count or wins?</a>
												</div>
											</div>
										}
										{/* {(currentUser && currentUser.user.role.includes('athlete')) && 
											<div className="col-md-6">
												<div className="form-group-flex">
													<label htmlFor="currentUsername">Wins</label>
													<Field type="text" className="form-control" name="wins" value={currentUser.user?.athlete?.wins} disabled={true} />
												</div>
											</div>
										} */}
									</div>

									<hr />
									<h3>Bio</h3>
									<p>Please complete the following fields and click the 'Save Bio' button.</p>
									{(currentUser && currentUser.user.role.includes('coach')) && 
										<>
											<div className="form-group-flex">
												<label htmlFor="event_code">Bio</label>
												<Field component="textarea" name="bio" className="form-control" />
											</div>
										</>
									}

									{(currentUser && currentUser.user.role.includes('athlete')) && 
										<>
											<div className="form-group-flex">
												<label htmlFor="event_code">Sporting Highlights</label>
												<Field component="textarea" name="sporting_highlights" className="form-control" />
												<ErrorMessage name="sporting_highlights" component="div" className="field-error" />
											</div>
											<div className="form-group-flex">
												<label htmlFor="event_code">Job/University</label>
												<Field component="textarea" name="job_course_university" className="form-control" />
												<ErrorMessage name="job_course_university" component="div" className="field-error" />
											</div>
											<div className="form-group-flex">
												<label htmlFor="event_code">Other</label>
												<Field component="textarea" name="other_info" className="form-control" />
												<ErrorMessage name="other_info" component="div" className="field-error" />
											</div>
										</>
									}

									<div className="form-group form-buttons mt-3">
										<button
											className="btn btn-primary btn-submit"
											type="submit"
											disabled={(isSubmitting)}
										>
											{isSubmitting && (
												<FontAwesomeIcon icon={faSpinner} spin />
											)}
											<span>Save Bio</span>
										</button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		);
	}


	if (loading) {
		return <Loading />;
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					{(currentUser) && <h1>Edit Bio</h1>}
				</div>

				<div className="col-12 form-group">
					{<ClubForm />}
				</div>
			</div>
		</div>
	);
}

export default withRouter(Bio);
