import React, { useEffect, useState } from 'react';
import { getLog } from '../api/misc';
import Loading from './global/loading';

const LogTable = ({ id }) => {
	const [log, setLog] = useState([]);
	const [loading, setLoading] = useState(true);

	const getLogData = async () => {
		try {
			const response = await getLog(id);
			setLog(response.data.logs);
		} catch (error) {
			console.error('Error getting log', error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getLogData();
	}, []);

	const Table = () => {
		if (log.length === 0) {
			return <div>No logs found</div>;
		}

		return (
			<table className="table table-striped">
				<thead>
					<tr>
						<th>User</th>
						<th>Action</th>
						<th>Message</th>
						<th>Date/Time</th>
					</tr>
				</thead>
				<tbody>
					{log.map((item, index) => (
						<tr key={index}>
							<td>{(item.user?.first_name) ? <>{item.user?.first_name} {item.user?.last_name}</>:'System'}</td>
							<td>{item.action}</td>
							<td dangerouslySetInnerHTML={{__html: item.text_change}}></td>
							<td>{item.created_at_formatted}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="position-relative">
			<Table />
		</div>
	);
}

export default LogTable;
