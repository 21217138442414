import { useEffect, useState } from 'react';
import { withRouter } from '../../../common/with-router';
import Table from '../../../components/Table';
import Search from '../../../components/Search';
import Pagination from '../../../components/Pagination';
import { getEntries } from '../../../api/entries';
import CommentatorFilter from '../../../components/CommentatorFilter';

const CommentatorCrewList = (props) => {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState({});
	const [page, setPage] = useState(1);
	const [sortDirection, setSortDirection] = useState('desc');
	const [sortedColumn, setSortedColumn] = useState('created_at');
	const [searchTerm, setSearchTerm] = useState('');
	const [filters, setFilters] = useState({ event: null, status: [{ value: 4 }, { value: 5 }, { value: 7 }, { value: 9 }, { value: 20 }], crew_numbered: true });
	const [isSearching, setIsSearching] = useState(false);

	const handleSort = (column) => {
		const newSortDirection = (sortDirection === 'asc' && sortedColumn === column) ? 'desc' : 'asc';
		setSortDirection(newSortDirection);
		setSortedColumn(column);
	};

	const getInitialData = async () => {
		try {
			const getEntryDataRes = await getEntries(filters, page, sortDirection, sortedColumn);

			if (getEntryDataRes.data.status === 'success') {
				setEntries(getEntryDataRes.data.entries);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (!isSearching) {
			getInitialData();
		}
	}, [page, sortDirection, sortedColumn, filters]);

	const handleSearch = (data) => {
		if (!data) {
			getInitialData();
			return;
		}

		setIsSearching(true);
		// setPage(1);
		setEntries(data);
	};

	if (loading) {
		return <div className="screen-loading">Loading...</div>;
	}

	const columns = () => {
		return Object.entries(entries.data).map(([key, value]) => {
			return {
				view: 'admin',
				id: value.crew.id,
				crew: value.crew.crew_number,
				crew_name: value.crew.crew_name,
				event_name: value.event.name,
				date_added: value.created_at_formatted,
			};
		});
	}; 

	const handleFilters = (newFilters) => {
		// merge the new filters with the existing filters
		const mergedFilters = { ...filters, ...newFilters };
		setFilters(mergedFilters);
	};

	return (
		<div className="container-fluid">
			<div className="row">
			</div>
			<div className="row">
				<div className="col-12 col-md-6">
					<h1>Crews</h1>
				</div>
				<div className="col-12 col-md-6 mb-3">
					<Search handleSearch={handleSearch} endpoint="admin/entries/search" page={page} setIsSearching={setIsSearching} placeholder="Search ENC Number or ID" setLoading={setLoading} />
				</div>
				<div className="col-12 col-md-6 mb-3">
					<CommentatorFilter handleFilters={handleFilters} filters={filters} />
				</div>

				<div className="col-12 form-group">
					<div>
						{(entries) ?
							<>
								<Table
									data={columns()}
									onSortChange={handleSort}
									visibleColumns={[
										{
											name: 'crew',
											sort: 'crew_number'
										},
										{
											name: 'crew_name',
											sort: 'crew_name'
										},
										{
											name: 'event_name',
											sort: 'event_name'
										},
										{
											name: 'date_added',
											sort: 'created_at'
										},
									]}
									onSort={handleSort}
									sortedColumn={sortedColumn}
									sortDirection={sortDirection}
									columnMappings={{
										'crew': 'entry.id',
										'crew_name': 'crew.crew_name',
										'event_name': 'event.name',
										'date_added': 'entry.date_added',
									}}
									linkColumn="crew"
									linkLocation="/admin/commentators"
									editable={true}
								/>
								<Pagination data={entries} setPage={setPage} />
							</> : <div className="alert alert-danger">No crews found.</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(CommentatorCrewList);
