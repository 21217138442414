import instance from './config';

const emailSend = (id, type) => {
	return instance.post(`/email`, {
		entry_id: id,
		trigger: type
	});
};

export {
	emailSend
}
